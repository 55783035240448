export default function getIfDevelopmentMode(andEmulator: boolean = false) {
    if (process.env.FORCEPRODUCTIONMODE) {
        return false;
    } else if (location.hostname === "localhost" || process.env.FORCEDEVMODE) {
        if (andEmulator) {
            return getIfUseEmulators();
        } else {
            return true;
        }
    } else {
        return false;
    }
}

export function getIfUseEmulators(): boolean {
    if (process.env.USE_EMULATORS === 'true') {
        return true;
    } else {
        return false;
    }
}