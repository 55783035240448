import * as ui from '../ui';
import * as accessibility from '../accessibility';
import {reloadPage} from '../utils';
import * as analytics from '../analytics';



export default async function displayAccessibilityPopup() {
    const popupSource = await import('../../popups/accessibilityoptions.html');
    ui.displayPopup(popupSource, true, undefined, function () {
        ui.handleClick('.popup_accessibilityoptions_button_save', function () {
            analytics.logAnalyticsEvent('use_feature', 'accessibility_settings');
            let newOptions: accessibility.accessibilityOptions = getOptions();
            accessibility.setLocalAccessibilityOptions(newOptions);
            ui.displayAlert('Saved options. Reloading page.', 'Options Saved');
            reloadPage();
        });
        ui.handleClick('.popup_accessibilityoptions_button_resetall', function () {
            loadOptions(accessibility.getDefaultAccessibilityOptions());
        });
        loadOptions();
    });
}

function getOptions(): accessibility.accessibilityOptions {
    let newOptions: accessibility.accessibilityOptions = accessibility.getDefaultAccessibilityOptions();
    newOptions.dyslexicfont = ui.getInputValue('input[name=accessibilityoptions_dyslexicfont]');
    newOptions.darktheme = ui.getInputValue('input[name=accessibilityoptions_darktheme]');
    newOptions.forcelighttheme = ui.getInputValue('input[name=accessibilityoptions_forcelighttheme]');
    newOptions.largecursor = ui.getInputValue('input[name=accessibilityoptions_largecursor]');
    newOptions.grayscale = ui.getInputValue('input[name=accessibilityoptions_grayscale]');
    newOptions.invertcolors = ui.getInputValue('input[name=accessibilityoptions_invertcolors]');
    newOptions.nofontkerning = ui.getInputValue('input[name=accessibilityoptions_nofontkerning]');
    newOptions.smallcaps = ui.getInputValue('input[name=accessibilityoptions_smallcaps]');
    newOptions.donotdisplayimages = ui.getInputValue('input[name=accessibilityoptions_donotdisplayimages]');
    newOptions.emphasizemenulinks = ui.getInputValue('input[name=accessibilityoptions_emphasizemenulinks]');
    newOptions.disabletextselection = ui.getInputValue('input[name=accessibilityoptions_disabletextselection]');
    newOptions.uppercasetext = ui.getInputValue('input[name=accessibilityoptions_uppercasetext]');
    newOptions.contrast = ui.getInputValue('#accessibilityoptions_contrast');
    newOptions.zoom = ui.getInputValue('#accessibilityoptions_zoom');
    newOptions.letterspacing = ui.getInputValue('#accessibilityoptions_letterspacing');
    newOptions.wordspacing = ui.getInputValue('#accessibilityoptions_wordpsacing');
    newOptions.lineheight = ui.getInputValue('#accessibilityoptions_lineheight');
    newOptions.brightness = ui.getInputValue('#accessibilityoptions_brightness');
    newOptions.saturation = ui.getInputValue('#accessibilityoptions_saturation');
    newOptions.sepia = ui.getInputValue('#accessibilityoptions_sepia');
    newOptions.huerotation = ui.getInputValue('#accessibilityoptions_huerotation');
    newOptions.caretcolor = ui.getInputValue('#accessibilityoptions_caretcolor');
    newOptions.textcolor = ui.getInputValue('#accessibilityoptions_textcolor');
    return newOptions;
}

function loadOptions(options?: accessibility.accessibilityOptions) {
    let localAccessibilityOptions: accessibility.accessibilityOptions = (options ? options : accessibility.getLocalAccessibilityOptions());
    ui.setInputValue('input[name=accessibilityoptions_dyslexicfont]', localAccessibilityOptions.dyslexicfont);
    ui.setInputValue('input[name=accessibilityoptions_darktheme]', localAccessibilityOptions.darktheme);
    ui.setInputValue('input[name=accessibilityoptions_forcelighttheme]', localAccessibilityOptions.forcelighttheme);
    ui.setInputValue('input[name=accessibilityoptions_largecursor]', localAccessibilityOptions.largecursor);
    ui.setInputValue('input[name=accessibilityoptions_grayscale]', localAccessibilityOptions.grayscale);
    ui.setInputValue('input[name=accessibilityoptions_invertcolors]', localAccessibilityOptions.invertcolors);
    ui.setInputValue('input[name=accessibilityoptions_nofontkerning]', localAccessibilityOptions.nofontkerning);
    ui.setInputValue('input[name=accessibilityoptions_smallcaps]', localAccessibilityOptions.smallcaps);
    ui.setInputValue('input[name=accessibilityoptions_donotdisplayimages]', localAccessibilityOptions.donotdisplayimages);
    ui.setInputValue('input[name=accessibilityoptions_emphasizemenulinks]', localAccessibilityOptions.emphasizemenulinks);
    ui.setInputValue('input[name=accessibilityoptions_disabletextselection]', localAccessibilityOptions.disabletextselection);
    ui.setInputValue('input[name=accessibilityoptions_uppercasetext]', localAccessibilityOptions.uppercasetext);
    ui.setInputValue('#accessibilityoptions_contrast', localAccessibilityOptions.contrast);
    ui.setInputValue('#accessibilityoptions_zoom', localAccessibilityOptions.zoom);
    ui.setInputValue('#accessibilityoptions_letterspacing', localAccessibilityOptions.letterspacing);
    ui.setInputValue('#accessibilityoptions_wordpsacing', localAccessibilityOptions.wordspacing);
    ui.setInputValue('#accessibilityoptions_lineheight', localAccessibilityOptions.lineheight);
    ui.setInputValue('#accessibilityoptions_brightness', localAccessibilityOptions.brightness);
    ui.setInputValue('#accessibilityoptions_saturation', localAccessibilityOptions.saturation);
    ui.setInputValue('#accessibilityoptions_sepia', localAccessibilityOptions.sepia);
    ui.setInputValue('#accessibilityoptions_huerotation', localAccessibilityOptions.huerotation);
    ui.setInputValue('#accessibilityoptions_caretcolor', localAccessibilityOptions.caretcolor);
    ui.setInputValue('#accessibilityoptions_textcolor', localAccessibilityOptions.textcolor);
}