import { Dom7 as $$ } from 'framework7';
import { CSSSelector } from 'framework7/types/components/app/app-class';
import { Dialog } from 'framework7/types/components/dialog/dialog';
import { SimpleDrawingBoard } from 'simple-drawing-board';
import { getLocalAgencyInfo } from './agency';
import { app } from './libs/framework7';
import { setIsConfirmGoBack } from './lockedstate';



let loadPreloader: Dialog.Dialog;
let calendarInstances: any = {};
let drawingBoardInstances: any = {};



export function handleClick(element: string, handler: any): void {
    if (!$$(element).hasClass('ui-handle-click')) {
        $$(element).off('click', handler);
        $$(element).on('click', handler);
        $$(element).addClass('ui-handle-click');
    }
}

export function handleChange(element: string, handler: any): void {
    if (!$$(element).hasClass('ui-handle-change')) {
        $$(element).off('change', handler);
        $$(element).on('change', handler);
        $$(element).addClass('ui-handle-change');
    }
}

export function handleFocus(element: string, handler: any): void {
    if (!$$(element).hasClass('ui-handle-focus')) {
        $$(element).off('focus', handler);
        $$(element).on('focus', handler);
        $$(element).addClass('ui-handle-focus');
    }
}

export function handleEnterKey(input: string, handler: any): void {
    if (!$$(input).hasClass('ui-handle-enterkey')) {
        $$(input).off('keyup', handler);
        $$(input).keyup(function (event: any) {
            if (event.keyCode === 13) {
                handler();
            }
        });
        $$(input).addClass('ui-handle-enterkey');
    }
}

export function setInputValue(input: string, value: any) {
    if (input) {
        let inputType: string = getInputType(input);
        if (inputType === 'checkbox') {
            $$(input).prop('checked', value);
        } else {
            $$(input).val(value);
        }
        app.input.checkEmptyState(input);
    }
}

export function getInputValue(input: string): any {
    let inputType: string = getInputType(input);
    if (inputType === 'checkbox') {
        return $$(input).prop('checked');
    } else {
        return $$(input).val();
    }
}

/**
 * Gets a select input's current text value.
 * @param {string} selectID The ID of the select input. Do not include # in the string.
 * @returns {string} The text value of the select input.
 */
export function getSelectTextByID(selectID: string): string {
    let selectInput: any = document.getElementById(selectID);
    return selectInput.options[selectInput.selectedIndex].text;
}

export function displayPopup(popupHTML: any, closeByBackDropClick = false, onClose?: any, onOpen?: any) {
    return new Promise(async function (resolve, reject) {
        if (!onClose) {
            onClose = function () { };
        }
        if (!onOpen) {
            onOpen = function () { };
        }
        let newPopup = app.popup.create({
            content: popupHTML,
            closeByBackdropClick: closeByBackDropClick,
            on: {
                close: onClose,
                opened: onOpen
            }
        });
        newPopup.open();
        resolve(newPopup);
    });
}

interface photoBrowserPhoto {
    url: string;
    caption?: string;
}

export function displayPhotoBrowser(photos: photoBrowserPhoto[]) {
    return app.photoBrowser.create({
        photos: photos
    });
}

export function closePopup(popupSelector?: string) {
    if (popupSelector) {
        app.popup.close(popupSelector);
    } else {
        app.popup.close();
    }
}

export function displayToast(message: string, timeout: number = 2500) {
    const newToast = app.toast.create({
        text: message,
        closeTimeout: timeout
    });
    newToast.open();
}

export function displayInfoToast(message: string, timeout: number = 2500) {
    hideLoadPreloader();
    const newToast = app.toast.create({
        icon: '<i class="fas fa-info-circle"></i>',
        text: message,
        position: 'center',
        closeTimeout: timeout
    });
    newToast.open();
}

export function getInputType(selector: string): string {
    let nodeName: string = $$(selector).prop('nodeName');
    if (nodeName === 'SELECT') {
        return 'select';
    } else if (nodeName === 'INPUT') {
        //Could be somehting like: checkbox, text, password, email
        return ($$(selector).prop('type').toLowerCase());
    } else {
        return '';
    }
}

export function setButtonLoadingState(buttonSelector: string, loading: boolean) {
    if (loading) {
        $$(buttonSelector).addClass('button-loading');
    } else {
        $$(buttonSelector).removeClass('button-loading');
    }
}

export function displayLoadPreloader(text = 'Loading...'): void {
    if (loadPreloader) {
        closePreloader(loadPreloader);
    }
    loadPreloader = app.dialog.preloader(text);
}

export function hideLoadPreloader(): void {
    if (loadPreloader) {
        closePreloader(loadPreloader);
    }
}

export function closeDialog(): void {
    app.dialog.close();
}

export function displayAlert(message: string, title = ''): void {
    hideLoadPreloader();
    app.dialog.alert(message, title);
}

export interface confirmDialogArguments {
    readonly message: string;
    readonly title?: string;
    readonly confirmCallback: Function;
    readonly cancelCallback?: Function;
}

export function displayConfirmDialog(args: confirmDialogArguments) {
    hideLoadPreloader();
    app.dialog.confirm(args.message, (args.title ? args.title : 'Confirm'), function () {
        args.confirmCallback();
    }, function () {
        if (args.cancelCallback) {
            args.cancelCallback();
        }
    });
}

export interface promptDialogArguments {
    readonly message: string;
    readonly title?: string;
    readonly confirmCallBack: Function;
    readonly cancelCallback?: Function;
    readonly defaultValue?: string;
}

export function displayPromptDialog(args: promptDialogArguments) {
    app.dialog.prompt(args.message, (args.title ? args.title : ''), function (result: string) {
        args.confirmCallBack(result);
    }, function () {
        if (args.cancelCallback) {
            args.cancelCallback();
        }
    }, (args.defaultValue ? args.defaultValue : ''));
}

/**
* Closes a preloader. Doesn't cause an error if the preloader is already closed.
* @param preloader The preloader object to close.
*/
export function closePreloader(preloader: any): void {
    try {
        preloader.close();
    } catch (e) {
        //do nothing
    }
}

export function displayErrorAlert(message: string) {
    hideLoadPreloader();
    app.dialog.create({
        title: 'Error',
        text: message,
        buttons: [
            {
                text: 'Support',
                onClick: function () {
                    //!TODO
                    //displaySupportPopup();
                }
            },
            {
                text: 'Ok',
                close: true
            }
        ]
    }).open();
}

export function applySkeletonTextEffect(element: string, effect: boolean = true) {
    $$(element).addClass('skeleton-text');
    if (effect) {
        $$(element).addClass('skeleton-effect-wave');
    }
}

export function removeSkeletonTextEffect(element: string) {
    $$(element).removeClass('skeleton-text');
    $$(element).removeClass('skeleton-effect-wave');
}

export function handleSettingInputs() {
    const inputClass: string = '.setting_input';
    handleChange(inputClass, function () {
        setIsConfirmGoBack(true);
    });
}

export function setInputInvalidState(inputSelector: string, invalid: boolean, message: string = 'Please fill out this field.') {
    if (invalid) {
        $$(inputSelector).parent().parent().parent().addClass('item-input-invalid');
        if (message) {
            $$(inputSelector).parent().parent().parent().addClass('item-input-with-error-message');
            $$(inputSelector).parent().append('<div class="item-input-error-message">' + message + '</div>')
        }
        $$(inputSelector).addClass('input-invalid');
    } else {
        $$(inputSelector).parent().parent().parent().removeClass('item-input-invalid');
        $$(inputSelector).parent().parent().parent().removeClass('item-input-with-error-message');
        $$(inputSelector).removeClass('input-invalid');
        if ($$(inputSelector).parent().children('.item-input-error-message').length > 0) {
            $$(inputSelector).parent().children('.item-input-error-message')[0].remove();
        }
    }
}

export function validateInput(inputSelector: string, required: boolean = true, validateFunction: Function, message: string = 'Please fill out this field.'): boolean {
    if (!required && !getInputValue(inputSelector)) {
        setInputInvalidState(inputSelector, false);
        return true;
    }
    if (validateFunction(getInputValue(inputSelector))) {
        setInputInvalidState(inputSelector, false);
        return true;
    } else {
        setInputInvalidState(inputSelector, true, message);
        return false;
    }
}

export function validateInputSimpleCheck(inputSelector: string): boolean {
    return validateInput(inputSelector, true, function (value: string) {
        if (value) {
            return true;
        } else {
            return false;
        }
    });
}

export async function displayNotFoundBackground(selector: string, message?: string) {
    let backgroundSource: any = await import('../reuse/nothingtoloadbackground.html');
    $$(selector).html(backgroundSource);
    if (message) {
        $$('#ui-nothingtoloadbackground-message').text(message);
    }
}

/**
 * Initializes a searchbar.
 * @param searchBarElement - The HTML element of the searchbar.
 * @param searchContainer - The HTML element that the searchbar will search in.
 * @param additionalSearchIn - Additional HTML classes to search in. Comma separated.
 */
export function initializeSearchBar(searchBarElement: string, searchContainer: string, additionalSearchIn: string = '', searchItem: string = 'li') {
    app.searchbar.destroy(searchBarElement);
    app.searchbar.create({
        el: searchBarElement,
        searchIn: '.item-inner,.searchableitem,.searchtags' + (additionalSearchIn ? ',' + additionalSearchIn : ''),
        searchItem: searchItem,
        searchContainer: searchContainer
    });
}

export interface verticalDialogButton {
    readonly text: string;
    readonly onClick: Function;
    readonly close?: boolean;
}

export interface verticalDialogArguments {
    readonly title?: string;
    readonly text?: string;
    readonly buttons: verticalDialogButton[];
}

export function displayVerticalDialog(args: verticalDialogArguments) {
    hideLoadPreloader();
    app.dialog.create({
        title: (args.title ? args.title : ''),
        text: (args.text ? args.text : ''),
        //@ts-ignore
        buttons: args.buttons,
        verticalButtons: true
    }).open();
}

export const _DIALOGCANCELBUTTON: any = { //Cancel button for dialogs
    text: '<span style="color: #f44336;">Cancel</span>',
    close: true
}

export interface actionSheetItem {
    readonly text: string;
    readonly onClick: Function;
    readonly dontInclude?: boolean
}

export function displayActionSheet(element: HTMLElement, items: actionSheetItem[], includeCancel = true) {
    let useButtons = [];
    for (let i in items) {
        if (!items[i].dontInclude) {
            useButtons.push({
                text: items[i].text,
                onClick: items[i].onClick
            });
        }
    }
    if (includeCancel && window.innerWidth <= 767) { //only display cancel button if action sheet
        useButtons.push({
            text: 'Cancel',
            color: 'red'
        });
    }
    let newActionSheet = app.actions.create({
        convertToPopover: true,
        targetEl: element,
        buttons: useButtons,
        //@ts-ignore
        closeOnEscape: true
    });
    newActionSheet.open();
}

export function displayComingSoonAlert(): void {
    displayAlert('This feature is coming soon.', 'Coming Soon');
}

export function initializeDatePicker(input: string | HTMLElement, closeOnSelect = true, multiple = false, timePicker = false, dateFormat?: string) {
    let options: any = {
        inputEl: input,
        closeOnSelect: closeOnSelect,
        multiple: multiple,
        timePicker: timePicker
    };
    if (dateFormat) {
        options['dateFormat'] = dateFormat;
    }
    if (typeof input === 'string') {
        calendarInstances[input] = app.calendar.create(options);
        return calendarInstances[input];
    } else {
        return app.calendar.create(options);
    }
}

export function initializeMonthYearPicker(input: string): void {
    let yearOptions: string = '';
    for (var i = 2010; i <= new Date().getFullYear() + 5; i++) {
        yearOptions += i + ' ';
    }
    yearOptions = yearOptions.trim();
    app.picker.create({
        inputEl: input,
        rotateEffect: true,
        routableModals: false,
        formatValue: function (values: any) {
            return values[0] + ' ' + values[1];
        },
        cols: [
            {
                textAlign: 'center',
                values: ('January February March April May June July August September October November December').split(' ')
            },
            {
                textAlign: 'center',
                values: yearOptions.split(' ')
            }
        ]
    });
}

export function getDatePickerValue(inputSelector: string): Date {
    if (calendarInstances[inputSelector]?.getValue()) {
        return calendarInstances[inputSelector].getValue()[0];
    } else {
        return undefined;
    }
}

export function setDatePickerValue(inputSelector: string, value: Date) {
    if (calendarInstances[inputSelector]) {
        calendarInstances[inputSelector].setValue([value]);
    }
}

export function setDrawingBoardInstance(inputSelector: string, instance: SimpleDrawingBoard): void {
    drawingBoardInstances[inputSelector] = instance;
}

export function clearDrawingBoardInstances(): void {
    for (let i in drawingBoardInstances) {
        drawingBoardInstances[i].destroy();
        delete drawingBoardInstances[i];
    }
    drawingBoardInstances = undefined;
    drawingBoardInstances = {};
}

export function getDrawingBoardInstance(inputSelector: string): SimpleDrawingBoard {
    if (drawingBoardInstances[inputSelector]) {
        return drawingBoardInstances[inputSelector];
    } else {
        return undefined;
    }
}

export function displayBetaAlert() {
    let displayedBetaAlert = localStorage.getItem('beta_alert');
    if (displayedBetaAlert !== 'true') {
        localStorage.setItem('beta_alert', 'true');
        displayAlert('Please note that this is an early beta release. There may be bugs, or things may change in the future. Your feedback is appreciated.', 'Beta');
    }
}

export function openAccordion(el: HTMLElement | CSSSelector): Promise<void> {
    return new Promise(function (resolve, reject) {
        //@ts-ignore
        $$(el).once('accordion:opened', function () {
            resolve();
        });
        app.accordion.open(el);
    });
}

export function closeAccordion(el: HTMLElement | CSSSelector) {
    app.accordion.close(el);
}

export function displayInfo(el: string, title: string, body?: string | number | boolean) {
    if (body) {
        $$(el).html('<span class="boldtext">' + title + ': </span>' + body);
    } else {
        $$(el).html('');
    }
}

let tooltipPositioningFixRun: boolean = false;

export function addTooltip(selector: string | HTMLElement, text: string) {
    if (typeof selector === 'string') {
        if (!$$(selector).hasClass('ui-tooltip')) {
            app.tooltip.create({
                targetEl: selector,
                text: text
            });
            $$(selector).addClass('ui-tooltip');
        }
    } else {
        if (!$$(selector).hasClass('ui-tooltip')) {
            app.tooltip.create({
                targetEl: selector,
                text: text
            });
            $$(selector).addClass('ui-tooltip');
        }
    }
    // Tooltips display at the top of the page until the window resize event is run
    if (!tooltipPositioningFixRun) {
        tooltipPositioningFixRun = true;
        try {
            window.dispatchEvent(new Event('resize'));
        } catch (error) {
            // do nothing
        }
    }
}

export function loadDropdownMonths(dropdownSelector: string, autoSelectCurrentMonth = true): void {
    let monthOptions: string = `
    <option value="0">January</option>
    <option value="1">February</option>
    <option value="2">March</option>
    <option value="3">April</option>
    <option value="4">May</option>
    <option value="5">June</option>
    <option value="6">July</option>
    <option value="7">August</option>
    <option value="8">September</option>
    <option value="9">October</option>
    <option value="10">November</option>
    <option value="11">December</option>
    `;
    $$(dropdownSelector).html(monthOptions);
    if (autoSelectCurrentMonth) {
        $$(dropdownSelector).val(new Date().getMonth());
    }
}

export function loadDropdownYears(dropdownSelector: string, autoSelectCurrentYear = true): void {
    let yearOptions: string = '';
    for (var i = 2010; i <= new Date().getFullYear() + 5; i++) {
        yearOptions += '<option value="' + i + '">' + i + '</option>';
    }
    $$(dropdownSelector).html(yearOptions);
    if (autoSelectCurrentYear) {
        $$(dropdownSelector).val(new Date().getFullYear());
    }
}

export function initializeStepper(element: string, autorepeat: boolean = true, initialValue: number = 1): void {
    let newStepper = app.stepper.create({ el: element, autorepeat: autorepeat });
    if (initialValue) {
        newStepper.setValue(initialValue);
    }
}

export function getStepperValue(element: string): number {
    let stepper = app.stepper.get(element);
    return stepper.getValue();
}

export function scrollToElement(selector: string): void {
    document.querySelector(selector).scrollIntoView({
        behavior: 'smooth'
    });
}

export function initializeTimePicker(input: string, presetTime?: string): any {
    const timePicker = app.picker.create({
        inputEl: input,
        rotateEffect: false,
        routableModals: false,
        formatValue: function (values: any) {
            return values[0] + ':' + values[1] + ' ' + values[2];
        },
        cols: [
            {
                textAlign: 'center',
                values: ('1 2 3 4 5 6 7 8 9 10 11 12').split(' ')
            },
            {
                textAlign: 'center',
                values: ('00 15 30 45').split(' ')
            },
            {
                textAlign: 'center',
                values: ('AM PM').split(' ')
            }
        ]
    });
    if (presetTime) {
        const timeDigit1: string = presetTime.split(':')[0];
        const timeDigit2: string = presetTime.split(':')[1];
        const timeDigit3: string = (presetTime.includes('AM') ? 'AM' : 'PM');
        timePicker.value = [timeDigit1, timeDigit2, timeDigit3];
        $$(input).val(presetTime);
    }
}

export function getSmartSelect(selector: string): any {
    return app.smartSelect.get(selector);
}

export function createSmartSelect(selector: string): any {
    return app.smartSelect.create({ el: selector, openIn: 'popup', searchbar: true, searchbarPlaceholder: 'Search' });
}

export function hideHiddenFeatures() {
    if (!getLocalAgencyInfo()) {
        setTimeout(perform, 1000)
    } else {
        perform();
    }
    function perform() {
        if (getLocalAgencyInfo()) { //We call this again because the perform() function could be called again
            if (getLocalAgencyInfo().hidefeatures?.shifts) {
                $$('.hidefeature_shifts').hide();
            }
            if (getLocalAgencyInfo().hidefeatures?.calendar) {
                $$('.hidefeature_calendar').hide();
            }
            if (getLocalAgencyInfo().hidefeatures?.locationnotes) {
                $$('.hidefeature_locationnotes').hide();
            }
            if (getLocalAgencyInfo().hidefeatures?.memberspage) {
                $$('.hidefeature_memberspage').hide();
            }
            if (getLocalAgencyInfo().hidefeatures?.forms) {
                $$('.hidefeature_forms').hide();
            }
            if (getLocalAgencyInfo().hidefeatures?.fines) {
                $$('.hidefeature_fines').hide();
            }
            if (getLocalAgencyInfo().hidefeatures?.locations) {
                $$('.hidefeature_locations').hide();
            }
            if (getLocalAgencyInfo().hidefeatures?.apparatus) {
                $$('.hidefeature_apparatus').hide();
            }
            if (getLocalAgencyInfo().hidefeatures?.smartrigalert) {
                $$('.hidefeature_smartrigalert').hide();
            }
            if (getLocalAgencyInfo().hidefeatures?.losap) {
                $$('.hidefeature_losap').hide();
            }
            if (getLocalAgencyInfo().hidefeatures?.complianceframeworks) {
                $$('.hidefeature_complianceframeworks').hide();
            }
        }
    }
}