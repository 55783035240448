import * as theme from './theme';
import '../css/accessibility.css';
import { Dom7 as $$ } from 'framework7';
import { listenForEvent } from './events';



export interface accessibilityOptions {
    dyslexicfont: boolean;
    darktheme: boolean;
    forcelighttheme: boolean;
    largecursor: boolean;
    grayscale: boolean;
    invertcolors: boolean;
    nofontkerning: boolean;
    smallcaps: boolean;
    donotdisplayimages: boolean;
    emphasizemenulinks: boolean;
    disabletextselection: boolean;
    uppercasetext: boolean;
    contrast: string;
    zoom: string;
    letterspacing: string;
    wordspacing: string;
    lineheight: string;
    brightness: string;
    saturation: string;
    sepia: string;
    huerotation: string;
    textcolor: string;
    caretcolor: string;
}



export function getDefaultAccessibilityOptions(): accessibilityOptions {
    return {
        dyslexicfont: false,
        darktheme: false,
        forcelighttheme: false,
        largecursor: false,
        grayscale: false,
        invertcolors: false,
        nofontkerning: false,
        smallcaps: false,
        donotdisplayimages: false,
        emphasizemenulinks: false,
        disabletextselection: false,
        uppercasetext: false,
        contrast: '',
        zoom: '',
        letterspacing: '',
        wordspacing: '',
        lineheight: '',
        brightness: '',
        saturation: '',
        sepia: '',
        huerotation: '',
        textcolor: '',
        caretcolor: ''
    };
}

function isAccessibilityOptionEnabled(options: accessibilityOptions): boolean {
    for (let i in options) {
        if (options[i]) {
            return true;
        }
    }
    return false;
}

export function getAndApplyLocalAccessibilityOptions() {
    let options: accessibilityOptions = getLocalAccessibilityOptions();
    if (isAccessibilityOptionEnabled(options)) {
        applyAccessibilityOptions(options);
    }

}

export function getLocalAccessibilityOptions(): accessibilityOptions {
    let initialLocalAccessibilityOptions: string = localStorage.getItem('accessibilityoptions');
    if (initialLocalAccessibilityOptions) {
        let localAccessibilityOptions: accessibilityOptions = JSON.parse(initialLocalAccessibilityOptions);
        return localAccessibilityOptions;
    } else {
        return getDefaultAccessibilityOptions();
    }
}

export function setLocalAccessibilityOptions(newOptions: accessibilityOptions) {
    localStorage.setItem('accessibilityoptions', JSON.stringify(newOptions));
}

export function applyAccessibilityOptions(options: accessibilityOptions) {
    if (options.dyslexicfont) {
        applyAccessibilityOption_DyslexicFont();
    }
    applyAccessibilityOption_DarkTheme(options.darktheme);
    if (options.forcelighttheme) {
        applyAccessibilityOption_ForceLightTheme();
    }
    
    applyAccessibilityOption_Grayscale(options.grayscale);
    applyAccessibilityOption_InvertColors(options.invertcolors);
    applyAccessibilityOption_NoFontKerning(options.nofontkerning);
    applyAccessibilityOption_SmallCaps(options.smallcaps);
    if (options.donotdisplayimages) {
        applyAccessibilityOption_DoNotDisplayImages();
    }
    listenForEvent('pageInit', function (pageName: string) {
        if (options.emphasizemenulinks) {
            applyAccessibilityOption_EmphasizeMenuLinks();
        } 
        if (options.largecursor) {
            applyAccessibilityOption_LargeCursor();
        }
    });
    
    if (options.disabletextselection) {
        applyAccessibilityOption_DisableTextSelection();
    }
    if (options.uppercasetext) {
        applyAccessibilityOption_UpperCaseText();
    }
    applyAccessibilityOption_Contrast(options.contrast);
    applyAccessibilityOption_Zoom(options.zoom);
    applyAccessibilityOption_LetterSpacing(options.letterspacing);
    applyAccessibilityOption_WordSpacing(options.wordspacing);
    applyAccessibilityOption_LineHeight(options.lineheight);
    applyAccessibilityOption_Brightness(options.brightness);
    applyAccessibilityOption_Saturation(options.saturation);
    applyAccessibilityOption_Sepia(options.sepia);
    applyAccessibilityOption_HueRotation(options.huerotation);
    applyAccessibilityOption_CaretColor(options.caretcolor);
    applyAccessibilityOption_TextColor(options.textcolor);
}

async function applyAccessibilityOption_DyslexicFont() {
    const fontCSS = await import('../css/dyslexic.css');
}

function applyAccessibilityOption_DarkTheme(value: boolean) {
    if (value) {
        theme.applyDarkTheme();
    } else {
        applyAccessibilityOption_ForceLightTheme();
    }
}

function applyAccessibilityOption_ForceLightTheme() {
    theme.applyLightTheme();
}

// !TODO: run every time on page init
function applyAccessibilityOption_LargeCursor() {
    $$('body').addClass('accessibility_largecursor');
    $$('div').addClass('accessibility_largecursor');
    $$('input').addClass('accessibility_largecursor');
    $$('i').addClass('accessibility_largecursor');
    $$('a').addClass('accessibility_largecursor');
    $$('select').addClass('accessibility_largecursor');
    $$('textarea').addClass('accessibility_largecursor');
}

function applyAccessibilityOption_Grayscale(value: boolean) {
    if (value) {
        $$('body').addClass('accessibility_grayscale');
    } else {
        $$('body').removeClass('accessibility_grayscale');
    }
}

function applyAccessibilityOption_InvertColors(value: boolean) {
    if (value) {
        $$('body').addClass('accessiblity_invertcolors');
    } else {
        $$('body').removeClass('accessiblity_invertcolors');
    }
}

function applyAccessibilityOption_NoFontKerning(value: boolean) {
    if (value) {
        $$('body').addClass('nofontkerning');
    } else {
        $$('body').removeClass('nofontkerning');
    }
}

function applyAccessibilityOption_SmallCaps(value: boolean) {
    if (value) {
        $$('body').addClass('accessibility_smallcaps');
    } else {
        $$('body').removeClass('accessibility_smallcaps');
    }
}

function applyAccessibilityOption_Contrast(value: string) {
    switch (value) {
        case '150':
            $$('body').addClass('accessibility_contrast_150');
            break;
        case '200':
            $$('body').addClass('accessibility_contrast_200');
            break;
        default:
            $$('body').removeClass('accessibility_contrast_150');
            $$('body').removeClass('accessibility_contrast_200');
    }
}

function applyAccessibilityOption_Zoom(value: string) {
    switch (value) {
        case '10':
            $$('body').addClass('accessibility_zoom_10percent');
            break;
        case '15':
            $$('body').addClass('accessibility_zoom_15percent');
            break;
        default:
            $$('body').removeClass('accessibility_zoom_10percent');
            $$('body').removeClass('accessibility_zoom_15percent');
    }
}

function applyAccessibilityOption_LetterSpacing(value: string) {
    switch (value) {
        case '1':
            $$('body').addClass('accessibility_letterspacing_1px');
            break;
        case '2':
            $$('body').addClass('accessibility_letterspacing_2px');
            break;
        case '3':
            $$('body').addClass('accessibility_letterspacing_3px');
            break;
        case '4':
            $$('body').addClass('accessibility_letterspacing_4px');
            break;
        default:
            $$('body').removeClass('accessibility_letterspacing_1px');
            $$('body').removeClass('accessibility_letterspacing_2px');
            $$('body').removeClass('accessibility_letterspacing_3px');
            $$('body').removeClass('accessibility_letterspacing_4px');
    }
}

function applyAccessibilityOption_WordSpacing(value: string) {
    switch (value) {
        case '3':
            $$('body').addClass('accessibility_wordspacing_3px');
            break;
        case '5':
            $$('body').addClass('accessibility_wordspacing_5px');
            break;
        case '7':
            $$('body').addClass('accessibility_wordspacing_7px');
            break;
        case '9':
            $$('body').addClass('accessibility_wordspacing_9px');
            break;
        default:
            $$('body').removeClass('accessibility_wordspacing_3px');
            $$('body').removeClass('accessibility_wordspacing_5px');
            $$('body').removeClass('accessibility_wordspacing_7px');
            $$('body').removeClass('accessibility_wordspacing_9px');
    }
}

function applyAccessibilityOption_LineHeight(value: string) {
    switch (value) {
        case '1.8':
            $$('body').addClass('accessibility_lineheight_1point8');
            break;
        case '2':
            $$('body').addClass('accessibility_lineheight_2');
            break;
        case '2.2':
            $$('body').addClass('accessibility_lineheight_2point2');
            break;
        case '2.4':
            $$('body').addClass('accessibility_lineheight_2point4');
            break;
        default:
            $$('body').removeClass('accessibility_lineheight_1point8');
            $$('body').removeClass('accessibility_lineheight_2');
            $$('body').removeClass('accessibility_lineheight_2point2');
            $$('body').removeClass('accessibility_lineheight_2point4');
    }
}

function applyAccessibilityOption_Brightness(value: string) {
    switch (value) {
        case '95':
            $$('body').addClass('accessibility_brightness_95');
            break;
        case '90':
            $$('body').addClass('accessibility_brightness_90');
            break;
        case '85':
            $$('body').addClass('accessibility_brightness_85');
            break;
        case '80':
            $$('body').addClass('accessibility_brightness_80');
            break;
        case '75':
            $$('body').addClass('accessibility_brightness_75');
            break;
        default:
            $$('body').removeClass('accessibility_brightness_95');
            $$('body').removeClass('accessibility_brightness_90');
            $$('body').removeClass('accessibility_brightness_85');
            $$('body').removeClass('accessibility_brightness_80');
            $$('body').removeClass('accessibility_brightness_75');
    }
}

function applyAccessibilityOption_Saturation(value: string) {
    switch (value) {
        case '200':
            $$('body').addClass('accessibility_saturation_200');
            break;
        case '300':
            $$('body').addClass('accessibility_saturation_300');
            break;
        case '400':
            $$('body').addClass('accessibility_saturation_400');
            break;
        default:
            $$('body').removeClass('accessibility_saturation_200');
            $$('body').removeClass('accessibility_saturation_300');
            $$('body').removeClass('accessibility_saturation_400');
    }
}

function applyAccessibilityOption_Sepia(value: string) {
    switch (value) {
        case '25':
            $$('body').addClass('accessibility_sepia_25');
            break;
        case '50':
            $$('body').addClass('accessibility_sepia_50');
            break;
        case '75':
            $$('body').addClass('accessibility_sepia_75');
            break;
        case '100':
            $$('body').addClass('accessibility_sepia_100');
            break;
        default:
            $$('body').removeClass('accessibility_sepia_25');
            $$('body').removeClass('accessibility_sepia_50');
            $$('body').removeClass('accessibility_sepia_75');
            $$('body').removeClass('accessibility_sepia_100');
    }
}

function applyAccessibilityOption_HueRotation(value: string) {
    switch (value) {
        case '45':
            $$('body').addClass('accessibility_huerotation_45');
            break;
        case '90':
            $$('body').addClass('accessibility_huerotation_90');
            break;
        case '135':
            $$('body').addClass('accessibility_huerotation_135');
            break;
        case '180':
            $$('body').addClass('accessibility_huerotation_180');
            break;
        case '225':
            $$('body').addClass('accessibility_huerotation_225');
            break;
        case '270':
            $$('body').addClass('accessibility_huerotation_270');
            break;
        case '315':
            $$('body').addClass('accessibility_huerotation_315');
            break;
        default:
            $$('body').removeClass('accessibility_huerotation_45');
            $$('body').removeClass('accessibility_huerotation_90');
            $$('body').removeClass('accessibility_huerotation_135');
            $$('body').removeClass('accessibility_huerotation_180');
            $$('body').removeClass('accessibility_huerotation_225');
            $$('body').removeClass('accessibility_huerotation_270');
            $$('body').removeClass('accessibility_huerotation_315');
    }
}

function applyAccessibilityOption_CaretColor(value: string) {
    switch (value) {
        case 'red':
            $$('body').addClass('accessibility_caretcolor_red');
            break;
        case 'blue':
            $$('body').addClass('accessibility_caretcolor_blue');
            break;
        case 'green':
            $$('body').addClass('accessibility_caretcolor_green');
            break;
        case 'yellow':
            $$('body').addClass('accessibility_caretcolor_yellow');
            break;
        case 'gray':
            $$('body').addClass('accessibility_caretcolor_gray');
            break;
        case 'orange':
            $$('body').addClass('accessibility_caretcolor_orange');
            break;
        case 'brown':
            $$('body').addClass('accessibility_caretcolor_brown');
            break;
        case 'white':
            $$('body').addClass('accessibility_caretcolor_white');
            break;
        default:
            $$('body').removeClass('accessibility_caretcolor_red');
            $$('body').removeClass('accessibility_caretcolor_blue');
            $$('body').removeClass('accessibility_caretcolor_green');
            $$('body').removeClass('accessibility_caretcolor_yellow');
            $$('body').removeClass('accessibility_caretcolor_gray');
            $$('body').removeClass('accessibility_caretcolor_orange');
            $$('body').removeClass('accessibility_caretcolor_brown');
            $$('body').removeClass('accessibility_caretcolor_white');
    }
}

async function applyAccessibilityOption_DoNotDisplayImages() {
    const hideImages = await import('../css/hideimages.css');
}

function applyAccessibilityOption_EmphasizeMenuLinks() {
    let lis = document.getElementsByTagName("li");
    for (let i = 0; i < lis.length; i++) {
        let li = lis[i];
        li.addEventListener("mouseover", function () {
            if (this.getElementsByTagName("a")[0]) {
                this.getElementsByTagName("a")[0].style.border = "1px solid darkorange";
            }
        });
        li.addEventListener("mouseout", function () {
            if (this.getElementsByTagName("a")[0]) {
                this.getElementsByTagName("a")[0].style.border = "none";
            }
        });
    }
}

async function applyAccessibilityOption_DisableTextSelection() {
    const disableTextSelection = await import('../css/disabletextselection.css');
}

async function applyAccessibilityOption_UpperCaseText() {
    const upperCaseText = await import('../css/uppercasetext.css');
}

function applyAccessibilityOption_TextColor(value: string) {
    switch (value) {
        case 'red':
            $$('body').addClass('accessibility_textcolor_red');
            break;
        case 'blue':
            $$('body').addClass('accessibility_textcolor_blue');
            break;
        case 'green':
            $$('body').addClass('accessibility_textcolor_green');
            break;
        case 'yellow':
            $$('body').addClass('accessibility_textcolor_yellow');
            break;
        case 'gray':
            $$('body').addClass('accessibility_textcolor_gray');
            break;
        case 'orange':
            $$('body').addClass('accessibility_textcolor_orange');
            break;
        case 'brown':
            $$('body').addClass('accessibility_textcolor_brown');
            break;
        case 'white':
            $$('body').addClass('accessibility_textcolor_white');
            break;
        default:
            $$('body').removeClass('accessibility_textcolor_red');
            $$('body').removeClass('accessibility_textcolor_blue');
            $$('body').removeClass('accessibility_textcolor_green');
            $$('body').removeClass('accessibility_textcolor_yellow');
            $$('body').removeClass('accessibility_textcolor_gray');
            $$('body').removeClass('accessibility_textcolor_orange');
            $$('body').removeClass('accessibility_textcolor_brown');
            $$('body').removeClass('accessibility_textcolor_white');
    }
}