/**
 * If a number is odd.
 * @param {number} num The number to check.
 * @returns {boolean} True if odd.
 */
export function isOdd(num: number): boolean {
    if (num % 2) {
        return true;
    } else {
        return false;
    }
}

/**
 * Opens a URL in a new tab. Uses rel="noopener".
 * @param url The URL to open.
 */
export function openURLInNewTab(url: string): void {
    window.open(url, '_blank').opener = null;
}

export function reloadPage(): void {
    window.location.reload();
}

export function historyPushState(url: string): void {
    window.history.pushState({}, null, url);
}

export function historyReplaceState(url: string): void {
    window.history.replaceState({}, null, url);
}

/**
 * Gets the height of the page.
 * @returns {number} Height of the page in pixels.
 */
export function getPageHeight(): number {
    return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.documentElement.clientHeight
    );
}

/**
 * Gets the width of the page.
 * @returns {number} Width of the page in pixels.
 */
export function getPageWidth(): number {
    return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
    );
}

/**
 * Returns the locale/language of the current browser.
 * @returns {string} The locale/language of the browser.
 */
export function getLocale(): string {
    //For some reason typescript doesn't have userLanguage as a property of navigator
    //See: https://techoverflow.net/2018/02/06/how-to-fix-error-ts2339-property-userlanguage-does-not-exist-on-type-navigator/
    //@ts-ignore
    return navigator.language || navigator.userLanguage;
}

/**
 * 
 * @param {any} time - The epoch time to format. Call .toMillis() on firebase timestamps. Or if Date object, call .getTime().
 * @param {string} format - How to format the timestamp. Can be 'full', 'full_with_seconds', 'date', 'time', 'chart'.
 * @returns {string} The formatted timestamp.
 */
export function formatTimeStamp(time: any, format: 'full' | 'full_with_seconds' | 'date' | 'date_dashes' | 'time' | 'chart' = 'full'): string {
    let formatted: string;
    if (format === 'full') {
        formatted = new Date(parseFloat(time)).toLocaleDateString(getLocale(), {
            hour: "2-digit",
            minute: "2-digit"
        });
    } else if (format === 'full_with_seconds') {
        formatted = new Date(parseFloat(time)).toLocaleDateString(getLocale(), {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
        });
    } else if (format === 'date') {
        formatted = new Date(parseFloat(time)).toLocaleDateString(getLocale(), {
            hour: "2-digit",
            minute: "2-digit"
        }).split(', ')[0];
    } else if (format === 'date_dashes') {
        formatted = new Date(parseFloat(time)).toLocaleDateString(getLocale(), {
            hour: "2-digit",
            minute: "2-digit"
        }).split(', ')[0];
        formatted = formatted.split('/').join('-');
        formatted = formatted.split('\\').join('-');
    } else if (format === 'time') {
        formatted = new Date(parseFloat(time)).toLocaleDateString(getLocale(), {
            hour: "2-digit",
            minute: "2-digit"
        }).split(', ')[1];
    } else if (format === 'chart') {
        let d = new Date(time);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();
        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }
        formatted = [year, month, day].join('-');
    }
    return formatted;
}

/**
 * Shortens text if longer than a specified length and trims it with an ellipsis.
 * @param {string} body - The text to shorten.
 * @param {number} maxlength - The maximum length the text can be.
 * @returns {string} The shortened text.
 */
export function shortenText(body: string, maxlength: number): string {
    return (body.length > maxlength) ? body.substring(0, maxlength) + '...' : body;
}

/**
 * Returns the file extension of a path or filename.
 * @param {string} str - The string of the filename/path.
 * @returns {string} The file extension (without a period).
 */
export function getFileExtension(str: string): string {
    return str.match(/\.([^\.]+)$/)[1];
}

/**
 * Gets a file name of a file without the extension.
 * E.g. "myfile.png" to "myfile".
 * @param {string} str The filename to format.
 * @returns {string} The file name without the extension.
 */
export function getFileNameWithoutExtension(str: string): string {
    return str.replace(/\.[^/.]+$/, "");
}

/**
 * Generates a random ID string consisting of upper/lower case letters, and numbers.
 * @param {number} length How long the ID should be (how many characters).
 * @returns {string} The randomly generated ID.
 */
export function generateID(length: number = 32): string {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

/**
 * Converts a Base64 string to binary.
 * @param {string} dataURI The Base64 string.
 * @returns {Uint8Array} The converted binary.
 */
export function convertBase64ToBinary(dataURI: string): Uint8Array {
    let base64Marker = ';base64,';
    let base64Index = dataURI.indexOf(base64Marker) + base64Marker.length;
    let base64 = dataURI.substring(base64Index);
    let raw = window.atob(base64);
    let rawLength = raw.length;
    let array = new Uint8Array(new ArrayBuffer(rawLength));

    for (var i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
    }
    return array;
}

export function convertBinaryToBase64(blob: Blob): Promise<string> {
    return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        reader.onloadend = () => {
            //@ts-ignore
            const unprocessed: string = reader.result;
            const base64Marker = ';base64,';
            if (unprocessed.includes(base64Marker)) {
                let base64Index = unprocessed.indexOf(base64Marker) + base64Marker.length;
                let base64 = unprocessed.substring(base64Index);
                resolve(base64);
            } else {
                resolve(unprocessed);
            }
        };
        reader.readAsDataURL(blob);
    });
}

//Based on https://stackoverflow.com/questions/63721110/how-can-i-convert-image-url-to-base64-string
export function getBase64ImageFromUrl(imageUrl: string): Promise<string> {
    return new Promise(async function (resolve, reject) {
        let res = await fetch(imageUrl);
        let blob = await res.blob();
        let reader = new FileReader();
        reader.addEventListener("load", function () {
            //@ts-ignore
            resolve(reader.result);
        }, false);
        reader.onerror = () => {
            reject(this);
        };
        reader.readAsDataURL(blob);
    });
}

export function convertPixelsToMillimeters(pixels: number, round = false): number {
    if (round) {
        return Math.round(pixels / 3.7795275591);
    } else {
        return pixels / 3.7795275591;
    }
}

/**
 * Gets the time remaining between two dates, as an object of different units, starting from days.
 * The units are: days, hours, minutes, seconds. Also includes "total" which is the total milliseconds remaining.
 * @param {Date} startTime The starting date.
 * @param {Date} endTime The ending date.
 * @returns {any} Object of breakdown of remaining time between start date and end date.
 */
export function getTimeRemainingBetweenTwoDates(startTime: Date, endTime: Date): any {
    let t = endTime.getTime() - startTime.getTime();
    let seconds = Math.floor((t / 1000) % 60);
    let minutes = Math.floor((t / 1000 / 60) % 60);
    let hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    let days = Math.floor(t / (1000 * 60 * 60 * 24));
    return {
        total: t,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds
    };
}

/**
 * Converts a number of seconds into a formatted string displaying how many hours, minutes, seconds that is.
 * @param secs - The number of seconds.
 * @returns {string} String in the format of '0H, 0M, 0S'. H or M is hidden if not enough seconds.
 */
export function convertSecondsToTimeString(secs: number): string {
    let thours = Math.floor(secs / 3600);
    let tminutes = (Math.floor(secs / 60) - (thours * 60));
    let tseconds = (secs - (Math.floor(secs / 60) * 60));
    let ret = '';
    if (thours) {
        ret = thours + 'H, ' + tminutes + 'M, ' + tseconds + 'S';
    } else if (tminutes) {
        ret = tminutes + 'M, ' + tseconds + 'S';
    } else {
        ret = tseconds + 'S';
    }
    return ret;
}

/**
 * Converts a time object (from getTimeRemaining()) to seconds.
 * @param timeobject - The remaining time object returned by getTimeRemaining().
 * @returns {number} The total seconds remaining.
 */
export function convertTotalTimeToSeconds(timeobject: any): number {
    let ret: number = 0;
    ret = timeobject.seconds;
    if (timeobject.minutes) {
        ret += timeobject.minutes * 60;
    }
    if (timeobject.hours) {
        ret += timeobject.hours * 3600;
    }
    if (timeobject.days) {
        ret += timeobject.days * 86400;
    }
    return ret;
}

/**
 * Checks if two objects are the same.
 * Only does a shallow check - first level keys.
 * @param {any} object1 First object to compare.
 * @param {any} object2 Second object to compare.
 * @returns {boolean} True if equivalent.
 */
export function shallowObjectEquals(object1: any, object2: any): boolean {
    const keys1: string[] = Object.keys(object1);
    const keys2: string[] = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }
    return true;
}

/**
 * Removes trailing comma and space (if there is one) from a string if it is there.
 * E.g. "1, 2, 3, 4, " or "1, 2, 3, 4," becomes "1, 2, 3, 4". 
 * @param {string} str The string to check and replace.
 * @returns {string} The string with trailing comma/space removed.
 */
export function trimTrailingCommaSeparator(str: string): string {
    if (str.endsWith(', ')) {
        return str.substring(0, str.length - 2);
    } else if (str.endsWith(',')) {
        return str.substring(0, str.length - 1);
    } else {
        return str;
    }
}

export function getDateAtLastDayOfMonth(month: number, year: number): Date {
    return new Date(year, month + 1, 0);
}

export function formatRoleLabel(role: string): string {
    switch (role) {
        case 'admin':
            return 'Admin';
        case 'member':
            return 'Member';
    }
}

export function stripDatabaseDocument(obj: any, additionalKeys?: string[]): any {
    let newObj = { ...obj };
    delete newObj.id;
    delete newObj.exists;
    if (additionalKeys) {
        for (let i in additionalKeys) {
            if (newObj.hasOwnProperty(additionalKeys[i])) {
                delete newObj[additionalKeys[i]];
            }
        }
    }
    return newObj;
}

//Starts at 0, same as js date object
export function convertMonthNumberToString(monthNumber: number): string {
    switch (monthNumber) {
        case 0:
            return 'January';
        case 1:
            return 'February';
        case 2:
            return 'March';
        case 3:
            return 'April';
        case 4:
            return 'May';
        case 5:
            return 'June';
        case 6:
            return 'July';
        case 7:
            return 'August';
        case 8:
            return 'September';
        case 9:
            return 'October';
        case 10:
            return 'November';
        case 11:
            return 'December';
    }
}

//Starts at 0, same as js date object
export function convertMonthStringToNumber(monthString: string): number {
    switch (monthString) {
        case 'January':
            return 0;
        case 'February':
            return 1;
        case 'March':
            return 2;
        case 'April':
            return 3;
        case 'May':
            return 4;
        case 'June':
            return 5;
        case 'July':
            return 6;
        case 'August':
            return 7;
        case 'September':
            return 8;
        case 'October':
            return 9;
        case 'November':
            return 10;
        case 'December':
            return 11;
    }
}

export function copyTextToClipboard(text: string) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function () {
        //do nothing
    }, function (err) {
        //do nothing
    });
    function fallbackCopyTextToClipboard(text: string) {
        let textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            let successful = document.execCommand('copy');
            let msg = successful ? 'successful' : 'unsuccessful';
        } catch (err) {
            //do nothing
        }

        document.body.removeChild(textArea);
    }
}

/**
* Rounds a specified number of minutes to the nearest increment of 15.
* Between 0-60. (0, 15, 30, 45, 60).
* @param {number} minutes The minutes to round.
* @returns {number} The rounded minutes.
*/
export function roundMinutesToQuarterHour(minutes: number): number {
    return (Math.round(minutes / 15) * 15) % 60;
}

export function getCleanDate(dateToClean?: Date): Date {
    const retDate: Date = (dateToClean ? new Date(dateToClean.getTime()) : new Date());
    retDate.setMinutes(0);
    retDate.setSeconds(0);
    retDate.setMilliseconds(0);
    return retDate;
}

/**
 * Converts a time picker's value to a Date object.
 * @param {string} value Time picker value to convert.
 * @returns {Date} Date object from time picker value. 
 */
export function convertTimePickerValueToDate(value: string): Date {
    let valueHour: number = Number(value.split(':')[0]);
    let valueMinutes: number = Number(value.split(':')[1].split(' ')[0]);
    let convertedDate: Date = new Date();
    if (value.includes('PM')) {
        convertedDate.setHours((valueHour === 12 ? 12 : valueHour + 12), valueMinutes, 0);
    } else {
        convertedDate.setHours((valueHour === 12 ? 0 : valueHour), valueMinutes, 0);
    }
    convertedDate.setSeconds(0);
    convertedDate.setMilliseconds(0);
    return convertedDate;
}

/**
 * Converts a date object to a time picker value.
 * @param {Date} date Date object to convert.
 * @returns {string} Time picker value string converted from date.
 */
export function convertDateToTimePickerValue(date: Date): string {
    let convertedValue: string = '';
    let amPM: string = '';

    if (date.getHours() === 0) { //Midnight
        amPM = 'AM';
        convertedValue = '12';
    } else if (date.getHours() === 12) { //12PM
        amPM = 'PM';
        convertedValue = '12';
    } else {
        if (date.getHours() < 12) { //AM
            amPM = 'AM';
            convertedValue = date.getHours().toString();
        } else {
            amPM = 'PM';
            convertedValue = (date.getHours() - 12).toString();
        }
    }

    convertedValue += ':';

    convertedValue += (date.getMinutes() === 0 ? '00' : date.getMinutes().toString());

    convertedValue += ' ' + amPM;

    return convertedValue;
}

export function getRandomNumber(maxNumber = 1000): number {
    return Math.floor(Math.random() * maxNumber)
}

export function documentContainsElement(element: Element): Promise<void> {
    return new Promise(function (resolve, reject) {
        const observer = new MutationObserver(function (mutations) {
            if (document.contains(element)) {
                resolve();
                observer.disconnect();
            }
        });
        observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
    });
}

export function removeItemFromArray(arr: any[], item: string | number): any[] {
    let newArray: any[] = [...arr];
    const index = newArray.indexOf(item);
    if (index > -1) {
        newArray.splice(index, 1);
    }
    return newArray
}

export function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

//Helps handle dates that are restored from localstorage
export function getDateMillis(dateItem: any): number {
    if (typeof dateItem === 'string') {
        return new Date(dateItem).getTime();
    } else if (typeof dateItem.getMonth === 'function') {
        return dateItem.getTime();
    } else if (typeof dateItem.toMillis === 'function') {
        return dateItem.toMillis();
    } else if (dateItem?.fromBackendTimestamp) {
        return dateItem.millis;
    }
}

//Parses money stored in cents to normalized amount if needed, and returns formatted amount
//E.g. 150 converts to 1.50 ($1.50)
export function parseMoney(amount: number, originalInCents = true): string {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const numberAmount = (originalInCents ? amount / 100 : amount);
    return formatter.format(numberAmount);
}