import { getAgencyID } from './agency';
import { getAuth, getIdToken  } from "firebase/auth";
import { app } from './libs/framework7';
import getIfDevelopmentMode from './developmentmode';
import { logErrorToServer } from './debug';



export function getAuthInfoForServerFunction(): Promise<any> {
    return new Promise(function (resolve, reject) {
        if (!getAgencyID() || !getAuth() || !getAuth().currentUser) {
            reject();
            return;
        }
        getIdToken(getAuth().currentUser).then(function (token: string) {
            resolve({ token: token, agencyid: getAgencyID() });
        });
    });
}

function processAPIArgs(args: any, includeAuth: boolean): Promise<any> {
    return new Promise(function (resolve, reject) {
        if (includeAuth) {
            let fullArgs = args;
            getAuthInfoForServerFunction().then(function (authInfo) {
                fullArgs['auth'] = authInfo;
                resolve(fullArgs);
            });
        } else {
            resolve(args);
        }
    });
}

export default function callBackendFunction(path: string, args: any = {}, includeAuth = true, post = false): Promise<any> {
    return new Promise(function (resolve, reject) {
        processAPIArgs(args, includeAuth).then(function (useArgs: any) {
            if (post) {
                app.request.post(getBackendAPIURL() + path, useArgs).then(function (response: any) {
                    try {
                        if (JSON.parse(response.data)) {
                            resolve(JSON.parse(response.data));
                        } else {
                            resolve(response);
                        }
                    } catch (e) {
                        resolve(response);
                    }
                }).catch(function (error: any) {
                    if (error) {
                        reject(error);
                    } else {
                        reject();
                    }
                });
            } else {
                app.request.get(getBackendAPIURL() + path, useArgs).then(function (response: any) {
                    try {
                        if (JSON.parse(response.data)) {
                            resolve(JSON.parse(response.data));
                        } else {
                            resolve(response);
                        }
                    } catch (error) {
                        logErrorToServer(error, 'callBackendFunction():1|Path: ' + path);
                        resolve(response);
                    }
                }).catch(function (error: any) {
                    if (error) {
                        logErrorToServer(error, 'callBackendFunction():2|Path: ' + path);
                        reject(error);
                    } else {
                        logErrorToServer('', 'callBackendFunction():3|Path: ' + path);
                        reject();
                    }
                });
            }
            
        });
    });
}

export function getBackendAPIURL(): string {
    let url: string = process.env.BACKEND_API_URL;
    if (getIfDevelopmentMode()) {
        url = process.env.BACKEND_API_EMULATOR_URL;
    }
    return url;
}