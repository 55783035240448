import * as ui from '../ui';
import { Dom7 as $$ } from 'framework7';
import { mainView } from '../libs/framework7';
import { getURLParameter, getURLQuery } from '../urlparameters';
import { loadComplianceFrameworksDropdown, loadStatisticsDropdown } from '../dropdowns';



export default async function displayAdminReportOptionsPopup(reportID: string, reload = false, year = false, month = false, complianceFramework = false, customStatistic = false) {
    ui.displayLoadPreloader();
    const popupSource = await import('../../popups/admin/reportoptions.html');
    ui.displayPopup(popupSource, true, undefined, async function () {
        ui.handleClick('.popup_admin_reportoptions_button_run', function () {
            run(reportID, reload, year, month, complianceFramework, customStatistic);
        });
        $$('.popup_admin_reportoptions_item').hide();
        ui.loadDropdownYears('#admin_reportoptions_year');
        ui.loadDropdownMonths('#admin_reportoptions_month');
        if (year) {
            $$('.popup_admin_reportoptions_disp_year').show();
            if (getURLQuery('year')) {
                $$('#admin_reportoptions_year').val(getURLQuery('year'));
            }
        }
        if (month) {
            $$('.popup_admin_reportoptions_disp_month').show();
            if (getURLQuery('month')) {
                $$('#admin_reportoptions_month').val(getURLQuery('month'));
            }
        }
        if (complianceFramework) {
            $$('.popup_admin_reportoptions_disp_complianceframework').show();
            await loadComplianceFrameworksDropdown('#admin_reportoptions_complianceframework', getURLQuery('complianceframework'));
        }
        if (customStatistic) {
            $$('.popup_admin_reportoptions_disp_customstatistic').show();
            await loadStatisticsDropdown('#admin_reportoptions_customstatistic', getURLQuery('customstatistic'));
        }
        ui.hideLoadPreloader();
    });
}

function run(reportID: string, reload = false, year = false, month = false, complianceFramework = false, customStatistic = false) {
    let url: string = '/admin/reports/' + reportID + '?reportviewer=true';
    if (year) {
        url += '&year=' + ui.getInputValue('#admin_reportoptions_year');
    }
    if (month) {
        url += '&month=' + ui.getInputValue('#admin_reportoptions_month');
    }
    if (complianceFramework) {
        url += '&complianceframework=' + ui.getInputValue('#admin_reportoptions_complianceframework');
    }
    if (customStatistic) {
        url += '&customstatistic=' + ui.getInputValue('#admin_reportoptions_customstatistic');
    }
    mainView.router.navigate(url, { reloadCurrent: reload });
    ui.closePopup('.popup-adminreportoptions');
}