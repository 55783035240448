import { listenForEvent } from './events';
import { mainView } from './libs/framework7';
import { displayHeaderInfo, displayMobileMenuButtonBasedOnScreenSize } from './menu';
import * as menu from './menu';
import { applyAgencyBrandingSettings } from './branding';
import { hideHiddenFeatures } from './ui';



export function setupPageInitHandler() {
    listenForEvent('pageInit', function (pageName: string) {
        runPageInitFunctions();
    });
    listenForEvent('menuPageInit', function (pageName: string) {
        runPageInitFunctions(false);
        displayHeaderInfo();
        hideHiddenFeatures();
    });
    listenForEvent('updateMenuActiveButton', function () {
        updateMenuActiveButton();
    });
}

export function runPageInitFunctions(headerInfo: boolean = true) {
    if (headerInfo) {
        displayHeaderInfo();
    }
    displayMobileMenuButtonBasedOnScreenSize();
    if (mainView.router.currentRoute?.route?.menu) {
        menu.showMenu(false);
        menu.navigate(mainView.router.currentRoute.route.menu);
    } else if (mainView.router.currentRoute?.route?.hideMenu) {
        menu.hideMenu();
    }
    updateMenuActiveButton();
    applyAgencyBrandingSettings();
    hideHiddenFeatures();
}

function updateMenuActiveButton() {
    if (mainView.router.currentRoute?.route?.menuButton) {
        menu.setActiveButton(mainView.router.currentRoute?.route?.menuButton);
    }
}