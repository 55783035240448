import { Panel } from 'framework7/types/components/panel/panel';
import { leftView, app } from './libs/framework7';
import { Dom7 as $$ } from 'framework7';
import { getAgencyInfo } from './agency';
import { getUserInfo, userIsSignedIn, getUserRole } from './user';
import { agencyDocument, userDocument } from './databaseinterfaces';
import { formatRoleLabel } from './utils';
import { applySkeletonTextEffect, removeSkeletonTextEffect } from './ui';



/**
 * Recreates and displays the left menu on the page.
 */
export function showMenu(open: boolean = true) {
    let leftpanel: Panel.Panel = app.panel.get('.panel-left');
    leftpanel.enableVisibleBreakpoint();
    if (open) {
        openMenu(false);
    }
}

/**
 * Removes the left menu on the page.
 */
export function hideMenu() {
    let leftpanel: Panel.Panel = app.panel.get('.panel-left');
    closeMenu(false);
    leftpanel.disableVisibleBreakpoint();
}

export function openMenu(animate: boolean = false) {
    let leftpanel: Panel.Panel = app.panel.get('.panel-left');
    leftpanel.open(animate);
}

export function closeMenu(animate: boolean = false) {
    let leftpanel: Panel.Panel = app.panel.get('.panel-left');
    leftpanel.close(animate);
}

export function navigate(url: string) {
    leftView.router.navigate(url);
}

export function resetActiveButtons() {
    $$('.menu_button').removeClass('item-selected');
}

export function setActiveButton(buttonClass: string) {
    resetActiveButtons();
    $$('.' + buttonClass).addClass('item-selected');
}

export async function displayHeaderInfo() {
    applySkeletonTextEffect('.menu_header_account_name');
    applySkeletonTextEffect('.menu_header_account_agency');
    applySkeletonTextEffect('.menu_header_account_role');
    applySkeletonTextEffect('.menu_header_account_location');
    const isSignedIn = await userIsSignedIn();
    if (isSignedIn) {
        getAgencyInfo().then(function (agencyInfo: agencyDocument) {
            $$('.menu_header_account_agency').text(agencyInfo.name);
            removeSkeletonTextEffect('.menu_header_account_agency');
        });
        getUserInfo().then(function (userInfo: userDocument) {
            $$('.menu_header_account_name').text(userInfo.firstname + ' ' + userInfo.lastname);
            $$('.menu_header_account_role').text(formatRoleLabel(userInfo.role));
            removeSkeletonTextEffect('.menu_header_account_name');
            removeSkeletonTextEffect('.menu_header_account_role');
            if (userInfo.location_id) {
                $$('.menu_header_account_location').text(userInfo.location_name);
            } else {
                $$('.menu_header_account_location').text('');
            }
            removeSkeletonTextEffect('.menu_header_account_location');
            updateMenuUI(); //!TODO: move to more logical place
        });
    } else {
        $$('.menu_header_account_name').text('Not Signed In');
        $$('.menu_header_account_agency').text('');
        $$('.menu_header_account_role').text('');
        $$('.menu_header_account_location').text('');
        removeSkeletonTextEffect('.menu_header_account_name');
        removeSkeletonTextEffect('.menu_header_account_agency');
        removeSkeletonTextEffect('.menu_header_account_role');
        removeSkeletonTextEffect('.menu_header_account_location');
    }
}

export function updateMenuUI() {
    if (getUserRole() === 'admin') {
        $$('.menu_button_admin').show();
    } else {
        $$('.menu_button_admin').hide();
    }
}

export function displayMobileMenuButtonBasedOnScreenSize() {
    if (window.innerWidth >= 960) {
        $$('.mobilemenubutton').hide();
    } else {
        $$('.mobilemenubutton').show();
    }
}