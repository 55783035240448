import { getLocalAgencyInfo } from './agency';
import { Dom7 as $$ } from 'framework7';
import { getFileURLFromStorage } from './fileupload';



let brandingApplied: boolean = false;

export function applyAgencyBrandingSettings(force = false) {
    if (getLocalAgencyInfo() && getLocalAgencyInfo().branding) {
        if (!brandingApplied) {
            apply();
        }

        if (force) {
            apply();
        }

        function apply() {
            brandingApplied = true;
            //===== Color Theme
            const dataColorTheme: string = $$('body').data('theme');
            if (dataColorTheme && dataColorTheme !== getLocalAgencyInfo().branding.colortheme) {
                $$('body').removeClass('color-theme-' + dataColorTheme);
                $$('body').removeData('theme');
            }
            if (getLocalAgencyInfo().branding.colortheme) {
                $$('body').addClass('color-theme-' + getLocalAgencyInfo().branding.colortheme);
                $$('body').data('theme', getLocalAgencyInfo().branding.colortheme);
            }
            //==============
            //===== Menu Buttons Text Color
            const dataButtonsTextColor: string = $$('.menu_main_dataholder').data('buttons_textcolor');
            if (dataButtonsTextColor && dataButtonsTextColor !== getLocalAgencyInfo().branding.menubuttons_textcolor) {
                $$('.menu_button').forEach(function (el: Element) {
                    $$(el).removeClass('text-color-' + dataButtonsTextColor);
                });
                $$('.menu_main_dataholder').removeData('buttons_textcolor');
            }
            if (getLocalAgencyInfo().branding.menubuttons_textcolor) {
                $$('.menu_button').forEach(function (el: Element) {
                    $$(el).addClass('text-color-' + getLocalAgencyInfo().branding.menubuttons_textcolor);
                });
                $$('.menu_main_dataholder').data('buttons_textcolor', getLocalAgencyInfo().branding.menubuttons_textcolor);
            }
            //==============
            //===== Menu Buttons Background Color
            const dataButtonsBackgroundColor: string = $$('.menu_main_dataholder').data('buttons_backgroundcolor');
            if (dataButtonsBackgroundColor && dataButtonsBackgroundColor !== getLocalAgencyInfo().branding.menubuttons_backgroundcolor) {
                $$('.menu_button').forEach(function (el: Element) {
                    $$(el).removeClass('bg-color-' + dataButtonsBackgroundColor);
                });
                $$('.menu_main_dataholder').removeData('buttons_backgroundcolor');
            }
            if (getLocalAgencyInfo().branding.menubuttons_backgroundcolor) {
                $$('.menu_button').forEach(function (el: Element) {
                    $$(el).addClass('bg-color-' + getLocalAgencyInfo().branding.menubuttons_backgroundcolor);
                });
                $$('.menu_main_dataholder').data('buttons_backgroundcolor', getLocalAgencyInfo().branding.menubuttons_backgroundcolor);
            }
            //==============
            //===== Menu Logo
            //menu_main_branding_logo
            if (getLocalAgencyInfo().branding.menulogo) {
                getFileURLFromStorage(getLocalAgencyInfo().branding.menulogo).then(function (imageURL: string) {
                    if (getLocalAgencyInfo().branding.menulogo_position === 'top') {
                        $$('.menu_main_branding_logo_top').html('<img class="noselect" style="max-width: 240px; max-height: 240px;" src="' + imageURL + '" alt="Agency Logo">');
                        $$('.menu_main_branding_logo_bottom').html('');
                    } else {
                        $$('.menu_main_branding_logo_bottom').html('<img class="noselect" style="max-width: 240px; max-height: 240px;" src="' + imageURL + '" alt="Agency Logo">');
                        $$('.menu_main_branding_logo_top').html('');
                    }
                });
            }
            //==============
        }
    }
}