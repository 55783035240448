import { agencyDocument, signInLookupDocument } from './databaseinterfaces';
import * as database from './database';
import { getUserEmailOnline } from './user';
import { displayVerticalDialog, hideLoadPreloader, verticalDialogButton } from './ui';
import { shortenText } from './utils';
import { logErrorToServer } from './debug';



export let agencyInfo: agencyDocument;

export function getLocalAgencyInfo(): agencyDocument {
    return agencyInfo;
}

export function getAgencyID(): string {
    return agencyInfo.id;
}

export function safeGetAgencyInfo(): Promise<agencyDocument> {
    return new Promise(function (resolve, reject) {
        const localID: string = localStorage.getItem('agency_id');
        if (localID) {
            getAgencyInfo().then(function (agencyInfo: agencyDocument) {
                resolve(agencyInfo);
            });
        } else {
            getUserEmailOnline().then(function (userEmail: string) {
                database.get('/signinlookup/' + userEmail).then(function (userSigninLookup: signInLookupDocument) {
                    if (userSigninLookup.exists) {
                        if (Object.keys(userSigninLookup.agencies).length > 1) {
                            let dialogButtons: verticalDialogButton[] = [];
                            Object.keys(userSigninLookup.agencies).forEach(function (agencyID: string) {
                                dialogButtons.push({
                                    text: shortenText(userSigninLookup.agencies[agencyID], 25),
                                    onClick: function () {
                                        localStorage.setItem('agency_id', agencyID);
                                        getAgencyInfo().then(function (agencyInfo: agencyDocument) {
                                            resolve(agencyInfo);
                                        });
                                    }
                                });
                            });
                            hideLoadPreloader();
                            displayVerticalDialog({
                                title: 'Choose Agency',
                                buttons: dialogButtons,
                                text: 'Select an agency to load into:'
                            });
                        } else {
                            const useAgencyID: string = Object.keys(userSigninLookup.agencies)[0];
                            localStorage.setItem('agency_id', useAgencyID);
                            getAgencyInfo().then(function (agencyInfo: agencyDocument) {
                                resolve(agencyInfo);
                            });
                        }
                    } else {
                        reject('Signin lookup doesnt exist.');
                    }
                }).catch(function (error: any) {
                    logErrorToServer(error, 'safeGetAgencyInfo():1');
                    reject(error);
                });
            }).catch(function (error: any) {
                logErrorToServer(error, 'safeGetAgencyInfo():2');
                reject(error);
            });
        }
    });
}

export function getAgencyInfo(useCache: boolean = true): Promise<agencyDocument> {
    return new Promise(function (resolve, reject) {
        if (useCache && agencyInfo && agencyInfo.exists) {
            resolve(agencyInfo);
        } else {
            let agencyID: string = localStorage.getItem('agency_id');
            if (!agencyID) {
                reject('missing-agency-id');
                return;
            }
            database.get('/agencies/$agencyID$').then(function (fetchedAgencyInfo: agencyDocument) {
                agencyInfo = fetchedAgencyInfo;
                resolve(fetchedAgencyInfo);
            }).catch(function (error: any) {
                logErrorToServer(error, 'getAgencyInfo():1');
                reject(error);
            });
        }
    });
}