import { getAuth, onAuthStateChanged, sendPasswordResetEmail, reauthenticateWithCredential, User, AuthError, EmailAuthProvider, signOut, signInWithEmailAndPassword, signInWithCustomToken, EmailAuthCredential, updatePassword, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { displayErrorAlert, displayConfirmDialog, displayAlert, displayLoadPreloader, displayPopup, handleClick, getInputValue, hideLoadPreloader, closePopup } from './ui';
import { userDocument } from './databaseinterfaces';
import * as database from './database';
import { reloadPage } from './utils';
import * as analytics from './analytics';
import { getAppURL } from "./appurl";



export let userInfo: userDocument;
let signedIn: boolean = false;
let signedInChecked: boolean = false;



export function getUserInfo(useCache: boolean = true): Promise<userDocument> {
    return new Promise(function (resolve, reject) {
        if (useCache && userInfo && userInfo.exists) {
            resolve(userInfo);
        } else {
            if (getAuth().currentUser) {
                database.get('/agencies/$agencyID$/users/' + getUserID()).then(function (fetchedUserInfo: userDocument) {
                    userInfo = fetchedUserInfo;
                    resolve(fetchedUserInfo);
                }).catch(function (error: any) {
                    reject(error);
                });
            } else {
                reject('No auth.');
            }
        }
    });
}

export function waitForAuthenticationStateAndData(): Promise<void> {
    return new Promise(function (resolve, reject) {
        const unsubscribe = onAuthStateChanged(getAuth(), function (user: User) {
            unsubscribe();
            if (user) {
                getUserInfo().then(function () {
                    resolve();
                }).catch(function (error: any) {
                    reject(error);
                });
            } else {
                resolve();
            }
        }, function (error: AuthError) {
            reject(error);
        });
    });
}

export function userIsSignedIn(useCache: boolean = true): Promise<boolean> {
    return new Promise(function (resolve, reject) {
        if (useCache && signedInChecked) {
            resolve(signedIn);
        } else {
            const unsubscribe = onAuthStateChanged(getAuth(), function (user: User) {
                signedInChecked = true;
                unsubscribe();
                if (user) {
                    signedIn = true;
                    resolve(true);
                } else {
                    signedIn = false;
                    resolve(false);
                }
            }, function (error: AuthError) {
                signedIn = false;
                reject(error);
            });
        }
    });
}

export function getUserEmailOnline(): Promise<string> {
    return new Promise(function (resolve, reject) {
        const unsubscribe = onAuthStateChanged(getAuth(), function (user: User) {
            if (user) {
                unsubscribe();
                resolve(user.email);
            } else {
                reject('Not signed in.');
            }
        }, function (error: AuthError) {
            reject(error);
        });
    });
}

export function signInWithPassword(email: string, password: string): Promise<boolean> {
    return new Promise(function (resolve, reject) {
        signInWithEmailAndPassword(getAuth(), email, password).then(function () {
            localStorage.setItem('user_email', email);
            resolve(false); //Two factor NOT required
        }).catch(function (error: any) {
            if (error.code === 'auth/multi-factor-auth-required') {
                resolve(true); //Two factor required
            } else {
                reject(error.code);
            }
        });
    });
}



export function signInWithToken(token: string): Promise<void> {
    return new Promise(function (resolve, reject) {
        signInWithCustomToken(getAuth(), token).then(function (userCredential: any) {
            localStorage.setItem('user_email', userCredential.user.email);
            resolve(); //Two factor NOT required
        }).catch(function (error: any) {
            reject(error.code);
        });
    });
}

export function handleSignInError(errorCode: string) {
    switch (errorCode) {
        case 'auth/invalid-tenant-id':
            displayErrorAlert('Invalid tenant ID.');
            break;
        case 'auth/invalid-email':
            displayErrorAlert('An invalid email or password was entered. Please make sure the entered email and password are correct.');
            break;
        case 'auth/user-disabled':
            displayErrorAlert('This account is disabled. Please contact support.');
            break;
        case 'auth/user-not-found':
            displayErrorAlert('This user could not be found.');
            break;
        case 'auth/wrong-password':
            displayErrorAlert('An invalid email or password was entered. Please make sure the entered email and password are correct.');
            break;
        case 'auth/argument-error':
            displayErrorAlert('There was an argument error signing in.');
            break;
        case 'auth/multi-factor-auth-required':
            //!TODO
            //startSignInTwoFactor('password', GlobalVars.signInEnteredEmail, error.resolver);
            break;
        case 'auth/wrong-password':
            displayErrorAlert('Invalid password.');
            break;
        default:
            displayErrorAlert('There was an error attempting to sign in.');
    }
}

/**
 * Returns the current user ID.
 * @returns {string} The user ID of the current user.
 */
export function getUserID(): string {
    return getAuth().currentUser.uid;
}

/**
 * Returns the full name of the user.
 * @returns {string} The full name of the user (format 'firstname lastname').
 */
export function getUserFullName(): string {
    return userInfo.firstname + ' ' + userInfo.lastname;
}

export function getUserEmail(): string {
    return userInfo.email;
}

export function getUserRole(): string {
    return userInfo.role;
}

export function userIsAdmin(): boolean {
    return userInfo.role === 'admin';
}

export function getUserLocationID(): string {
    return userInfo.location_id;
}

export function getUserLocationName(): string {
    return userInfo.location_name;
}

export function getUserPermissions(): userDocument['permissions'] {
    if (userInfo.permissions) {
        return userInfo.permissions;
    } else {
        return {};
    }
}

export function signOutUser(silent = false) {
    if (silent) {
        performSignout();
    } else {
        displayConfirmDialog({
            message: 'Are you sure you want to sign out?', title: 'Confirm Sign Out', confirmCallback: function () {
                analytics.logAnalyticsEvent('signout');
                performSignout();
            }
        })
    }
    function performSignout() {
        signOut(getAuth()).then(function () {
            displayLoadPreloader('Signed out. Reloading page...');
            reloadPage();
        }).catch(function (error: any) {
            displayErrorAlert('There was an error attempting to sign out.');
        });
    }
}

export function updateUserPassword(newPassword: string): Promise<void> {
    return new Promise(function (resolve, reject) {
        updatePassword(getAuth().currentUser, newPassword).then(function () {
            resolve();
        }).catch(function (error: any) {
            reject(error);
        });
    });
}

export function reauthenticateUser(): Promise<void> {
    return new Promise(async function (resolve, reject) {
        displayLoadPreloader();
        const popupSource = await import('../popups/user_reauthenticate.html');
        displayPopup(popupSource, true, undefined, function () {
            hideLoadPreloader();
            handleClick('.popup_user_reauthenticate_button_next', function () {
                reauthenticateUserNext().then(function () {
                    closePopup('.popup-user_reauthenticate');
                    resolve();
                }).catch(function () {
                    reject();
                });
            });
        });
    });
}

function reauthenticateUserNext(): Promise<void> {
    return new Promise(function (resolve, reject) {
        if (!getInputValue('input[name=user_reauthenticate_password]')) {
            displayAlert('Please enter your password to continue.');
            reject();
            return;
        }
        let userCredential: EmailAuthCredential = EmailAuthProvider.credential(getUserEmail(), getInputValue('input[name=user_reauthenticate_password]'));
        reauthenticateWithCredential(getAuth().currentUser, userCredential).then(function () {
            resolve();
        }).catch(function () {
            displayErrorAlert('There was an error attempting to reauthenticate.');
            reject();
        });
    });
}

export function sendPasswordReset(email: string): Promise<void> {
    return new Promise(function (resolve, reject) {
        sendPasswordResetEmail(getAuth(), email).then(function () {
            resolve();
        }).catch(function (error: any) {
            reject(error);
        });
    });
}

export function sendSignInEmail(email: string): Promise<void> {
    return new Promise(function (resolve, reject) {
        const actionCodeSettings = {
            url: getAppURL() + '/signin',
            // This must be true.
            handleCodeInApp: true
        };
        sendSignInLinkToEmail(getAuth(), email, actionCodeSettings).then(function () {
            localStorage.setItem('signinemail_email', email);
            resolve();
        }).catch(function (error: any) {
            reject(error);
        });
    });
}

export function signInIsFromEmailLink(): boolean {
    if (isSignInWithEmailLink(getAuth(), window.location.href)) {
        return true;
    } else {
        return false;
    }
}

export function signInWithSentEmailLink(email: string, url: string): Promise<void> {
    return new Promise(function (resolve, reject) {
        signInWithEmailLink(getAuth(), email, url).then((result) => {
            localStorage.removeItem('signinemail_email');
            resolve();
        }).catch(function (error: any) {
            reject(error);
        });
    });
}