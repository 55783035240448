import { getFileExtension } from "./utils";

/**
 * Validates if an email is a valid formatted email or not.
 * @param email - The email to validate.
 * @returns {boolean} True if valid.
 */
export function validateEmail(email: string): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

//From https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
export function validateURL(url: string): boolean {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(url);
}


//Validates 10 digits, allows dashes (-) or periods (.) to separate number groups.
//Does not support country codes (+1) or parenthesis.
export function validatePhoneNumber(phoneNumber: string): boolean {
    return /^[0-9]{3}([-.])?[0-9]{3}([-.])?[0-9]{4}$/.test(phoneNumber);
}

/**
 * Validates if a file's size is less than a specified limit.
 * @param {any} file - The file object of the file to validate.
 * @param {number} maxsize - The maximum file size of the file in KB.
 * @returns {boolean} Returns true if the file size is within the specified limit.
 */
export function validateFileSize(file: any, maxsize: number = 20000): boolean {
    let FileSize = file.size / 1024; // in KB
    return FileSize < maxsize;
}

/**
 * Validates if a file is a specified/specific type.
 * @param {any} lfile - The file object to validate.
 * @param {string} fileext - The file extension to validate the file against. Do not include a period, just the extension (e.g. "pdf").
 * @returns {boolean} True if the file is of the specified type.
 */
export function validateIfCertainFileType(lfile: any, fileext: string): boolean {
    let ret = false;
    let ext: string;
    if (typeof lfile === 'string') {
        ext = getFileExtension(lfile);
    } else {
        ext = getFileExtension(lfile.name);
    }
    if (ext === fileext) {
        ret = true
    }
    return ret;
}