import { getFirestore, collection, doc, runTransaction as firebaseRunTransaction, onSnapshot as firebaseOnSnapshot, waitForPendingWrites, query, deleteDoc, where, orderBy, limit, getDoc, getDocs, addDoc, updateDoc, setDoc, serverTimestamp, increment, deleteField, DocumentSnapshot, QuerySnapshot } from "firebase/firestore";
import { isOdd } from './utils';
import { hideLoadPreloader } from './ui';
import { logErrorToServer } from "./debug";



//#region Interfaces

interface databaseQueryOrderBy {
    readonly index: string;
    readonly orderBy?: string;
}

interface databaseQueryWhere {
    readonly index: string;
    readonly operator: '<' | '<=' | '==' | '>' | '>=' | '!=' | 'array-contains' | 'array-contains-any' | 'in' | 'not-in';
    readonly where: string | boolean | number | Date | Array<string>;
}

export interface databaseQueryOptions {
    readonly queryOrderBy?: databaseQueryOrderBy;
    readonly queryWhere?: databaseQueryWhere | databaseQueryWhere[];
    readonly queryLimit?: number;
}




export function SERVERTIMESTAMP() {
    return serverTimestamp();
}

export function SERVERINCREMENT(incrementBy: number) {
    //@ts-ignore
    return increment(incrementBy);
}

export function SERVERDELETE() {
    //@ts-ignore
    return deleteField();
}

//#endregion

/**
 * Parses a database query path for specific keys and replaces them (e.g. $getUrlCourseID$ with the course id.)
 * @param queryPath Database query path to parse.
 * @returns {string} The parsed query path.
 */
function parseDatabaseQuery(queryPath: string): string {
    let parsedPath: string = queryPath;
    if (parsedPath.includes('$agencyID$')) {
        parsedPath = parsedPath.split('$agencyID$').join(localStorage.getItem('agency_id'));
    }
    return parsedPath;
}

/**
 * Wrapper for firestore get().
 * @param queryPath The path of the document or collection separated by forward slashes. Starts with base / (e.g. /users/USERID).
 * @param queryOptions Options object that allows you to provide orderBy statement, Where statement and limit statement.
 * @returns {any} Array if a collection, or object if document.
 */
export async function get(queryPath: string, queryOptions?: databaseQueryOptions): Promise<object> {
    return new Promise(function (resolve, reject) {
        const firestore = getFirestore();
        let parsedQuery = parseDatabaseQuery(queryPath); //Parse the query path first

        let splitQuery: any = parsedQuery.split('/');
        let isCollection = isOdd(splitQuery.length - 1); //If there are an odd number of slashes "/", the query is for a collection. If it's even then it's a document.
        let firebaseRef: any;
        let firebaseQuery: any;

        if (isCollection) {
            firebaseRef = collection(firestore, parsedQuery);
        } else {
            firebaseRef = doc(firestore, parsedQuery);
        }

        if (queryOptions) {

            let allQueryOptions: any[] = [];

            if (queryOptions.queryWhere) {
                if (Array.isArray(queryOptions.queryWhere)) {
                    let parsedArray: any[] = [];
                    for (let i in queryOptions.queryWhere) {
                        //@ts-ignore
                        allQueryOptions.push(where(queryOptions.queryWhere[i].index, queryOptions.queryWhere[i].operator, queryOptions.queryWhere[i].where));
                    }
                } else {
                    //@ts-ignore
                    allQueryOptions.push(where(queryOptions.queryWhere.index, queryOptions.queryWhere.operator, queryOptions.queryWhere.where));
                }
            }

            if (queryOptions.queryOrderBy) {
                //@ts-ignore
                allQueryOptions.push(queryOptions.queryOrderBy.orderBy ? orderBy(queryOptions.queryOrderBy.index, queryOptions.queryOrderBy.orderBy) : orderBy(queryOptions.queryOrderBy.index));
            }

            if (queryOptions.queryLimit) {
                allQueryOptions.push(limit(queryOptions.queryLimit));
            }

            firebaseQuery = query(firebaseRef, ...allQueryOptions);

        } else {
            firebaseQuery = query(firebaseRef);
        }

        if (isCollection) {
            getDocs(firebaseQuery).then(function (result: any) {
                finish(result);
            }).catch(function (error: any) {
                logErrorToServer(error, 'database:get():1');
                hideLoadPreloader(); //Close load preloader if error so it doesn't stay stuck open
                reject(error);
            });
        } else {
            getDoc(firebaseQuery).then(function (result: any) {
                finish(result);
            }).catch(function (error: any) {
                logErrorToServer(error, 'database:get():2');
                hideLoadPreloader(); //Close load preloader if error so it doesn't stay stuck open
                reject(error);
            });
        }

        function finish(result: any) {
            if (isCollection) {
                //The query is a collection, parse the result and return it
                let resultArray: any = [];
                result.forEach(function (resultItem: any) {
                    let resultArrayItem: any = {};
                    resultArrayItem = resultItem.data();
                    resultArrayItem.id = resultItem.id;
                    resultArrayItem.path = resultItem.ref.path;
                    resultArray.push(resultArrayItem);
                });
                resolve(resultArray);
            } else {
                //The query is for a document, parse the result and return it
                let resultObject: any = {};
                if (result.exists()) {
                    resultObject = result.data();
                }
                if (!resultObject) {
                    resultObject = {
                        exists: result.exists(),
                        id: result.id
                    }
                    resolve(resultObject);
                } else {
                    resultObject.exists = result.exists();
                    resultObject.id = result.id;
                    resultObject.path = result.ref.path;
                    resolve(resultObject);
                }
            }
        }
    });
}

/**
 * Wrapper for firestore update().
 * @param queryPath The path of the document or collection separated by forward slashes. Starts with base / (e.g. /users/USERID).
 * @param queryBody Object of document body that will be set as the new values.
 * @returns {any} Resolves once completed.
 */
export async function update(queryPath: string, queryBody: any): Promise<void> {
    return new Promise(function (resolve, reject) {
        const firestore = getFirestore();

        let parsedQuery = parseDatabaseQuery(queryPath); //Parse the query path first

        let splitQuery: any = parsedQuery.split('/');
        let isCollection = isOdd(splitQuery.length - 1); //If there are an odd number of slashes "/", the query is for a collection. If it's even then it's a document.
        let firebaseRef: any;

        if (isCollection) {
            reject('Query must be for a document.');
        } else {
            firebaseRef = doc(firestore, parsedQuery);
        }

        //Perform the query
        updateDoc(firebaseRef, queryBody).then(function () {
            resolve();
        }).catch(function (error: any) {
            logErrorToServer(error, 'database:update():1');
            hideLoadPreloader(); //Close load preloader if error so it doesn't stay stuck open
            reject(error);
        });
    });
}

/**
 * Wrapper for firestore set().
 * @param queryPath The path of the document or collection separated by forward slashes. Starts with base / (e.g. /users/USERID).
 * @param queryBody Object of document body that will be set as the new values.
 * @returns {any} Resolves once completed.
 */
export async function set(queryPath: string, queryBody: any, merge = false): Promise<void> {
    return new Promise(function (resolve, reject) {
        const firestore = getFirestore();

        let parsedQuery = parseDatabaseQuery(queryPath); //Parse the query path first

        let splitQuery: any = parsedQuery.split('/');
        let isCollection = isOdd(splitQuery.length - 1); //If there are an odd number of slashes "/", the query is for a collection. If it's even then it's a document.
        let firebaseRef: any; //The firebase object that will hold the actual firestore object

        if (isCollection) {
            reject('Query must be for a document.');
        } else {
            firebaseRef = doc(firestore, parsedQuery);
        }

        //Perform the query
        setDoc(firebaseRef, queryBody, { merge: merge }).then(function () {
            resolve();
        }).catch(function (error: any) {
            logErrorToServer(error, 'database:set():1');
            hideLoadPreloader(); //Close load preloader if error so it doesn't stay stuck open
            reject(error);
        });
    });
}

/**
 * Wrapper for firestore add().
 * @param queryPath The path of the document or collection separated by forward slashes. Starts with base / (e.g. /users/USERID).
 * @param queryBody Object of document body that will be set as the new values.
 * @returns {any} Resolves with the newly added documents ID.
 */
export async function add(queryPath: string, queryBody: any): Promise<string> {
    return new Promise(function (resolve, reject) {
        const firestore = getFirestore();

        let parsedQuery = parseDatabaseQuery(queryPath); //Parse the query path first

        let splitQuery: any = parsedQuery.split('/');
        let isCollection = isOdd(splitQuery.length - 1); //If there are an odd number of slashes "/", the query is for a collection. If it's even then it's a document.
        let firebaseRef: any; //The firebase object that will hold the actual firestore object

        if (isCollection) {
            firebaseRef = collection(firestore, parsedQuery);
        } else {
            reject('Query must be for a collection.');
        }

        //Perform the query
        addDoc(firebaseRef, queryBody).then(function (newDocument: any) {
            resolve(newDocument.id);
        }).catch(function (error: any) {
            logErrorToServer(error, 'database:add():1');
            hideLoadPreloader(); //Close load preloader if error so it doesn't stay stuck open
            reject(error);
        });
    });
}

/**
 * Wrapper for firestore delete().
 * @param queryPath The path of the document or collection separated by forward slashes. Starts with base / (e.g. /users/USERID).
 * @returns {any} Resolves when the document is deleted.
 */
export async function remove(queryPath: string): Promise<void> {
    return new Promise(function (resolve, reject) {
        const firestore = getFirestore();

        let parsedQuery = parseDatabaseQuery(queryPath); //Parse the query path first
        let splitQuery: any = parsedQuery.split('/');
        let isCollection = isOdd(splitQuery.length - 1); //If there are an odd number of slashes "/", the query is for a collection. If it's even then it's a document.
        let firebaseRef: any; //The firebase object that will hold the actual firestore object

        if (isCollection) {
            reject('Query must be for a document.');
        } else {
            firebaseRef = doc(firestore, parsedQuery);
        }

        //Perform the query
        deleteDoc(firebaseRef).then(function () {
            resolve();
        }).catch(function (error: any) {
            logErrorToServer(error, 'database:remove():1');
            hideLoadPreloader(); //Close load preloader if error so it doesn't stay stuck open
            reject(error);
        });
    });
}

export async function databaseWaitForPendingWrites(): Promise<void> {
    return new Promise(function (resolve, reject) {
        waitForPendingWrites(getFirestore()).then(function () {
            resolve();
        });
    });
}

/**
 * Wrapper for firestore onSnapshot().
 * @param queryPath The path of the document or collection separated by forward slashes. Starts with base / (e.g. /users/USERID).
 * @returns {any} Resolves when the document or collection is updated.
 */
export async function onSnapshot(queryPath: string, snapshotCallback: Function, queryOptions?: databaseQueryOptions): Promise<any> {
    return new Promise(function (resolve, reject) {
        const firestore = getFirestore();

        let parsedQuery = parseDatabaseQuery(queryPath); //Parse the query path first
        let splitQuery: any = parsedQuery.split('/');
        let isCollection = isOdd(splitQuery.length - 1); //If there are an odd number of slashes "/", the query is for a collection. If it's even then it's a document.
        let firebaseRef: any;
        let firebaseQuery: any;

        if (isCollection) {
            firebaseRef = collection(firestore, parsedQuery);
        } else {
            firebaseRef = doc(firestore, parsedQuery);
        }

        let firstRun = true;

        if (queryOptions) {

            let allQueryOptions: any[] = [];

            if (queryOptions.queryWhere) {
                if (Array.isArray(queryOptions.queryWhere)) {
                    for (let i in queryOptions.queryWhere) {
                        //@ts-ignore
                        allQueryOptions.push(where(queryOptions.queryWhere[i].index, queryOptions.queryWhere[i].operator, queryOptions.queryWhere[i].where));
                    }
                } else {
                    //@ts-ignore
                    allQueryOptions.push(where(queryOptions.queryWhere.index, queryOptions.queryWhere.operator, queryOptions.queryWhere.where));
                }
            }

            if (queryOptions.queryOrderBy) {
                //@ts-ignore
                allQueryOptions.push(queryOptions.queryOrderBy.orderBy ? orderBy(queryOptions.queryOrderBy.index, queryOptions.queryOrderBy.orderBy) : orderBy(queryOptions.queryOrderBy.index));
            }

            if (queryOptions.queryLimit) {
                allQueryOptions.push(limit(queryOptions.queryLimit));
            }

            firebaseQuery = query(firebaseRef, ...allQueryOptions);

        } else {
            firebaseQuery = query(firebaseRef);
        }
        let unsubscribeHandler = firebaseOnSnapshot(firebaseQuery, function (snapshot: QuerySnapshot | DocumentSnapshot) {
            if (firstRun) {
                firstRun = false;
                return;
            }
            if (isCollection && 'forEach' in snapshot) {
                //The query is a collection, parse the result and return it
                let resultArray: any = [];

                snapshot.forEach(function (resultItem: any) {
                    let resultArrayItem: any = {};
                    resultArrayItem = resultItem.data({ serverTimestamps: 'estimate' });
                    resultArrayItem.id = resultItem.id;
                    resultArray.push(resultArrayItem);
                });

                if (!snapshot.metadata.hasPendingWrites) { //Only listen for updates from server
                    snapshotCallback(resultArray);
                }
            } else if ('exists' in snapshot) {
                //The query is for a document, parse the result and return it
                let resultObject: any = {};

                if (snapshot.exists()) {
                    resultObject = snapshot.data({ serverTimestamps: 'estimate' });
                }

                if (resultObject) {
                    resultObject.exists = (snapshot.exists() ? snapshot.exists() : false);
                    resultObject.id = snapshot.id;
                }
                
                if (!snapshot.metadata.hasPendingWrites) { //Only listen for updates from server
                    snapshotCallback(resultObject);
                }
            }
        }, function (error: any) {
            hideLoadPreloader(); //Close load preloader if error so it doesn't stay stuck open
            reject(error);
        });

        resolve(unsubscribeHandler);
    });
}

//#region Database Batch
//#endregion

type transactionMergeHandler = (serverDocument: any, localDocument: any) => any;


/**
 * Wrapper for firestore runTransaction().
 * @param queryPath The path of the document or collection separated by forward slashes. Starts with base / (e.g. /users/USERID).
 * @param queryBody Object of document body that will be set as the new values.
 * @returns {any} Resolves once completed.
 */
export async function runTransaction(queryPath: string, queryBody: any, mergeHandler: transactionMergeHandler): Promise<object> {
    return new Promise(function (resolve, reject) {
        const firestore = getFirestore();

        let parsedQuery = parseDatabaseQuery(queryPath); //Parse the query path first

        let splitQuery: any = parsedQuery.split('/');
        let isCollection = isOdd(splitQuery.length - 1); //If there are an odd number of slashes "/", the query is for a collection. If it's even then it's a document.
        let firebasRef: any; //The firebase object that will hold the actual firestore object

        if (isCollection) {
            reject('Query must be for a document.');
        } else {
            firebasRef = doc(firestore, parsedQuery);
        }

        firebaseRunTransaction(firestore, function (transaction: any) {
            // This code may get re-run multiple times if there are conflicts.
            return transaction.get(firebasRef).then(function (serverQueryDocument: any) {
                if (!serverQueryDocument.exists()) {
                    reject('Document does not exist.');
                    return;
                }
                // === Format server document like we do documents for our other requests
                // Document must exist, which we handle above
                let resultServerObject: any = {};
                resultServerObject = serverQueryDocument.data();
                resultServerObject.exists = serverQueryDocument.exists();
                resultServerObject.id = serverQueryDocument.id;
                resultServerObject.path = serverQueryDocument.ref.path;
                // ====
                let mergedDocument: any = mergeHandler(resultServerObject, queryBody);
                transaction.update(firebasRef, mergedDocument);
            });
        }).then(function (mergedDocument: any) {
            resolve(mergedDocument);
        }).catch(function (error: any) {
            logErrorToServer(error, 'database:runTransaction():1');
            hideLoadPreloader(); //Close load preloader if error so it doesn't stay stuck open
            reject(error);
        });
    });
}