import { mainView } from './libs/framework7';
import * as routerHelper from './routerhelper';
import * as menu from './menu';



export const routes: any[] = [
    {
        name: 'home',
        path: '/',
        pageSource: import('../pages/home.html'),
        pageHandler: import('./pages/home'),
        async: routerLoadPage,
        beforeEnter: routerHelper.checkIfSignedIn,
        menu: '/menu_main',
        menuButton: 'menu_button_home',
        beforeLeave: routerHelper.defaultHandleBeforeLeave
    },
    {
        name: 'signin',
        path: '/signin',
        pageSource: import('../pages/signin.html'),
        pageHandler: import('./pages/signin'),
        async: routerLoadPage,
        beforeEnter: routerHelper.cannotBeSignedIn,
        hideMenu: true
    },
    {
        name: 'invite',
        path: '/invite/:inviteID',
        async: function routerLoadPage({ app, to, resolve, reject }) {
            reject();
            mainView.router.navigate('/signin?invite=' + to.params.inviteID);
        },
        beforeEnter: routerHelper.cannotBeSignedIn,
        hideMenu: true
    },
    {
        name: 'accessform',
        path: '/accessform/:agencyID/:formID',
        async: function routerLoadPage({ app, to, resolve, reject }) {
            reject();
            routerHelper.handleFormAccess(to.params.agencyID, to.params.formID);
        },
        hideMenu: true
    },
    {
        name: 'about',
        path: '/about',
        pageSource: import('../pages/about.html'),
        pageHandler: import('./pages/about'),
        async: routerLoadPage,
        beforeEnter: routerHelper.checkIfSignedIn,
        menu: '/menu_main',
        menuButton: 'menu_button_settings',
        beforeLeave: routerHelper.defaultHandleBeforeLeave
    },
    {
        name: 'smartrigalert',
        path: '/smartrigalert',
        pageSource: import('../pages/smartrigalert/calls.html'),
        pageHandler: import('./pages/smartrigalert/calls'),
        async: routerLoadPage,
        beforeEnter: routerHelper.checkIfSignedIn,
        menu: '/menu_main',
        menuButton: 'menu_button_smartrigalert',
        beforeLeave: routerHelper.defaultHandleBeforeLeave,
        routes: [
            {
                name: 'smartrigalert_call',
                path: 'call/:callID',
                pageSource: import('../pages/smartrigalert/call.html'),
                pageHandler: import('./pages/smartrigalert/call'),
                pageBeforeRemoveHandler: import('./pages/smartrigalert/call'),
                async: routerLoadPage,
                beforeEnter: routerHelper.checkIfSignedIn,
                menu: '/menu_main',
                menuButton: 'menu_button_smartrigalert',
                beforeLeave: routerHelper.defaultHandleBeforeLeave
            }
        ]
    },
    {
        name: 'settings',
        path: '/settings',
        pageSource: import('../pages/settings/settings.html'),
        pageHandler: import('./pages/settings/settings'),
        async: routerLoadPage,
        beforeEnter: routerHelper.checkIfSignedIn,
        menu: '/menu_main',
        menuButton: 'menu_button_settings',
        beforeLeave: routerHelper.defaultHandleBeforeLeave,
        routes: [
            {
                name: 'settings_signature',
                path: 'signature',
                pageSource: import('../pages/settings/signature.html'),
                pageHandler: import('./pages/settings/signature'),
                async: routerLoadPage,
                beforeEnter: routerHelper.checkIfSignedIn,
                menu: '/menu_main',
                menuButton: 'menu_button_settings',
                beforeLeave: routerHelper.defaultHandleBeforeLeave
            },
            {
                name: 'settings_account',
                path: 'account',
                pageSource: import('../pages/settings/account.html'),
                pageHandler: import('./pages/settings/account'),
                async: routerLoadPage,
                beforeEnter: routerHelper.checkIfSignedIn,
                menu: '/menu_main',
                menuButton: 'menu_button_settings',
                beforeLeave: routerHelper.defaultHandleBeforeLeave
            },
            {
                name: 'settings_phonenumber',
                path: 'phonenumber',
                pageSource: import('../pages/settings/phonenumber.html'),
                pageHandler: import('./pages/settings/phonenumber'),
                async: routerLoadPage,
                beforeEnter: routerHelper.checkIfSignedIn,
                menu: '/menu_main',
                menuButton: 'menu_button_settings',
                beforeLeave: routerHelper.defaultHandleBeforeLeave
            },
            {
                name: 'security',
                path: 'security',
                routes: [
                    {
                        name: 'settings_security_signinhistory',
                        path: 'signinhistory',
                        pageSource: import('../pages/settings/security/signinhistory.html'),
                        pageHandler: import('./pages/settings/security/signinhistory'),
                        async: routerLoadPage,
                        beforeEnter: routerHelper.checkIfSignedIn,
                        menu: '/menu_main',
                        menuButton: 'menu_button_settings',
                        beforeLeave: routerHelper.defaultHandleBeforeLeave
                    },
                    {
                        name: 'settings_security_email',
                        path: 'email',
                        pageSource: import('../pages/settings/security/email.html'),
                        pageHandler: import('./pages/settings/security/email'),
                        async: routerLoadPage,
                        beforeEnter: routerHelper.checkIfSignedIn,
                        menu: '/menu_main',
                        menuButton: 'menu_button_settings',
                        beforeLeave: routerHelper.defaultHandleBeforeLeave
                    },
                    {
                        name: 'settings_security_actions',
                        path: 'actions',
                        pageSource: import('../pages/settings/security/actions.html'),
                        pageHandler: import('./pages/settings/security/actions'),
                        async: routerLoadPage,
                        beforeEnter: routerHelper.checkIfSignedIn,
                        menu: '/menu_main',
                        menuButton: 'menu_button_settings',
                        beforeLeave: routerHelper.defaultHandleBeforeLeave
                    },
                    {
                        name: 'settings_security_changepassword',
                        path: 'changepassword',
                        pageSource: import('../pages/settings/security/changepassword.html'),
                        pageHandler: import('./pages/settings/security/changepassword'),
                        async: routerLoadPage,
                        beforeEnter: routerHelper.checkIfSignedIn,
                        menu: '/menu_main',
                        menuButton: 'menu_button_settings',
                        beforeLeave: routerHelper.defaultHandleBeforeLeave
                    }
                ]
            }
        ],
    },
    {
        name: 'checklist',
        path: '/checklist',
        routes: [
            {
                name: 'checklist_submitted',
                path: 'submitted',
                pageSource: import('../pages/checklist/submitted.html'),
                pageHandler: import('./pages/checklist/submitted'),
                async: routerLoadPage,
                beforeEnter: routerHelper.checkIfSignedIn,
                menu: '/menu_main',
                menuButton: 'menu_button_checklist_submitted',
                beforeLeave: routerHelper.defaultHandleBeforeLeave,
                routes: [
                    {
                        name: 'checklist_view',
                        path: ':checklistID',
                        pageSource: import('../pages/checklist/view.html'),
                        pageHandler: import('./pages/checklist/view'),
                        async: routerLoadPage,
                        beforeEnter: routerHelper.checkIfSignedIn,
                        menu: '/menu_main',
                        menuButton: 'menu_button_checklist_submitted',
                        beforeLeave: routerHelper.defaultHandleBeforeLeave
                    }
                ]
            },
            {
                name: 'checklist_run',
                path: 'run/:checklistID',
                pageSource: import('../pages/checklist/run.html'),
                pageHandler: import('./pages/checklist/run'),
                async: routerLoadPage,
                beforeEnter: routerHelper.checkIfSignedIn,
                menu: '/menu_main',
                menuButton: 'menu_button_home',
                beforeLeave: routerHelper.defaultHandleBeforeLeave
            }
        ]
    },
    {
        name: 'shifts',
        path: '/shifts',
        pageSource: import('../pages/shifts/shifts.html'),
        pageHandler: import('./pages/shifts/shifts'),
        async: routerLoadPage,
        beforeEnter: routerHelper.checkIfSignedIn,
        menu: '/menu_main',
        menuButton: 'menu_button_shifts',
        beforeLeave: routerHelper.defaultHandleBeforeLeave
    },
    {
        name: 'locationnotes',
        path: '/locationnotes',
        pageSource: import('../pages/locationnotes.html'),
        pageHandler: import('./pages/locationnotes'),
        async: routerLoadPage,
        beforeEnter: routerHelper.checkIfSignedIn,
        menu: '/menu_main',
        menuButton: 'menu_button_locationnotes',
        beforeLeave: routerHelper.defaultHandleBeforeLeave
    },
    {
        name: 'calendar',
        path: '/calendar',
        pageSource: import('../pages/calendar/calendar.html'),
        pageHandler: import('./pages/calendar/calendar'),
        async: routerLoadPage,
        beforeEnter: routerHelper.checkIfSignedIn,
        menu: '/menu_main',
        menuButton: 'menu_button_calendar',
        beforeLeave: routerHelper.defaultHandleBeforeLeave
    },
    {
        name: 'members',
        path: '/members',
        pageSource: import('../pages/members/members.html'),
        pageHandler: import('./pages/members/members'),
        async: routerLoadPage,
        beforeEnter: routerHelper.checkIfSignedIn,
        menu: '/menu_main',
        menuButton: 'menu_button_members',
        beforeLeave: routerHelper.defaultHandleBeforeLeave
    },
    {
        name: 'form_run',
        path: '/form/run/:agencyID/:checklistID',
        pageSource: import('../pages/checklist/run.html'),
        pageHandler: import('./pages/checklist/run'),
        async: routerLoadPage,
        menu: '/menu_main',
        menuButton: 'menu_button_home',
        beforeLeave: routerHelper.defaultHandleBeforeLeave
    },
    {
        name: 'smartrigtrack',
        path: '/smartrigtrack',
        pageSource: import('../pages/smartrigtrack.html'),
        pageHandler: import('./pages/smartrigtrack'),
        async: routerLoadPage,
        menu: '/menu_main',
        menuButton: 'menu_button_smartrigtrack',
        beforeLeave: routerHelper.defaultHandleBeforeLeave
    },
    {
        name: 'admin',
        path: '/admin',
        pageSource: import('../pages/admin/home.html'),
        pageHandler: import('./pages/admin/home'),
        async: routerLoadPage,
        beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
        menu: '/menu_main',
        menuButton: 'menu_button_admin',
        beforeLeave: routerHelper.defaultHandleBeforeLeave,
        routes: [
            {
                name: 'admin_shifts',
                path: 'shifts',
                pageSource: import('../pages/admin/shifts.html'),
                pageHandler: import('./pages/admin/shifts'),
                async: routerLoadPage,
                beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                menu: '/menu_main',
                menuButton: 'menu_button_admin',
                beforeLeave: routerHelper.defaultHandleBeforeLeave
            },
            {
                name: 'admin_agencyinfo',
                path: 'agencyinfo',
                pageSource: import('../pages/admin/agencyinfo.html'),
                pageHandler: import('./pages/admin/agencyinfo'),
                async: routerLoadPage,
                beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                menu: '/menu_main',
                menuButton: 'menu_button_admin',
                beforeLeave: routerHelper.defaultHandleBeforeLeave
            },
            {
                name: 'admin_smartrigalert',
                path: 'smartrigalert',
                pageSource: import('../pages/admin/smartrigalert.html'),
                pageHandler: import('./pages/admin/smartrigalert'),
                async: routerLoadPage,
                beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                menu: '/menu_main',
                menuButton: 'menu_button_admin',
                beforeLeave: routerHelper.defaultHandleBeforeLeave
            },
            {
                name: 'admin_hidefeatures',
                path: 'hidefeatures',
                pageSource: import('../pages/admin/hidefeatures.html'),
                pageHandler: import('./pages/admin/hidefeatures'),
                async: routerLoadPage,
                beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                menu: '/menu_main',
                menuButton: 'menu_button_admin',
                beforeLeave: routerHelper.defaultHandleBeforeLeave
            },
            {
                name: 'admin_locations',
                path: 'locations',
                pageSource: import('../pages/admin/locations.html'),
                pageHandler: import('./pages/admin/locations'),
                async: routerLoadPage,
                beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                menu: '/menu_main',
                menuButton: 'menu_button_admin',
                beforeLeave: routerHelper.defaultHandleBeforeLeave
            },
            {
                name: 'admin_smartrigtrack',
                path: 'smartrigtrack',
                pageSource: import('../pages/admin/smartrigtrack.html'),
                pageHandler: import('./pages/admin/smartrigtrack'),
                async: routerLoadPage,
                beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                menu: '/menu_main',
                menuButton: 'menu_button_admin',
                beforeLeave: routerHelper.defaultHandleBeforeLeave
            },
            {
                name: 'admin_apparatus',
                path: 'apparatus',
                pageSource: import('../pages/admin/apparatus.html'),
                pageHandler: import('./pages/admin/apparatus'),
                async: routerLoadPage,
                beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                menu: '/menu_main',
                menuButton: 'menu_button_admin',
                beforeLeave: routerHelper.defaultHandleBeforeLeave
            },
            {
                name: 'admin_complianceframeworks',
                path: 'complianceframeworks',
                pageSource: import('../pages/admin/complianceframeworks.html'),
                pageHandler: import('./pages/admin/complianceframeworks'),
                async: routerLoadPage,
                beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                menu: '/menu_main',
                menuButton: 'menu_button_admin',
                beforeLeave: routerHelper.defaultHandleBeforeLeave
            },
            {
                name: 'admin_statistics',
                path: 'statistics',
                pageSource: import('../pages/admin/statistics.html'),
                pageHandler: import('./pages/admin/statistics'),
                async: routerLoadPage,
                beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                menu: '/menu_main',
                menuButton: 'menu_button_admin',
                beforeLeave: routerHelper.defaultHandleBeforeLeave
            },
            {
                name: 'admin_losap',
                path: 'losap',
                pageSource: import('../pages/admin/losap.html'),
                pageHandler: import('./pages/admin/losap'),
                async: routerLoadPage,
                beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                menu: '/menu_main',
                menuButton: 'menu_button_admin',
                beforeLeave: routerHelper.defaultHandleBeforeLeave
            },
            {
                name: 'admin_apikeys',
                path: 'apikeys',
                pageSource: import('../pages/admin/apikeys.html'),
                async: routerLoadPage,
                beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                menu: '/menu_main',
                menuButton: 'menu_button_admin',
                beforeLeave: routerHelper.defaultHandleBeforeLeave
            },
            {
                name: 'admin_fines',
                path: 'fines',
                pageSource: import('../pages/admin/fines.html'),
                pageHandler: import('./pages/admin/fines'),
                async: routerLoadPage,
                beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                menu: '/menu_main',
                menuButton: 'menu_button_admin',
                beforeLeave: routerHelper.defaultHandleBeforeLeave
            },
            {
                name: 'admin_reports',
                path: 'reports',
                pageSource: import('../pages/admin/reports/reports.html'),
                pageHandler: import('./pages/admin/reports/reports'),
                async: routerLoadPage,
                beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                menu: '/menu_main',
                menuButton: 'menu_button_admin',
                beforeLeave: routerHelper.defaultHandleBeforeLeave,
                routes: [
                    {
                        name: 'admin_report',
                        path: ':reportID',
                        pageSource: import('../pages/admin/reports/report.html'),
                        pageHandler: import('./pages/admin/reports/report'),
                        async: routerLoadPage,
                        beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                        menu: '/menu_main',
                        menuButton: 'menu_button_admin',
                        beforeLeave: routerHelper.defaultHandleBeforeLeave
                    }
                ]
            },
            {
                name: 'admin_authentication',
                path: 'authentication',
                pageSource: import('../pages/admin/authentication.html'),
                pageHandler: import('./pages/admin/authentication'),
                async: routerLoadPage,
                beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                menu: '/menu_main',
                menuButton: 'menu_button_admin',
                beforeLeave: routerHelper.defaultHandleBeforeLeave
            },
            {
                name: 'admin_activitylog',
                path: 'activitylog',
                pageSource: import('../pages/admin/activitylog.html'),
                pageHandler: import('./pages/admin/activitylog'),
                async: routerLoadPage,
                beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                menu: '/menu_main',
                menuButton: 'menu_button_admin',
                beforeLeave: routerHelper.defaultHandleBeforeLeave
            },
            {
                name: 'admin_branding',
                path: 'branding',
                pageSource: import('../pages/admin/branding.html'),
                pageHandler: import('./pages/admin/branding'),
                async: routerLoadPage,
                beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                menu: '/menu_main',
                menuButton: 'menu_button_admin',
                beforeLeave: routerHelper.defaultHandleBeforeLeave
            },
            {
                name: 'admin_users',
                path: 'users',
                pageSource: import('../pages/admin/users.html'),
                pageHandler: import('./pages/admin/users'),
                async: routerLoadPage,
                beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                menu: '/menu_main',
                menuButton: 'menu_button_admin',
                beforeLeave: routerHelper.defaultHandleBeforeLeave
            },
            {
                name: 'admin_checklists',
                path: 'checklists',
                pageSource: import('../pages/admin/checklists/checklists.html'),
                pageHandler: import('./pages/admin/checklists/checklists'),
                async: routerLoadPage,
                beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                menu: '/menu_main',
                menuButton: 'menu_button_admin',
                beforeLeave: routerHelper.defaultHandleBeforeLeave,
                routes: [
                    {
                        name: 'admin_checklist',
                        path: ':checklistID',
                        pageSource: import('../pages/admin/checklists/checklist.html'),
                        pageHandler: import('./pages/admin/checklists/checklist'),
                        async: routerLoadPage,
                        beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                        menu: '/menu_main',
                        menuButton: 'menu_button_admin',
                        beforeLeave: routerHelper.defaultHandleBeforeLeave
                    }
                ]
            },
            {
                name: 'admin_forms',
                path: 'forms',
                pageSource: import('../pages/admin/forms/forms.html'),
                pageHandler: import('./pages/admin/forms/forms'),
                async: routerLoadPage,
                beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                menu: '/menu_main',
                menuButton: 'menu_button_admin',
                beforeLeave: routerHelper.defaultHandleBeforeLeave,
                routes: [
                    {
                        name: 'admin_forms_submitted',
                        path: 'submitted',
                        pageSource: import('../pages/admin/forms/submitted.html'),
                        pageHandler: import('./pages/admin/forms/submitted'),
                        async: routerLoadPage,
                        beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                        menu: '/menu_main',
                        menuButton: 'menu_button_admin',
                        beforeLeave: routerHelper.defaultHandleBeforeLeave,
                        routes: [
                            {
                                name: 'form_view',
                                path: ':checklistID',
                                pageSource: import('../pages/checklist/view.html'),
                                pageHandler: import('./pages/checklist/view'),
                                async: routerLoadPage,
                                beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                                menu: '/menu_main',
                                menuButton: 'menu_button_admin',
                                beforeLeave: routerHelper.defaultHandleBeforeLeave
                            }
                        ]
                    },
                    {
                        name: 'admin_form',
                        path: ':checklistID',
                        pageSource: import('../pages/admin/checklists/checklist.html'),
                        pageHandler: import('./pages/admin/checklists/checklist'),
                        async: routerLoadPage,
                        beforeEnter: [routerHelper.checkIfSignedIn, routerHelper.checkIfAdmin],
                        menu: '/menu_main',
                        menuButton: 'menu_button_admin',
                        beforeLeave: routerHelper.defaultHandleBeforeLeave
                    }
                ]
            }
        ]
    },
    {
        name: '404',
        path: '(.*)',
        pageSource: import('../pages/404.html'),
        pageHandler: import('./pages/404'),
        async: routerLoadPage,
    }
];

export const menuRoutes: any[] = [
    {
        name: 'menu_main',
        path: '/menu_main',
        pageSource: import('../menu/main.html'),
        pageHandler: import('./menu/main'),
        async: routerLoadPage,
        keepAlive: true
    }
];

async function routerLoadPage({ app, to, resolve }) {
    const pageSource: string = await to.route.pageSource;
    menu.closeMenu(true);
    resolve({
        content: pageSource
    });
}