.accessibility_largecursor {
    cursor: url('../assets/img/cursor_large.png'), auto;
}

.accessibility_wordspacing_3px {
    word-spacing: 3px;
}

.accessibility_wordspacing_5px {
    word-spacing: 5px;
}

.accessibility_wordspacing_7px {
    word-spacing: 7px;
}

.accessibility_wordspacing_9px {
    word-spacing: 9px;
}

.accessibility_letterspacing_1px {
    letter-spacing: 1px;
}

.accessibility_letterspacing_2px {
    letter-spacing: 2px;
}

.accessibility_letterspacing_3px {
    letter-spacing: 3px;
}

.accessibility_letterspacing_4px {
    letter-spacing: 4px !important;
}

.accessibility_lineheight_1point8 {
    line-height: 1.8;
}

.accessibility_lineheight_2 {
    line-height: 2;
}

.accessibility_lineheight_2point2 {
    line-height: 2.2;
}

.accessibility_lineheight_2point4 {
    line-height: 2.4;
}

.accessibility_zoom_10percent {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
}

.accessibility_zoom_15percent {
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    transform: scale(1.15);
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
}

.accessiblity_invertcolors {
    -webkit-filter: invert(100%);
    filter: invert(100%);
}

.accessibility_saturation_200 {
    -webkit-filter: saturate(200%);
    filter: saturate(200%);
}

.accessibility_saturation_300 {
    -webkit-filter: saturate(300%);
    filter: saturate(300%);
}

.accessibility_saturation_400 {
    -webkit-filter: saturate(400%);
    filter: saturate(400%);
}

.accessibility_grayscale {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.accessibility_contrast_150 {
    -webkit-filter: contrast(150%);
    filter: contrast(150%);
}

.accessibility_contrast_200 {
    -webkit-filter: contrast(200%);
    filter: contrast(200%);
}

.accessibility_brightness_95 {
    -webkit-filter: brightness(95%);
    filter: brightness(95%);
}

.accessibility_brightness_90 {
    -webkit-filter: brightness(90%);
    filter: brightness(90%);
}

.accessibility_brightness_85 {
    -webkit-filter: brightness(85%);
    filter: brightness(85%);
}

.accessibility_brightness_80 {
    -webkit-filter: brightness(80%);
    filter: brightness(80%);
}

.accessibility_brightness_75 {
    -webkit-filter: brightness(75%);
    filter: brightness(75%);
}

.accessibility_nofontkerning {
    font-kerning: none !important;
}

.accessibility_smallcaps {
    font-variant: small-caps !important;
}

.accessibility_sepia_25 {
    -webkit-filter: sepia(25%);
    filter: sepia(25%);
}

.accessibility_sepia_50 {
    -webkit-filter: sepia(50%);
    filter: sepia(50%);
}

.accessibility_sepia_75 {
    -webkit-filter: sepia(75%);
    filter: sepia(75%);
}

.accessibility_sepia_100 {
    -webkit-filter: sepia(100%);
    filter: sepia(100%);
}

.accessibility_huerotation_45 {
    -webkit-filter: hue-rotate(45deg);
    filter: hue-rotate(45deg);
}

.accessibility_huerotation_90 {
    -webkit-filter: hue-rotate(90deg);
    filter: hue-rotate(90deg);
}

.accessibility_huerotation_135 {
    -webkit-filter: hue-rotate(135deg);
    filter: hue-rotate(135deg);
}

.accessibility_huerotation_180 {
    -webkit-filter: hue-rotate(180deg);
    filter: hue-rotate(180deg);
}

.accessibility_huerotation_225 {
    -webkit-filter: hue-rotate(225deg);
    filter: hue-rotate(225deg);
}

.accessibility_huerotation_270 {
    -webkit-filter: hue-rotate(270deg);
    filter: hue-rotate(270deg);
}

.accessibility_huerotation_315 {
    -webkit-filter: hue-rotate(315deg);
    filter: hue-rotate(315deg);
}

.accessibility_caretcolor_red {
    caret-color: red !important;
}

.accessibility_caretcolor_blue {
    caret-color: blue !important;
}

.accessibility_caretcolor_green {
    caret-color: green !important;
}

.accessibility_caretcolor_yellow {
    caret-color: yellow !important;
}

.accessibility_caretcolor_gray {
    caret-color: gray !important;
}

.accessibility_caretcolor_orange {
    caret-color: orange !important;
}

.accessibility_caretcolor_brown {
    caret-color: brown !important;
}

.accessibility_caretcolor_white {
    caret-color: white !important;
}

.accessibility_textcolor_red {
    color: red !important;
}

.accessibility_textcolor_blue {
    color: blue !important;
}

.accessibility_textcolor_green {
    color: green !important;
}

.accessibility_textcolor_yellow {
    color: yellow !important;
}

.accessibility_textcolor_gray {
    color: gray !important;
}

.accessibility_textcolor_orange {
    color: orange !important;
}

.accessibility_textcolor_brown {
    color: brown !important;
}

.accessibility_textcolor_white {
    color: white !important;
}