import callBackendFunction from "./backend";
import { api_ip_get } from "./backendinterfaces";
import * as database from './database';
import * as device from './device';


export function scanStringForXSS(str: string): boolean {
    const flaggedKeys = ['<link', '<script', '<object', '<iframe', '<div', 'javascript:', 'src=', 'href=', 'data=', 'eval(', '<!--', '<style', '<body', 'expression('];
    for (let i in flaggedKeys) {
        if (str.includes(flaggedKeys[i])) {
            console.log('flagged: ', flaggedKeys[i]);
            return true;
        }
    }
    return false;
}

export function displayConsoleSecurityWarning() {
    //@ts-ignore
    window.scanStringForXSS = scanStringForXSS;
    console.log('%cStop!', 'color: red; font-size: xx-large');
    console.log('%cThe console is intended for developers, if someone told you to enter something here, it may be a scam using an attack called Self-XSS.', 'font-size: large');
}

export function logSignIn(userID: string): Promise<void> {
    return new Promise(function (resolve, reject) {
        callBackendFunction('/user/signin_security_check', {
            browser: device.getBrowser(),
            os: device.getOperatingSystem(),
            userid: userID
        }, false).then(function (response: api_ip_get) {
            resolve()
        }).catch(function (error) {
            resolve();
        });
    });
}