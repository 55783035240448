import 'regenerator-runtime/runtime'; //Fixes missing regenerator-runtime error

import initializeFirebase from "./libs/initializefirebase";
import { initializeFramework7 } from './libs/framework7';
import { hideLoader as hideBaseLoader, hideBrowserWarning } from './baseloadingindicator';
import { loadFontAwesome } from './libs/fontawesome';
import '../css/app.css';
import { getAndApplyLocalAccessibilityOptions } from './accessibility';
import { setupPageInitHandler } from './pageinit';
import { initializePageResizeHandler } from './pageresize';
import '../lib/fontawesome/all.min.css';
import * as analytics from './analytics';
import { displayConsoleSecurityWarning } from './security';
import { loadGlobalConfig } from './globalconfig';



let initialized: boolean = false;



export default async function initializeCore() {
    if (!initialized) {
        initializeFirebase(); //Make sure firebase is loaded before Framework7 so that routes can check for auth state
        setupPageInitHandler();
        initializeFramework7(); //After loading framework7, the framework handles the current route (will initially check auth state and go to page or signin)
        //loadFontAwesome();
        getAndApplyLocalAccessibilityOptions();
        initializePageResizeHandler();
        loadGlobalConfig();
        initialized = true;
        hideBaseLoader();
        hideBrowserWarning();
        displayConsoleSecurityWarning();
        analytics.logAnalyticsEvent('pageload', 'app', false);
    }
}