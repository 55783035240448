let lockedState: boolean = false;
let confirmGoBack: boolean = false;



export function setIsLocked(state: boolean) {
    lockedState = state;
}

export function isLocked(): boolean {
    return lockedState;
}

export function setIsConfirmGoBack(state: boolean) {
    confirmGoBack = state;
}

export function isConfirmGoBack(): boolean {
    return confirmGoBack;
}