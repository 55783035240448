import { Dom7 as $$ } from 'framework7';
import * as database from './database';
import { agencyLocationDocument, complianceFrameworkItemDocument, statisticItemDocument } from './databaseinterfaces';



export function loadLocationsDropdown(selector: string, selectedLocation?: string) {
    $$(selector).html('<option value="">No location</option>');
    database.get('/agencies/$agencyID$/locations').then(function (locations: agencyLocationDocument[]) {
        if (locations.length === 0) {
            return;
        }
        locations.forEach(function (location: agencyLocationDocument) {
            $$(selector).append('<option value="' + location.id + '">' + location.name + '</option>');
        });
        $$(selector).val(selectedLocation);
    });
}

export function loadStatisticsDropdown(selector: string, selectedStatistic?: string): Promise<void> {
    return new Promise(function (resolve, reject) {
        $$(selector).html('<option value="">No statistic</option>');
        database.get('/agencies/$agencyID$/statistics').then(function (statistics: statisticItemDocument[]) {
            if (statistics.length === 0) {
                resolve();
                return;
            }
            statistics.forEach(function (statistic: statisticItemDocument) {
                $$(selector).append('<option value="' + statistic.id + '">' + statistic.name + '</option>');
            });
            $$(selector).val(selectedStatistic);
            resolve();
        });
    });
}

export function loadComplianceFrameworksDropdown(selector: string, selectedFramework?: string): Promise<void> {
    return new Promise(function (resolve, reject) {
        $$(selector).html('<option value="">No framework</option>');
        database.get('/agencies/$agencyID$/complianceframeworks').then(function (frameworks: complianceFrameworkItemDocument[]) {
            if (frameworks.length === 0) {
                resolve();
                return;
            }
            frameworks.forEach(function (framework: complianceFrameworkItemDocument) {
                $$(selector).append('<option value="' + framework.id + '">' + framework.name + '</option>');
            });
            if (selectedFramework) {
                $$(selector).val(selectedFramework);
            }
            resolve();
        });
    });
}

export function loadYearsDropdown(selector: string, selectedYear?: string): void {
    let yearOptions: string = '';
    for (var i = 2010; i <= new Date().getFullYear() + 5; i++) {
        if (i === new Date().getFullYear()) {
            yearOptions += '<option value="' + i + '">' + i + ' (Current Year)</option>';
        } else {
            yearOptions += '<option value="' + i + '">' + i + '</option>';
        }
    }
    $$(selector).html(yearOptions);
    if (selectedYear) {
        $$(selector).val(selectedYear)
    }
}