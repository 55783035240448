import * as eventEmitterLib from 'events';



const emitter = new eventEmitterLib.EventEmitter;



export function emitEvent(eventName: string, message?: string): void {
    if (message) {
        emitter.emit(eventName, message);
    } else {
        emitter.emit(eventName);
    }
}

export function listenForEvent(eventName: string, callback: Function) {
    emitter.on(eventName, function (message: string) {
        callback(message);
    });
}

export function removeListener(eventName: string, callback: Function) {
    emitter.removeListener(eventName, function (message: string) {
        callback(message);
    });
}

export function removeAllListeners() {
    emitter.removeAllListeners();
}