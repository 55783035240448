import { shiftCalendarItemDocument, shiftItemDocument } from "./databaseinterfaces";
import * as database from './database';
import * as utils from './utils';



export function getCurrentShift(): Promise<shiftCalendarItemDocument> {
    return new Promise(function (resolve, reject) {
        const presentTime: Date = new Date();
        database.get('/agencies/$agencyID$/shifts_calendar', { queryWhere: { index: 'timestamp_end', operator: '>=', where: presentTime } }).then(function (shiftCalendars: shiftCalendarItemDocument[]) {
            if (shiftCalendars.length === 0) {
                resolve(undefined);
                return;
            }
            let foundShiftCalendar: shiftCalendarItemDocument;
            for (let i in shiftCalendars) {
                const currentTime: Date = new Date();
                //We have typescript ignore this line because the type of the timestamp is now a firebase one, but the interface we have
                //is set as a date
                //@ts-ignore
                if (currentTime > shiftCalendars[i].timestamp_start.toDate() && currentTime < shiftCalendars[i].timestamp_end.toDate() && !foundShiftCalendar) {
                    foundShiftCalendar = shiftCalendars[i];
                }
            }
            if (foundShiftCalendar) {
                resolve(foundShiftCalendar);
            } else {
                resolve(undefined);
            }
        }).catch(function (error: any) {
            reject(error);
        });
    });
}

export function formatShiftID(id: string, startTime: Date): string {
    return 'shift_' + id + '_' + startTime.getDate() + '-' + startTime.getMonth() + '-' + startTime.getFullYear();
}

export function getShiftIDFromShiftDocument(shiftDoc: shiftItemDocument, setDate?: number): string {
    const startTime: Date = utils.getCleanDate();
    if (setDate) {
        startTime.setDate(setDate);
    }
    startTime.setHours(utils.convertTimePickerValueToDate(shiftDoc.time_start).getHours());
    startTime.setMinutes(utils.convertTimePickerValueToDate(shiftDoc.time_start).getMinutes());
    return formatShiftID(shiftDoc.id, startTime);
}