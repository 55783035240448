import { Dom7 as $$ } from 'framework7';
import { displayMobileMenuButtonBasedOnScreenSize } from './menu';



let resizeTime: Date;
let timeout: boolean = false;


export function initializePageResizeHandler() {
    $$(window).resize(function () {
        resizeTime = new Date();
        if (timeout === false) {
            timeout = true;
            setTimeout(pageResizeEndHelper, 250);
        }
    });
    function pageResizeEndHelper() {
        let ndate: any = new Date();
        if (ndate - resizeTime.getTime() < 250) {
            setTimeout(pageResizeEndHelper, 250);
        } else {
            timeout = false;
            handlePageResizeEnd();
        }
    }
}

function handlePageResizeEnd() {
    displayMobileMenuButtonBasedOnScreenSize();
}