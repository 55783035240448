import { app } from './libs/framework7';



/**
 * Gets if the current device is a mobile device.
 * @returns {boolean} True if a mobile device.
 */
export function getIfMobileDevice(): boolean {
    return (app.device.desktop ? false : true)
}

export function getOperatingSystem(): string {
    return app.device.os;
}

export type browserName = 'edge' | 'chrome' | 'firefox' | 'safari' | 'opera' | 'none'

export function getBrowser(): browserName {

    const userAgent = navigator.userAgent;
    let browserName: browserName;

    if (userAgent.match(/edg/i)) {
        browserName = 'edge';
    } else if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = 'chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = 'firefox';
    } else if (userAgent.match(/safari/i)) {
        browserName = 'safari';
    } else if (userAgent.match(/opr\//i)) {
        browserName = 'opera';
    } else {
        browserName = 'none';
    }

    return browserName;
}