import { fineItemDocument, losapCategoryDocument, losapPercentageDocument, userDocument } from "./databaseinterfaces";

export interface allUsersCacheObject { [userID: string]: userDocument; }
export interface finesCacheObject { [fineID: string]: fineItemDocument; }
export interface losapCategoriesCacheObject { [losapCategoryID: string]: losapCategoryDocument; }
export interface losapPercentagesCacheObject { [losapPercentageID: string]: losapPercentageDocument; }

let allUsers: allUsersCacheObject = {};
let fines: finesCacheObject = {};
let losapCategories: losapCategoriesCacheObject = {};
let losapPercentages: losapPercentagesCacheObject = {};


export function getAllUsersCache(): allUsersCacheObject {
    return allUsers;
}

export function setAllUsersCache(newUsers: allUsersCacheObject): void {
    allUsers = newUsers;
}

export function getFinesCache(): finesCacheObject {
    return fines;
}

export function setFinesCache(newFines: finesCacheObject): void {
    fines = newFines;
}

export function getLOSAPCategoriesCache(): losapCategoriesCacheObject {
    return losapCategories;
}

export function setLOSAPCategoriesCache(newLOSAPCategories: losapCategoriesCacheObject): void {
    losapCategories = newLOSAPCategories;
}

export function getLOSAPPercentagesCache(): losapPercentagesCacheObject {
    return losapPercentages;
}

export function setLOSAPPercentagesCache(newLOSAPPercentages: losapPercentagesCacheObject): void {
    losapPercentages = newLOSAPPercentages;
}