import { getLocalAgencyInfo } from './agency';
import * as database from './database';
import { hideLoadPreloader } from './ui';
import { getURLQuery } from './urlparameters';
import { getUserID, userIsSignedIn } from './user';

export function debugConsoleLog(...args: any[]) {
    if (getURLQuery('debug') === 'true') {
        console.log('DEBUG: ', ...args);
    }
}

export function logErrorToServer(error: any, notes: string) {
    try {
        hideLoadPreloader();
        let errorText: string = '';
        if (error) {
            if (error.stack) {
                errorText = error.stack;
            } else if (typeof error === 'object') {
                errorText = JSON.stringify(error);
            } else {
                errorText = error.toString();
            }
        }
        userIsSignedIn().then(function (isSignedIn: boolean) {
            database.add('/errorlogs', {
                agencyid: (getLocalAgencyInfo() ? getLocalAgencyInfo().id : ''),
                userid: (isSignedIn ? getUserID() : ''),
                timestamp: database.SERVERTIMESTAMP(),
                url: window.location.href,
                error: errorText,
                notes: notes
            });
            debugConsoleLog('Error: ', error);
        });
    } catch (error) {
        debugConsoleLog('Failed to log error: ', error);
    }
}