import { checkListItemDocument, fineItemDocument, losapCategoryDocument, userSignatureDocument, smartRigAlertCallDocument } from './databaseinterfaces';
import { getInputValue, getDatePickerValue, setInputValue, setDatePickerValue, displayAlert, displayErrorAlert, getDrawingBoardInstance, setDrawingBoardInstance, handleClick, displayLoadPreloader, hideLoadPreloader, initializeStepper, initializeDatePicker, displayConfirmDialog } from './ui';
import { Dom7 as $$ } from 'framework7';
import { app } from './libs/framework7';
import { generateID, getTimeRemainingBetweenTwoDates, getDateMillis, formatTimeStamp, getDateAtLastDayOfMonth, convertMonthStringToNumber, convertMonthNumberToString } from './utils';
import { getCurrentFile, getFileURLFromStorage, getIfHasCurrentFile, setupCurrentFileUploadInput, uploadCurrentFile, validateCurrentFileTypeIsImage } from './fileupload';
import { getAgencyID, getAgencyInfo, getLocalAgencyInfo } from './agency';
import { validateFileSize } from './validation';
import { emitEvent } from './events';
import * as database from './database';
import { getUserFullName, getUserID } from './user';
import { uploadDataURL, getDataURL } from './fileupload';
import { finesCacheObject, getFinesCache, getLOSAPCategoriesCache, losapCategoriesCacheObject, setFinesCache, setLOSAPCategoriesCache } from './datacache';
import { logErrorToServer } from './debug';



export function getChecklistItemTypes() {
    return {
        title: {
            name: 'Title',
            icon: 'fa-h1',
            runForm: import('../templates/checklist/run/title.hbs'),
            renderViewItem: async function (id: string, checkListItemData: checkListItemDocument): Promise<string> {
                return '<div style="font-weight: bold; font-size: 1.3em;">' + checkListItemData.items[id].title + '</div>'
            },
            itemObjectTemplate: {
                type: 'title',
                title: 'New Title',
                notes: '',
                column: 1
            }
        },
        section: {
            name: 'Section',
            icon: 'fa-object-group',
            runForm: import('../templates/checklist/run/section.hbs'),
            renderViewItem: async function (id: string, checkListItemData: checkListItemDocument): Promise<string> {
                return '<div style="background-color: #e8e6e6; font-weight: bold; font-size: 1.1em; height: 100%; display: flex; justify-content: center; align-items: center;"><span>' + checkListItemData.items[id].title + '</span></div>'
            },
            itemObjectTemplate: {
                type: 'section',
                title: 'New Section',
                notes: '',
                column: 1
            }
        },
        checkbox: {
            name: 'Checkbox',
            icon: 'fa-check-square',
            runForm: import('../templates/checklist/run/checkbox.hbs'),
            renderViewItem: async function (id: string, checkListItemData: checkListItemDocument): Promise<string> {
                let ret: string = '<div>';
                if (checkListItemData.items[id].data.checked) {
                    ret += '<span class="far fa-check-square fa-lg" alt="Checked" title="Checked"></span> '
                } else {
                    ret += '<span class="far fa-square fa-lg" alt="Unchecked" title="Unchecked"></span> '
                }
                ret += checkListItemData.items[id].title;
                if (checkListItemData.items[id].special?.counter) {
                    ret += ' (Count: ' + checkListItemData.items[id].data.counter + ')';
                }
                if (checkListItemData.items[id].special?.expiration && checkListItemData.items[id].data.expiration) {
                    ret += ' (Expires: ' + formatTimeStamp(getDateMillis(checkListItemData.items[id].data.expiration), 'date') + ')';
                }
                if (checkListItemData.items[id].special.canaddnotes && checkListItemData.items[id].data.canaddnotes) {
                    ret += '<br> <span class="fas fa-sticky-note"></span> ' + checkListItemData.items[id].data.canaddnotes;
                }
                ret += '</div>';
                return ret;
            },
            runLoadFunction: function (id: string, checkListItemData: checkListItemDocument, fromRemoteUserChange: boolean = false, firstRender: boolean): void {
                if (typeof checkListItemData.items[id].data.expiration === 'string') { //If restored from local backup, date will be a string
                    if (!checkListItemData.items[id].special.expirationformat) {
                        checkListItemData.items[id].data.expiration = new Date(checkListItemData.items[id].data.expiration);
                    }
                } else if (typeof checkListItemData.items[id].data.expiration === 'object') {
                    if (checkListItemData.items[id].special.expirationformat === 'monthyear') {
                        const properDate: Date = new Date(getDateMillis(checkListItemData.items[id].data.expiration));
                        const monthString: string = convertMonthNumberToString(properDate.getMonth())
                        const year: number = properDate.getFullYear();
                        setInputValue('#checklist_run_item_special_expiration_' + id, monthString + ' ' + year);
                    }
                }
                if (fromRemoteUserChange && (getInputValue('#checklist_run_item_' + id) !== checkListItemData.items[id].data.checked)) {
                    displayElementChanged('.checklist_run_item_container_' + id, checkListItemData.lastchangebyusername);
                }
                setInputValue('#checklist_run_item_' + id, checkListItemData.items[id].data.checked);
                if (checkListItemData.items[id].special?.expiration) {
                    if (checkListItemData.items[id].data.expiration) {
                        if (!checkListItemData.items[id].special.expirationformat) {
                            let expirationDate: any = checkListItemData.items[id].data.expiration;
                            setDatePickerValue('#checklist_run_item_special_expiration_' + id, new Date(getDateMillis(expirationDate)));
                        }
                    }
                }
                if (checkListItemData.items[id].special?.canaddnotes) {
                    if (checkListItemData.items[id].data.canaddnotes) {
                        setInputValue('#checklist_run_item_special_canaddnotes_' + id, checkListItemData.items[id].data.canaddnotes);
                    }
                }
                if (checkListItemData.items[id].special?.counter) {
                    if (!isNaN(checkListItemData.items[id].data.counter)) {
                        setInputValue('#checklist_run_item_special_counter_' + id, checkListItemData.items[id].data.counter);
                    }
                    if (getInputValue('#checklist_run_item_' + id)) { // If checked, hide the counter
                        $$('.checklist_run_item_special_disp_counter_' + id).hide();
                    } else {
                        $$('.checklist_run_item_special_disp_counter_' + id).show();
                    }
                }
            },
            runSaveFunction: async function (id: string, checkListItemData: checkListItemDocument): Promise<void> {
                checkListItemData.items[id].data.checked = getInputValue('#checklist_run_item_' + id);
                if (checkListItemData.items[id].special?.expiration) {
                    if (checkListItemData.items[id].special.expirationformat === 'monthyear') {
                        if (getInputValue('#checklist_run_item_special_expiration_' + id)) {
                            const monthNumber: number = convertMonthStringToNumber(getInputValue('#checklist_run_item_special_expiration_' + id).split(' ')[0]);
                            const convertedDate: Date = getDateAtLastDayOfMonth(monthNumber, Number(getInputValue('#checklist_run_item_special_expiration_' + id).split(' ')[1]));
                            checkListItemData.items[id].data.expiration = new Date(convertedDate);
                        } else {
                            checkListItemData.items[id].data.expiration = false;
                        }
                    } else {
                        let expirationDate: Date = getDatePickerValue('#checklist_run_item_special_expiration_' + id);
                        checkListItemData.items[id].data.expiration = (expirationDate ? expirationDate : false);
                    }
                }
                if (checkListItemData.items[id].special?.canaddnotes) {
                    checkListItemData.items[id].data.canaddnotes = getInputValue('#checklist_run_item_special_canaddnotes_' + id);
                }
                if (checkListItemData.items[id].special?.counter) {
                    checkListItemData.items[id].data.counter = Number(getInputValue('#checklist_run_item_special_counter_' + id));
                    if (getInputValue('#checklist_run_item_' + id)) { // If checked, hide the counter
                        $$('.checklist_run_item_special_disp_counter_' + id).hide();
                    } else {
                        $$('.checklist_run_item_special_disp_counter_' + id).show();
                    }
                }
                if (checkListItemData.items[id].special.complianceframeworks && checkListItemData.items[id].complianceframeworks?.id) {
                    checkListItemData.hascomplianceframework = true;
                    if (!checkListItemData.complianceframeworks) {
                        checkListItemData.complianceframeworks = [];
                    }
                    if (!checkListItemData.complianceframeworks.includes(checkListItemData.items[id].complianceframeworks.id)) {
                        checkListItemData.complianceframeworks.push(checkListItemData.items[id].complianceframeworks.id);
                    }
                }
            },
            runValidate: function (id: string, checkListItemData: checkListItemDocument): string {
                if (getInputValue('#checklist_run_item_' + id) && checkListItemData.items[id].special?.expiration) {
                    if (checkListItemData.items[id].special.expirationformat === 'monthyear') {
                        if (!getInputValue('#checklist_run_item_special_expiration_' + id)) {
                            return '"' + checkListItemData.items[id].title + '" requires an expiration date.';
                        } else {
                            return '';
                        }
                    } else {
                        if (!getDatePickerValue('#checklist_run_item_special_expiration_' + id)) {
                            return '"' + checkListItemData.items[id].title + '" requires an expiration date.';
                        } else {
                            return '';
                        }
                    }

                } else {
                    return '';
                }
            },
            runAlert: function (id: string, checkListItemData: checkListItemDocument): boolean {
                let alertValue: string = ''
                if (checkListItemData.items[id].special.noalert) {
                    return;
                } else {
                    if (!getInputValue('#checklist_run_item_' + id)) {
                        alertValue = 'Missing: "' + checkListItemData.items[id].title + '"';
                    } else {
                        if (checkListItemData.items[id].special?.expiration) {
                            let expirationDate: Date = getDatePickerValue('#checklist_run_item_special_expiration_' + id);
                            if (checkListItemData.items[id].special.expirationformat === 'monthyear') {
                                const monthNumber: number = convertMonthStringToNumber(getInputValue('#checklist_run_item_special_expiration_' + id).split(' ')[0]);
                                const convertedDate: Date = getDateAtLastDayOfMonth(monthNumber, Number(getInputValue('#checklist_run_item_special_expiration_' + id).split(' ')[1]));
                                expirationDate = new Date(convertedDate);
                            } else {
                                expirationDate = getDatePickerValue('#checklist_run_item_special_expiration_' + id);
                            }
                            let daysRemaining: number = getTimeRemainingBetweenTwoDates(new Date(), expirationDate).days;
                            if (daysRemaining < 8) { //Alert if expires in a week
                                alertValue = '"' + checkListItemData.items[id].title + '" expiration is in ' + (daysRemaining + 1) + ' days.';
                            } else {
                                return;
                            }
                        } else {
                            return;
                        }
                    }
                }
                if (alertValue) {
                    checkListItemData.alerts.push(alertValue);
                    if (checkListItemData.items[id].special.notifyifalert) {
                        checkListItemData.hasalerts = true;
                    }
                    return true;
                } else {
                    return false;
                }
            },
            itemObjectTemplate: {
                type: 'checkbox',
                title: 'New Checkbox',
                notes: '',
                data: {
                    checked: false,
                    expiration: false,
                    canaddnotes: '',
                    counter: 0
                },
                statistics: {
                    id: '',
                    action: ''
                },
                complianceframeworks: {
                    id: '',
                    requiredcount: 0
                },
                update: 0,
                special: {
                    expiration: false,
                    expirationformat: '',
                    noalert: false,
                    canaddnotes: false,
                    notifyifalert: false,
                    counter: false,
                    outofservice: false,
                    statistics: true,
                    complianceframeworks: false
                },
                column: 1
            }
        },
        uploadphoto: {
            name: 'Upload Photo',
            icon: 'fa-camera',
            runForm: import('../templates/checklist/run/uploadphoto.hbs'),
            renderViewItem: async function (id: string, checkListItemData: checkListItemDocument): Promise<string> {
                let ret: string = '<div>';
                ret += '<span class="boldtext">' + checkListItemData.items[id].title + ': </span>';
                ret += '<br>';
                if (checkListItemData.items[id].data.photourl) {
                    try {
                        const imageURL = await getFileURLFromStorage(checkListItemData.items[id].data.photourl);
                        ret += '<img style="max-width: 50%;" class="noselect" src="' + imageURL + '" alt="">';
                    } catch (error) {
                        logErrorToServer(error, 'uploadphoto:renderViewItem():1');
                        ret += '<i class="fas fa-file-times fa-3x"></i> <br> <p>Image could not be found.</p>';
                    }
                } else {
                    ret += '<span>No photo uploaded.</span>';
                }
                if (checkListItemData.items[id].special.canaddnotes) {
                    ret += '<br> <span class="fas fa-sticky-note"></span> ' + checkListItemData.items[id].data.canaddnotes;
                }
                ret += '</div>';
                return ret;
            },
            runLoadFunction: async function (id: string, checkListItemData: checkListItemDocument, fromRemoteUserChange: boolean = false, firstRender: boolean): Promise<void> {
                if (firstRender) {
                    if (checkListItemData.items[id].special.canaddnotes) {
                        $$('.checklist_run_item_uploadphoto_disp_notes_' + id).show();
                    } else {
                        $$('.checklist_run_item_uploadphoto_disp_notes_' + id).hide();
                    }
                    setupCurrentFileUploadInput('input[name=checklist_run_item_uploadphoto_file_' + id + ']', true);
                    document.querySelector('.checklist_run_item_uploadphoto_file_' + id).addEventListener('FilePond:addfile', (e: any) => {
                        emitEvent('checklist_run_itemchange', id + '|' + checkListItemData.items[id].type);
                    });
                    document.querySelector('.checklist_run_item_uploadphoto_file_' + id).addEventListener('FilePond:removefile', (e: any) => {
                        checkListItemData.items[id].data.photoid = '';
                        checkListItemData.items[id].data.photourl = '';
                        emitEvent('checklist_run_itemchange', id + '|' + checkListItemData.items[id].type);
                    });
                }
                if (checkListItemData.items[id].data.photourl) {
                    const imageURL = await getFileURLFromStorage(checkListItemData.items[id].data.photourl);
                    $$('.admin_checklist_edititem_item_image_current_' + id).html('<img style="max-width: 100%;" class="noselect" src="' + imageURL + '" alt="">');
                }
                if (checkListItemData.items[id].special.canaddnotes) {
                    if (fromRemoteUserChange && (getInputValue('#checklist_run_item_uploadphoto_notes_' + id) !== checkListItemData.items[id].data.canaddnotes)) {
                        displayElementChanged('.checklist_run_item_container_' + id, checkListItemData.lastchangebyusername);
                    }
                    setInputValue('#checklist_run_item_uploadphoto_notes_' + id, checkListItemData.items[id].data.canaddnotes);
                }
            },
            runSaveFunction: function (id: string, checkListItemData: checkListItemDocument): Promise<void> {
                return new Promise(function (resolve, reject) {
                    if (checkListItemData.items[id].special?.canaddnotes) {
                        checkListItemData.items[id].data.canaddnotes = getInputValue('#checklist_run_item_uploadphoto_notes_' + id);
                    }
                    if (getIfHasCurrentFile('input[name=checklist_run_item_uploadphoto_file_' + id + ']')) {
                        if (checkListItemData.items[id].data.photoid !== getCurrentFile('input[name=checklist_run_item_uploadphoto_file_' + id + ']').filename) {
                            checkListItemData.items[id].data.photoid = getCurrentFile('input[name=checklist_run_item_uploadphoto_file_' + id + ']').filename;
                            uploadCurrentFile('/agencies/' + getAgencyID() + '/checklist_uploadedphotos/' + generateID(), 'input[name=checklist_run_item_uploadphoto_file_' + id + ']').then(function (uploadedFile: any) {
                                checkListItemData.items[id].data.photourl = uploadedFile.fileLoc;
                                emitEvent('checklist_run_updateactive');
                                resolve();
                            }).catch(function (error) {
                                logErrorToServer(error, 'uploadphoto:runSaveFunction():1');
                                displayErrorAlert('There was an error attempting to upload the photo.');
                                resolve();
                            });
                        } else {
                            resolve();
                        }
                    }
                });
            },
            runValidate: function (id: string, checkListItemData: checkListItemDocument): string {
                if (checkListItemData.items[id].special?.required) {
                    if (checkListItemData.items[id].data.photourl || getIfHasCurrentFile('input[name=checklist_run_item_uploadphoto_file_' + id + ']')) {
                        return '';
                    } else {
                        return '"' + checkListItemData.items[id].title + '" requires a photo to be uploaded.';
                    }
                } else {
                    return '';
                }
            },
            itemObjectTemplate: {
                type: 'uploadphoto',
                title: 'New Upload Photo',
                notes: '',
                data: {
                    canaddnotes: '',
                    photourl: '',
                    photoid: ''
                },
                update: 0,
                special: {
                    canaddnotes: false,
                    required: false
                },
                column: 1
            }
        },
        dropdown: {
            name: 'Dropdown',
            icon: 'fa-caret-square-down',
            editForm: import('../reuse/checklist/edit/dropdown.html'),
            runForm: import('../templates/checklist/run/dropdown.hbs'),
            renderViewItem: async function (id: string, checkListItemData: checkListItemDocument): Promise<string> {
                let ret: string = '';
                ret += '<span class="fas fa-caret-down fa-lg"></span> '
                ret += '<span class="boldtext">' + checkListItemData.items[id].title + ': </span>';
                if (checkListItemData.items[id].data.selecteditem) {
                    ret += checkListItemData.items[id].options['item' + checkListItemData.items[id].data.selecteditem];
                }
                return ret;
            },
            editLoadFunction: function (id: string, checkListItemData: checkListItemDocument): void {
                for (let i = 1; i <= 10; i++) {
                    if (checkListItemData.items[id].options['item' + i]) {
                        setInputValue('input[name=admin_checklist_edititem_item_dropdown_option' + i + ']', checkListItemData.items[id].options['item' + i]);
                    } else {
                        setInputValue('input[name=admin_checklist_edititem_item_dropdown_option' + i + ']', '');
                    }
                }
            },
            editValidate: function (id: string, checkListItemData: checkListItemDocument): boolean {
                return true;
            },
            editSaveFunction: async function (id: string, checkListItemData: checkListItemDocument): Promise<void> {
                for (let i = 1; i <= 10; i++) {
                    checkListItemData.items[id].options['item' + i] = getInputValue('input[name=admin_checklist_edititem_item_dropdown_option' + i + ']');
                }
                return;
            },
            runLoadFunction: function (id: string, checkListItemData: checkListItemDocument, fromRemoteUserChange: boolean = false, firstRender: boolean): void {
                if (firstRender) {
                    $$('#checklist_run_item_' + id).html('<option value="">Select an option</option>');
                    for (let i = 1; i <= 10; i++) {
                        if (checkListItemData.items[id].options['item' + i]) {
                            $$('#checklist_run_item_' + id).append('<option value="' + i + '">' + checkListItemData.items[id].options['item' + i] + '</option>');
                        }
                    }
                }
                if (fromRemoteUserChange && (getInputValue('#checklist_run_item_' + id) !== checkListItemData.items[id].data.selecteditem)) {
                    displayElementChanged('.checklist_run_item_container_' + id, checkListItemData.lastchangebyusername);
                }
                setInputValue('#checklist_run_item_' + id, checkListItemData.items[id].data.selecteditem);
            },
            runValidate: function (id: string, checkListItemData: checkListItemDocument): string {
                if (!getInputValue('#checklist_run_item_' + id)) {
                    return 'Select an option for "' + checkListItemData.items[id].title + '".';
                } else {
                    return '';
                }
            },
            runSaveFunction: async function (id: string, checkListItemData: checkListItemDocument): Promise<void> {
                checkListItemData.items[id].data.selecteditem = getInputValue('#checklist_run_item_' + id);
            },
            itemObjectTemplate: {
                type: 'dropdown',
                title: 'New Dropdown',
                notes: '',
                data: {
                    selecteditem: ''
                },
                update: 0,
                options: {
                    item1: '',
                    item2: '',
                    item3: '',
                    item4: '',
                    item5: '',
                    item6: '',
                    item7: '',
                    item8: '',
                    item9: '',
                    item10: ''
                },
                column: 1
            }
        },
        textfield: {
            name: 'Text Field',
            icon: 'fa-font-case',
            runForm: import('../templates/checklist/run/textfield.hbs'),
            renderViewItem: async function (id: string, checkListItemData: checkListItemDocument): Promise<string> {
                let ret: string = '';
                ret += '<span class="fas fa-comment fa-lg"></span> '
                ret += '<span class="boldtext">' + checkListItemData.items[id].title + ': </span>';
                if (checkListItemData.items[id].data.text) {
                    ret += checkListItemData.items[id].data.text;
                }
                return ret;
            },
            runLoadFunction: function (id: string, checkListItemData: checkListItemDocument, fromRemoteUserChange: boolean = false, firstRender: boolean): void {
                if (fromRemoteUserChange && (getInputValue('#checklist_run_item_' + id) !== checkListItemData.items[id].data.text)) {
                    displayElementChanged('.checklist_run_item_container_' + id, checkListItemData.lastchangebyusername);
                }
                setInputValue('#checklist_run_item_' + id, checkListItemData.items[id].data.text);
            },
            runValidate: function (id: string, checkListItemData: checkListItemDocument): string {
                if (checkListItemData.items[id].special.required && !getInputValue('#checklist_run_item_' + id)) {
                    return 'Enter a value for "' + checkListItemData.items[id].title + '".';
                } else {
                    return '';
                }
            },
            runSaveFunction: async function (id: string, checkListItemData: checkListItemDocument): Promise<void> {
                checkListItemData.items[id].data.text = getInputValue('#checklist_run_item_' + id);
            },
            itemObjectTemplate: {
                type: 'textfield',
                title: 'New Text Field',
                notes: '',
                data: {
                    text: ''
                },
                update: 0,
                special: {
                    required: false,
                    displayonnextchecklist: false
                },
                column: 1
            }
        },
        number: {
            name: 'Number',
            icon: 'fa-tally',
            runForm: import('../templates/checklist/run/number.hbs'),
            renderViewItem: async function (id: string, checkListItemData: checkListItemDocument): Promise<string> {
                let ret: string = '';
                ret += '<span class="fas fa-tally fa-lg"></span> '
                ret += '<span class="boldtext">' + checkListItemData.items[id].title + ': </span>';
                if (checkListItemData.items[id].data.number) {
                    ret += checkListItemData.items[id].data.number;
                }
                return ret;
            },
            runLoadFunction: function (id: string, checkListItemData: checkListItemDocument, fromRemoteUserChange: boolean = false, firstRender: boolean): void {
                if (fromRemoteUserChange && (Number(getInputValue('#checklist_run_item_' + id)) !== checkListItemData.items[id].data.number)) {
                    displayElementChanged('.checklist_run_item_container_' + id, checkListItemData.lastchangebyusername);
                }
                setInputValue('#checklist_run_item_' + id, checkListItemData.items[id].data.number);
            },
            runValidate: function (id: string, checkListItemData: checkListItemDocument): string {
                if (checkListItemData.items[id].special.required && !getInputValue('#checklist_run_item_' + id)) {
                    return 'Enter a value for "' + checkListItemData.items[id].title + '".';
                } else if (getInputValue('#checklist_run_item_' + id) && isNaN(Number(getInputValue('#checklist_run_item_' + id)))) {
                    return 'Enter a numerical value for "' + checkListItemData.items[id].title + '".';
                } else if (getInputValue('#checklist_run_item_' + id) && Number(getInputValue('#checklist_run_item_' + id) < 0)) {
                    return 'Number must be at least 0 for "' + checkListItemData.items[id].title + '".';
                } else {
                    return '';
                }
            },
            runSaveFunction: async function (id: string, checkListItemData: checkListItemDocument): Promise<void> {
                checkListItemData.items[id].data.number = Number(getInputValue('#checklist_run_item_' + id));
            },
            itemObjectTemplate: {
                type: 'number',
                title: 'New Number',
                notes: '',
                data: {
                    number: 0
                },
                statistics: {
                    id: '',
                    action: ''
                },
                update: 0,
                special: {
                    required: false,
                    statistics: true
                },
                column: 1
            }
        },
        image: {
            name: 'Image',
            icon: 'fa-image',
            editForm: import('../reuse/checklist/edit/image.html'),
            runForm: import('../templates/checklist/run/image.hbs'),
            renderViewItem: async function (id: string, checkListItemData: checkListItemDocument): Promise<string> {
                try {
                    const imageURL = await getFileURLFromStorage(checkListItemData.items[id].options.source);
                    return '<img style="max-width: 100%;" class="noselect" src="' + imageURL + '" alt="' + checkListItemData.items[id].options.alt + '">';
                } catch (error) {
                    logErrorToServer(error, 'image:renderViewItem():1');
                    return '<i class="fas fa-file-times fa-3x"></i> <br> <p>Image could not be found.</p>';
                }
            },
            editLoadFunction: function (id: string, checkListItemData: checkListItemDocument): void {
                setupCurrentFileUploadInput('input[name=admin_checklist_edititem_item_image_fileupload]');
                let currentItem = checkListItemData.items[id];
                if (currentItem.options.source) {
                    getFileURLFromStorage(currentItem.options.source).then(function (imageURL: string) {
                        $$('.admin_checklist_edititem_item_image_current').html('<div class="block-title">Current Image</div><img style="max-width: 100%;" src="' + imageURL + '" alt="' + currentItem.options.alt + '">');
                    });
                }
                setInputValue('input[name=admin_checklist_edititem_item_image_alt]', currentItem.options.alt);
            },
            editValidate: function (id: string, checkListItemData: checkListItemDocument): boolean {
                if (getIfHasCurrentFile()) {
                    if (!validateFileSize(getCurrentFile().file)) {
                        displayAlert('File is too large.');
                        return false;
                    }
                    if (!validateCurrentFileTypeIsImage()) {
                        displayAlert('Please select a valid image file.');
                        return false;
                    }
                } else {
                    if (!checkListItemData.items[id].options.source) { //No file selected, and no current file
                        displayAlert('Please select an image.');
                        return false;
                    }
                }
                return true;
            },
            editSaveFunction: async function (id: string, checkListItemData: checkListItemDocument): Promise<void> {
                checkListItemData.items[id].options.alt = getInputValue('input[name=admin_checklist_edititem_item_image_alt]');
                if (getIfHasCurrentFile()) {
                    uploadCurrentFile('/agencies/' + getAgencyID() + '/checklist_images/' + generateID()).then(function (uploadedFile: any) {
                        checkListItemData.items[id].options.source = uploadedFile.fileLoc;
                        return;
                    }).catch(function (error: any) {
                        logErrorToServer(error, 'image:editSaveFunction():1');
                        displayErrorAlert('Error uploading file.')
                        return;
                    });
                } else {
                    return;
                }
            },
            runLoadFunction: async function (id: string, checkListItemData: checkListItemDocument, fromRemoteUserChange: boolean = false, firstRender: boolean): Promise<void> {
                if (firstRender) {
                    const imageURL = await getFileURLFromStorage(checkListItemData.items[id].options.source);
                    $$('#checklist_run_item_' + id).html('<img style="max-width: 100%;" class="noselect" src="' + imageURL + '" alt="' + checkListItemData.items[id].options.alt + '">');
                }
            },
            itemObjectTemplate: {
                type: 'image',
                title: 'New Image',
                notes: '',
                options: {
                    source: '',
                    alt: ''
                },
                column: 1
            }
        },
        signature: {
            name: 'Signature',
            icon: 'fa-signature',
            editForm: import('../reuse/checklist/edit/signature.html'),
            runForm: import('../templates/checklist/run/signature.hbs'),
            renderViewItem: async function (id: string, checkListItemData: checkListItemDocument): Promise<string> {
                let ret: string = '<div>';
                ret += '<span class="fas fa-signature"></span> ' + checkListItemData.items[id].title;
                ret += '<br>';
                if (checkListItemData.items[id].options.draw && checkListItemData.items[id].data.signature_draw) {
                    try {
                        const fileSource: string = await getDataURL(checkListItemData.items[id].data.signature_draw);
                        ret += '<img width="230px" height="60px" style="border-style: solid; border-width: small;" src="' + fileSource + '"></img>';
                        ret += '<br>';
                    } catch (error) {
                        logErrorToServer(error, 'signature:renderViewItem():1');
                        ret += '<i class="fas fa-file-times fa-3x"></i> <br> <p>Image could not be found.</p>';
                        ret += '<br>';
                    }
                }
                if (checkListItemData.items[id].options.print && checkListItemData.items[id].data.signature_print) {
                    ret += checkListItemData.items[id].data.signature_print;
                }
                if (checkListItemData.items[id].options.datefield && checkListItemData.items[id].data.signature_date) {
                    ret += ' (' + formatTimeStamp(getDateMillis(checkListItemData.items[id].data.signature_date), 'date') + ')';
                }
                ret += '</div>'
                return ret;
            },
            editLoadFunction: function (id: string, checkListItemData: checkListItemDocument): void {
                setInputValue('input[name=admin_checklist_edititem_item_signature_draw]', checkListItemData.items[id].options.draw);
                setInputValue('input[name=admin_checklist_edititem_item_signature_datefield]', checkListItemData.items[id].options.datefield);
                setInputValue('input[name=admin_checklist_edititem_item_signature_print]', checkListItemData.items[id].options.print);
            },
            editValidate: function (id: string, checkListItemData: checkListItemDocument): boolean {
                if (!getInputValue('input[name=admin_checklist_edititem_item_signature_draw]') && !getInputValue('input[name=admin_checklist_edititem_item_signature_print]')) {
                    displayAlert('Please select at least one signature type: draw/print.');
                    return false;
                }
                return true;
            },
            editSaveFunction: async function (id: string, checkListItemData: checkListItemDocument): Promise<void> {
                checkListItemData.items[id].options.draw = getInputValue('input[name=admin_checklist_edititem_item_signature_draw]');
                checkListItemData.items[id].options.datefield = getInputValue('input[name=admin_checklist_edititem_item_signature_datefield]');
                checkListItemData.items[id].options.print = getInputValue('input[name=admin_checklist_edititem_item_signature_print]');
                return;
            },
            runLoadFunction: async function (id: string, checkListItemData: checkListItemDocument, fromRemoteUserChange: boolean = false, firstRender: boolean): Promise<void> {
                if (typeof checkListItemData.items[id].data.signature_date === 'string') { //If restored from local backup, date will be a string
                    checkListItemData.items[id].data.signature_date = new Date(checkListItemData.items[id].data.signature_date);
                }
                if (checkListItemData.items[id].options.draw) {
                    if (getDrawingBoardInstance('#checklist_run_item_' + id)) {
                        if (checkListItemData.items[id].data.signature_draw) {
                            getDataURL(checkListItemData.items[id].data.signature_draw).then(function (fileSource: string) {
                                getDrawingBoardInstance('#checklist_run_item_' + id).fillImageByDataURL(fileSource);
                            });
                        }
                    } else {
                        const drawingBoardLib = await import('simple-drawing-board');
                        let drawingBoard: any;
                        //@ts-ignore
                        drawingBoard = drawingBoardLib.create(document.getElementById('checklist_run_item_' + id));
                        drawingBoard.observer.on('drawEnd', function () {
                            emitEvent('checklist_run_itemchange', id + '|' + checkListItemData.items[id].type);
                        });
                        handleClick('.checklist_run_item_draw_clearbutton_' + id, function () {
                            drawingBoard.clear();
                            emitEvent('checklist_run_itemchange', id + '|' + checkListItemData.items[id].type);
                        });
                        handleClick('.checklist_run_item_draw_usesaved_' + id, function () {
                            displayLoadPreloader('Loading signature...');
                            database.get('/agencies/$agencyID$/users_signatures/' + getUserID()).then(async function (signatureData: userSignatureDocument) {
                                if (signatureData.exists) {
                                    await drawingBoard.fillImageByDataURL(signatureData.signature);
                                    if (checkListItemData.items[id].options.print) {
                                        setInputValue('#checklist_run_item_options_print_' + id, getUserFullName());
                                    }
                                    if (checkListItemData.items[id].options.datefield) {
                                        let signatureDate: any = new Date();
                                        setDatePickerValue('#checklist_run_item_options_datefield_' + id, signatureDate);
                                    }
                                    hideLoadPreloader();
                                    emitEvent('checklist_run_itemchange', id + '|' + checkListItemData.items[id].type);
                                } else {
                                    displayAlert('You do not have a saved signature.');
                                    hideLoadPreloader();
                                }
                            }).catch(function (error: any) {
                                logErrorToServer(error, 'signature:runLoadFunction():1');
                                displayErrorAlert('There was an error loading the signature.');
                            });
                        });
                        setDrawingBoardInstance('#checklist_run_item_' + id, drawingBoard);
                        if (checkListItemData.items[id].data.signature_draw) {
                            getDataURL(checkListItemData.items[id].data.signature_draw).then(function (fileSource: string) {
                                drawingBoard.fillImageByDataURL(fileSource);
                            });
                        }
                    }
                } else {
                    $$('.checklist_run_item_signature_disp_draw_' + id).hide();
                }
                if (checkListItemData.items[id].options.print) {
                    setInputValue('#checklist_run_item_options_print_' + id, checkListItemData.items[id].data.signature_print);
                } else {
                    $$('.checklist_run_item_signature_disp_print_' + id).hide();
                }
                if (checkListItemData.items[id].options.datefield) {
                    if (checkListItemData.items[id].data.signature_date) {
                        let signatureDate: any = checkListItemData.items[id].data.signature_date;
                        setDatePickerValue('#checklist_run_item_options_datefield_' + id, new Date(getDateMillis(signatureDate)));
                    }
                } else {
                    $$('.checklist_run_item_signature_disp_datefield_' + id).hide();
                }
                if (!checkListItemData.items[id].options.print && !checkListItemData.items[id].options.datefield) {
                    $$('.checklist_run_item_signature_disp_print_or_datefield_' + id).hide();
                }
            },
            runValidate: function (id: string, checkListItemData: checkListItemDocument): string {
                if (checkListItemData.items[id].special.required) {
                    if (checkListItemData.items[id].options.print && !getInputValue('#checklist_run_item_options_print_' + id)) {
                        return 'Please print signature for "' + checkListItemData.items[id].title + '".';
                    } else if (checkListItemData.items[id].options.datefield && !getDatePickerValue('#checklist_run_item_options_datefield_' + id)) {
                        return '"' + checkListItemData.items[id].title + '" requires a date.';
                    } else {
                        return '';
                    }
                } else {
                    return '';
                }
            },
            runSaveFunction: async function (id: string, checkListItemData: checkListItemDocument): Promise<void> {
                if (checkListItemData.items[id].options.print) {
                    checkListItemData.items[id].data.signature_print = getInputValue('#checklist_run_item_options_print_' + id);
                }
                if (checkListItemData.items[id].options.datefield) {
                    let signatureDate: Date = getDatePickerValue('#checklist_run_item_options_datefield_' + id);
                    checkListItemData.items[id].data.signature_date = (signatureDate ? signatureDate : false);
                }
                if (checkListItemData.items[id].options.draw) {
                    const signatureDataURL = getDrawingBoardInstance('#checklist_run_item_' + id).toDataURL();
                    let filePath: string = '/agencies/' + getAgencyID() + '/checklist_signatures/' + checkListItemData.id + '/' + generateID() + '.png';
                    if (checkListItemData.items[id].data.signature_draw) {
                        filePath = checkListItemData.items[id].data.signature_draw;
                    }
                    uploadDataURL(filePath, signatureDataURL).catch(function (error) {
                        logErrorToServer(error, 'signature:runSaveFunction():1');
                        displayAlert('Error uploading signature file.');
                    });
                    checkListItemData.items[id].data.signature_draw = filePath;
                }
            },
            itemObjectTemplate: {
                type: 'signature',
                title: 'New Signature',
                notes: '',
                data: {
                    signature_draw: '',
                    signature_print: '',
                    signature_date: false
                },
                options: {
                    datefield: false,
                    draw: true, //Stored as base64
                    print: false
                },
                update: 0,
                special: {
                    required: false
                },
                column: 1
            }
        },
        attendance: {
            name: 'Attendance',
            icon: 'fa-clipboard-user',
            runForm: import('../templates/checklist/run/attendance.hbs'),
            renderViewItem: async function (id: string, checkListItemData: checkListItemDocument): Promise<string> {
                let ret: string = '<div>';
                if (checkListItemData.items[id].data.fine?.id) {
                    ret += '<span class="fas fa-money-check-edit-alt"></span> Fine Status: ' + checkListItemData.items[id].data.fine.name;
                } else {
                    ret += '<span class="fas fa-money-check-edit-alt"></span> Fine Status: No Fine';
                }
                ret += '<br>';
                if (checkListItemData.items[id].data.losap?.id) {
                    ret += '<span class="fas fa-user-chart"></span> LOSAP Status: ' + checkListItemData.items[id].data.losap.name;
                } else {
                    ret += '<span class="fas fa-user-chart"></span> LOSAP Status: No LOSAP';
                }
                ret += '<br> <span class="fas fa-calendar"></span> Date for: ' + formatTimeStamp(getDateMillis(checkListItemData.items[id].data.date), 'date');
                ret += '<br>';
                ret += '<table class="attendance-table"><thead>';
                ret += `<tr>
                <th>Present</th>
                <th>Excused</th>
                <th>Name</th>
                <th>Title</th>
                </tr>
                </thead>
                <tbody id="checklist_run_item_attendance_rows_{{id}}" class="report_output_rows">
                `;
                Object.keys(checkListItemData.items[id].data.users).forEach(function (userKey: string) {
                    ret += `
                    <tr>
                        <td style="width: 5%;">${(checkListItemData.items[id].data.users[userKey].state === 'present' ? '<span class="far fa-check-square fa-lg" alt="Checked" title="Checked"></span>' : '<span class="far fa-square fa-lg" alt="Unchecked" title="Unchecked"></span>')}</td>
                        <td style="width: 5%;">${(checkListItemData.items[id].data.users[userKey].state === 'excused' ? '<span class="far fa-check-square fa-lg" alt="Checked" title="Checked"></span>' : '<span class="far fa-square fa-lg" alt="Unchecked" title="Unchecked"></span>')}</td>
                        <td>${checkListItemData.items[id].data.users[userKey].firstname + ' ' + checkListItemData.items[id].data.users[userKey].lastname}</td>
                        <td>${checkListItemData.items[id].data.users[userKey].title}</td>
                    </tr>
                `;
                });
                ret += `</tbody>
                </table>`;
                ret += '</div>';
                return ret;
            },
            runLoadFunction: async function (id: string, checkListItemData: checkListItemDocument, fromRemoteUserChange: boolean = false, firstRender: boolean): Promise<void> {
                const userKeys: string[] = Object.keys(checkListItemData.items[id].data.users);
                if (userKeys.length < 1) {
                    return; //No users
                }
                if (firstRender) {
                    userKeys.forEach(function (userKey: string) {
                        const userName: string = checkListItemData.items[id].data.users[userKey].firstname + ' ' + checkListItemData.items[id].data.users[userKey].lastname;
                        const userTitle: string = checkListItemData.items[id].data.users[userKey].title;
                        $$('#checklist_run_item_attendance_rows_' + id).append($$(`
                            <tr>
                                <td style="width: 5%;"><label class="checkbox"><input name="checklist_run_item_attendance_check_present_${userKey}" class="checklist_run_itemchange" type="checkbox" /><i class="icon-checkbox"></i></label></td>
                                <td style="width: 5%;"><label class="checkbox"><input name="checklist_run_item_attendance_check_excused_${userKey}" class="checklist_run_itemchange" type="checkbox" /><i class="icon-checkbox"></i></label></td>
                                <td>${userName}</td>
                                <td>${userTitle}</td>
                            </tr>
                        `));
                    });
                    if (checkListItemData.items[id].special.fine) {
                        database.get('/agencies/$agencyID$/fines').then(function (fines: fineItemDocument[]) {
                            const newFinesCache: finesCacheObject = {};
                            $$('#checklist_run_item_attendance_finestatus_' + id).html('<option value="">No Fine</option>');
                            fines.forEach(function (fine: fineItemDocument) {
                                newFinesCache[fine.id] = fine;
                                $$('#checklist_run_item_attendance_finestatus_' + id).append('<option value="' + fine.id + '">' + fine.name + '</option>');
                            });
                            setFinesCache(newFinesCache);
                            setInputValue('#checklist_run_item_attendance_finestatus_' + id, (checkListItemData.items[id].data.fine?.id ? checkListItemData.items[id].data.fine?.id : ''));
                        });
                    }
                    if (checkListItemData.items[id].special.losap) {
                        database.get('/agencies/$agencyID$/losap_categories').then(function (losapCategories: losapCategoryDocument[]) {
                            const newLOSAPCategoriesCache: losapCategoriesCacheObject = {};
                            $$('#checklist_run_item_attendance_losapcategory_' + id).html('<option value="">No LOSAP</option>');
                            losapCategories.forEach(function (losapCategory: losapCategoryDocument) {
                                newLOSAPCategoriesCache[losapCategory.id] = losapCategory;
                                if (losapCategory.type === 'manual') { //Don't allow selecting of manual categories
                                    return;
                                }
                                $$('#checklist_run_item_attendance_losapcategory_' + id).append('<option value="' + losapCategory.id + '">' + losapCategory.name + '</option>');
                            });
                            setLOSAPCategoriesCache(newLOSAPCategoriesCache);
                            setInputValue('#checklist_run_item_attendance_losapcategory_' + id, (checkListItemData.items[id].data.losap?.id ? checkListItemData.items[id].data.losap?.id : ''));
                        });
                    }
                    initializeDatePicker('input[name=checklist_run_item_attendance_date_' + id + ']');
                    if (!getLocalAgencyInfo().hidefeatures?.smartrigalert && checkListItemData.items[id].special.importfromlatestcall && !checkListItemData.items[id].data.autofilledfromlatestcall) {
                        displayConfirmDialog({
                            title: 'Auto-fill Data',
                            message: 'Do you want to auto-fill some data from the latest call?',
                            confirmCallback: function () {
                                displayLoadPreloader('Fetching data...');
                                database.get('/agencies/$agencyID$/smartrigalert/calls/calls', { queryOrderBy: { index: 'timestamp', orderBy: 'desc' }, queryLimit: 1 }).then(function (calls: smartRigAlertCallDocument[]) {
                                    checkListItemData.items[id].data.autofilledfromlatestcall = true;
                                    if (calls.length < 1) {
                                        displayAlert('No calls to fetch data from.');
                                        return;
                                    }
                                    const call = calls[0];
                                    checkListItemData.associatedcallid = call.id;
                                    setDatePickerValue('input[name=checklist_run_item_attendance_date_' + id + ']', call.timestamp.toDate());
                                    if (call.responding) {
                                        Object.keys(call.responding).forEach(function (userID: string) {
                                            if (call.responding[userID].destination) {
                                                if (call.responding[userID].destination === 'cancelled') {
                                                    setInputValue('input[name=checklist_run_item_attendance_check_present_' + userID + ']', false);
                                                    setInputValue('input[name=checklist_run_item_attendance_check_excused_' + userID + ']', false);
                                                } else {
                                                    setInputValue('input[name=checklist_run_item_attendance_check_present_' + userID + ']', true);
                                                    setInputValue('input[name=checklist_run_item_attendance_check_excused_' + userID + ']', false);
                                                }
                                            } else {
                                                setInputValue('input[name=checklist_run_item_attendance_check_present_' + userID + ']', false);
                                                setInputValue('input[name=checklist_run_item_attendance_check_excused_' + userID + ']', false);
                                            }
                                        });
                                    }
                                    emitEvent('checklist_run_itemchange', id + '|' + checkListItemData.items[id].type);
                                    hideLoadPreloader();
                                    displayAlert('Imported data from call: ' + formatTimeStamp(getDateMillis(call.timestamp)));
                                });
                            },
                            cancelCallback: function () {
                                checkListItemData.items[id].data.autofilledfromlatestcall = true;
                                emitEvent('checklist_run_itemchange', id + '|' + checkListItemData.items[id].type);
                            }
                        });
                    }
                }
                if (checkListItemData.items[id].data.date) {
                    setDatePickerValue('input[name=checklist_run_item_attendance_date_' + id + ']', checkListItemData.items[id].data.date.toDate());
                } else {
                    setDatePickerValue('input[name=checklist_run_item_attendance_date_' + id + ']', new Date());
                }
                setInputValue('#checklist_run_item_attendance_finestatus_' + id, (checkListItemData.items[id].data.fine?.id ? checkListItemData.items[id].data.fine?.id : ''));
                setInputValue('#checklist_run_item_attendance_losapcategory_' + id, (checkListItemData.items[id].data.losap?.id ? checkListItemData.items[id].data.losap?.id : ''));
                setInputValue('#checklist_run_item_attendance_losapcalltype_' + id, (checkListItemData.items[id].data.losap?.calltype ? checkListItemData.items[id].data.losap?.calltype : ''));
                userKeys.forEach(function (userKey: string) {
                    const userData = checkListItemData.items[id].data.users[userKey];
                    switch (userData.state) {
                        case 'present':
                            setInputValue('input[name=checklist_run_item_attendance_check_present_' + userKey + ']', true);
                            setInputValue('input[name=checklist_run_item_attendance_check_excused_' + userKey + ']', false);
                            break;
                        case 'excused':
                            setInputValue('input[name=checklist_run_item_attendance_check_present_' + userKey + ']', false);
                            setInputValue('input[name=checklist_run_item_attendance_check_excused_' + userKey + ']', true);
                            break;
                        default:
                            setInputValue('input[name=checklist_run_item_attendance_check_present_' + userKey + ']', false);
                            setInputValue('input[name=checklist_run_item_attendance_check_excused_' + userKey + ']', false);
                    }
                });
            },
            runSaveFunction: async function (id: string, checkListItemData: checkListItemDocument): Promise<void> {
                const userKeys: string[] = Object.keys(checkListItemData.items[id].data.users);
                if (userKeys.length < 1) {
                    return; //No users
                }
                if (checkListItemData.items[id].special.fine) {
                    const selectedID: string = getInputValue('#checklist_run_item_attendance_finestatus_' + id);
                    if (selectedID) {
                        checkListItemData.items[id].data.fine = getFinesCache()[getInputValue('#checklist_run_item_attendance_finestatus_' + id)];
                    } else {
                        checkListItemData.items[id].data.fine = {};
                    }
                }
                if (checkListItemData.items[id].special.losap) {
                    const selectedID: string = getInputValue('#checklist_run_item_attendance_losapcategory_' + id);
                    if (selectedID) {
                        checkListItemData.items[id].data.losap = getLOSAPCategoriesCache()[getInputValue('#checklist_run_item_attendance_losapcategory_' + id)];
                        checkListItemData.items[id].data.losap.calltype = getInputValue('#checklist_run_item_attendance_losapcalltype_' + id);
                    } else {
                        checkListItemData.items[id].data.losap = {};
                    }
                }
                if (getDatePickerValue('input[name=checklist_run_item_attendance_date_' + id + ']')) {
                    checkListItemData.items[id].data.date = getDatePickerValue('input[name=checklist_run_item_attendance_date_' + id + ']');
                } else {
                    checkListItemData.items[id].data.date = new Date();
                }
                userKeys.forEach(function (userKey: string) {
                    let userState = 'absent';
                    if (getInputValue('input[name=checklist_run_item_attendance_check_present_' + userKey + ']')) {
                        userState = 'present';
                        setInputValue('input[name=checklist_run_item_attendance_check_excused_' + userKey + ']', false);
                    }
                    if (getInputValue('input[name=checklist_run_item_attendance_check_excused_' + userKey + ']')) {
                        userState = 'excused';
                        setInputValue('input[name=checklist_run_item_attendance_check_present_' + userKey + ']', false);
                    }
                    checkListItemData.items[id].data.users[userKey].state = userState;
                });
            },
            runValidate: function (id: string, checkListItemData: checkListItemDocument): string {
                return '';
            },
            itemObjectTemplate: {
                type: 'attendance',
                title: 'New Attendance',
                notes: '',
                data: {
                    users: {},
                    fine: {},
                    losap: {},
                    date: false,
                    autofilledfromlatestcall: false
                },
                update: 0,
                special: {
                    users: true,
                    fine: false,
                    losap: false,
                    importfromlatestcall: false
                },
                column: 1
            }
        },
        date: {
            name: 'Date',
            icon: 'fa-calendar-day',
            runForm: import('../templates/checklist/run/date.hbs'),
            renderViewItem: async function (id: string, checkListItemData: checkListItemDocument): Promise<string> {
                let ret: string = '';
                ret += '<span class="fas fa-calendar-day fa-lg"></span> '
                ret += '<span class="boldtext">' + checkListItemData.items[id].title + ': </span>';
                if (checkListItemData.items[id].data.date) {
                    ret += formatTimeStamp(getDateMillis(checkListItemData.items[id].data.date), 'date');
                }
                return ret;
            },
            runLoadFunction: function (id: string, checkListItemData: checkListItemDocument, fromRemoteUserChange: boolean = false, firstRender: boolean): void {
                if (firstRender) {
                    initializeDatePicker('input[name=checklist_run_item_' + id + ']');
                }
                if (fromRemoteUserChange && (getDatePickerValue('input[name=checklist_run_item_' + id + ']') !== checkListItemData.items[id].data.date)) {
                    displayElementChanged('.checklist_run_item_container_' + id, checkListItemData.lastchangebyusername);
                }
                if (checkListItemData.items[id].data.date) {
                    setDatePickerValue('input[name=checklist_run_item_' + id + ']', checkListItemData.items[id].data.date.toDate());
                }
            },
            runValidate: function (id: string, checkListItemData: checkListItemDocument): string {
                if (checkListItemData.items[id].special.required && !getInputValue('input[name=checklist_run_item_' + id + ']')) {
                    return 'Enter a value for "' + checkListItemData.items[id].title + '".';
                } else {
                    return '';
                }
            },
            runSaveFunction: async function (id: string, checkListItemData: checkListItemDocument): Promise<void> {
                checkListItemData.items[id].data.date = getDatePickerValue('input[name=checklist_run_item_' + id + ']');
            },
            itemObjectTemplate: {
                type: 'date',
                title: 'New Date Field',
                notes: '',
                data: {
                    date: false
                },
                update: 0,
                special: {
                    required: false
                },
                column: 1
            }
        }
    };
}

function displayElementChanged(selector: string, userName: string) {
    $$(selector).addClass('checklist-item-changed');
    let nameIndicator = app.tooltip.create({
        targetEl: selector,
        text: 'Changed by ' + userName,
        trigger: 'manual'
    });
    //Typescript definitions incorrectly state that the targetel is required for .show()
    //@ts-ignore
    nameIndicator.show();
    setTimeout(function () {
        $$(selector).removeClass('checklist-item-changed');
        nameIndicator.destroy();
    }, 700);
}