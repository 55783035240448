import { userDocument } from './databaseinterfaces';
import {getUserInfo, getUserID} from './user';
import * as database from './database';



export default function logActivity(message: string, data?: Object) {
    getUserInfo().then(function (userInfo: userDocument) {
        let logData: any = {
            userid: getUserID(),
            name: userInfo.firstname + ' ' + userInfo.lastname,
            role: userInfo.role,
            body: message,
            timestamp: database.SERVERTIMESTAMP()
        };
        if (data) {
            logData.data = data;
        }
        database.add('/agencies/$agencyID$/activitylog', logData);
    });
}