//Initialize both user info and agency info
import { getUserInfo } from './user';
import { safeGetAgencyInfo } from './agency';



let initialized: boolean = false;



export function initializeUserAndAgencyInfo(): Promise<void> {
    return new Promise(function (resolve, reject) {
        if (initialized) {
            resolve();
        } else {
            safeGetAgencyInfo().then(function () {
                getUserInfo(false).then(function () {
                    initialized = true;
                    resolve();
                }).catch(function (error: any) {
                    reject(error);
                });
            }).catch(function (error: any) {
                reject(error);
            });
        }
    });
}