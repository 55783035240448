import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

import getIfDevelopmentMode from '../developmentmode';



let initialized: boolean = false;
let firebaseApp: FirebaseApp;
let firebaseAuth: any;


const firebaseConfig = {
    apiKey: process.env.FIREBASE_APIKEY,
    authDomain: process.env.FIREBASE_AUTHDOMAIN,
    projectId: process.env.FIREBASE_PROJECTID,
    storageBucket: process.env.FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGINGSENDERID,
    appId: process.env.FIREBASE_APPID
}



export default function initializeFirebase(): void {
    firebaseApp = initializeApp(firebaseConfig);
    /*
    firebase.firestore().settings({
        //@ts-ignore
        cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
        merge: true
    });

    */

    const auth = getAuth(firebaseApp);
    const storage = getStorage(firebaseApp);
    const firestore = getFirestore(firebaseApp);


    if (getIfDevelopmentMode(true)) {
        console.log('[RUNNING IN DEV MODE]');
        connectAuthEmulator(auth, 'http://localhost:' + process.env.FIREBASE_EMULATOR_AUTH_PORT, { disableWarnings: true });
        connectStorageEmulator(storage, 'localhost', Number(process.env.FIREBASE_EMULATOR_STORAGE_PORT));
        connectFirestoreEmulator(firestore, 'localhost', Number(process.env.FIREBASE_EMULATOR_FIRESTORE_PORT));
    }
}
