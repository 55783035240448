import { generateID } from './utils';
import renderSimpleTable from '../templates/simpletable.hbs';
import { Dom7 as $$ } from 'framework7';
const dt = require('datatables.net-dt')();
import '../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css';



export default function renderDataTable(parentElement: string, headers: string[], rows: string[][], orderBy?: { headerName: string, orderBy: 'asc' | 'desc' }, tableID: string = generateID()) {
    $$(parentElement).html(renderSimpleTable({
        id: tableID,
        headers: headers,
        rows: rows,
    }));
    if (orderBy) {
        const newTable = new dt('#' + tableID, {
            order: [[convertHeaderNameToColumnNumber(orderBy.headerName, headers), orderBy.orderBy]]
        });
    } else {
        const newTable = new dt('#' + tableID, {
            ordering: false
        });
    }
    
}

function convertHeaderNameToColumnNumber(headerName: string, headers: string[]) {
    return headers.indexOf(headerName);
}