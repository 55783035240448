import { Dom7 as $$ } from 'framework7';



export function applyDarkTheme() {
    $$('#app').addClass('theme-dark');
}

export function applyLightTheme() {
    $$('#app').removeClass('theme-dark');
}