
                import Handlebars from 'handlebars/dist/handlebars.runtime';
                const templateFunction = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"tour_home_checklist\">\r\n    <div class=\"item\">\r\n        <div class=\"item-content\">\r\n            <div class=\"item-media\"><i class=\"fas fa-clipboard-list-check fa-2x\"></i></div>\r\n            <div class=\"item-inner\">\r\n                <div class=\"item-title-row\">\r\n                    <div class=\"item-title boldtext\">My First Checklist</div>\r\n                </div>\r\n                <div class=\"item-subtitle overflowbreak\"><span class=\"boldtext\">Location: </span> Headquarters</div>\r\n                <div class=\"item-subtitle overflowbreak homechecklistitem_disp_editactive_"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data,"loc":{"start":{"line":10,"column":90},"end":{"line":10,"column":96}}}) : helper)))
    + " hide\" style=\"color: green;\"><i class=\"fas fa-user-edit\"></i> This checklist is actively being worked on.</div>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</li>";
},"useData":true});
                export default templateFunction;
                