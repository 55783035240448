import {Dom7 as $$} from 'framework7';



export function showLoader() {
    $$('#htmlloadingtext').show();
}

export function hideLoader() {
    $$('#htmlloadingtext').hide();
}

export function hideBrowserWarning() {
    $$('#browserwarning').hide();
}