import * as database from './database';
import { Dom7 as $$ } from 'framework7';
import { userDocument } from './databaseinterfaces';
import { getSmartSelect, createSmartSelect } from './ui';
import { setAllUsersCache, allUsersCacheObject } from './datacache';



export function loadUserSmartSelect(selectID: string, smartSelectClass: string): Promise<any> {
    return new Promise(function (resolve, reject) {
        const locations: any = {
            nolocation: {
                name: 'No Location',
                users: []
            }
        };
        const newAllUsers: allUsersCacheObject = {};

        database.get('/agencies/$agencyID$/users', { queryOrderBy: { index: 'lastname', orderBy: 'desc' } }).then(function (users: userDocument[]) {
            $$(selectID).html('<option value="">Loading...</option>');
            if (users.length === 0) {
                $$(selectID).html('<option value="">No users.</option>');
                if (getSmartSelect(smartSelectClass)) {
                    resolve(getSmartSelect(smartSelectClass));
                } else {
                    resolve(createSmartSelect(smartSelectClass));
                }
                return;
            }
            for (let i in users) {
                if (users[i].location_id) {
                    if (!locations[users[i].location_id]) {
                        locations[users[i].location_id] = {
                            name: users[i].location_name,
                            users: []
                        };
                    }
                    locations[users[i].location_id].users.push(users[i]);
                } else {
                    locations.nolocation.users.push(users[i]);
                }
                newAllUsers[users[i].id] = users[i];
            }
            setAllUsersCache(newAllUsers);
            $$(selectID).html('');
            Object.keys(locations).forEach(function (locationKey: string) {
                $$(selectID).append('<optgroup label="' + locations[locationKey].name + '">');
                for (let i in locations[locationKey].users) {
                    const userTitle: string = (locations[locationKey].users[i].title ? ' (' + locations[locationKey].users[i].title + ')' : '');
                    $$(selectID).append('<option value="' + locations[locationKey].users[i].id + '">' + locations[locationKey].users[i].firstname + ' ' + locations[locationKey].users[i].lastname + (userTitle ? userTitle : '') + '</option>');
                }
                $$(selectID).append('</optgroup>');
            });
            if (getSmartSelect(smartSelectClass)) {
                resolve(getSmartSelect(smartSelectClass));
            } else {
                resolve(createSmartSelect(smartSelectClass));
            }
        }).catch(function (error: any) {
            reject(error);
        });
    });
}