import { mainView } from './libs/framework7';



/**
 * Gets the current URL parameter's value by name.
 * E.g. if the route is /user/:userID the param would be "userID".
 * @param {string} param The URL parameter to get the value of.
 * @returns {string} The value of the URL parameter.
 */
export function getURLParameter(param: string): string {
    return mainView.router.currentRoute?.params[param];
}

/**
 * Get's the current URL query's value by name.
 * E.g. If the route is /user?id=1234 the query would be "id". 
 * @param {string} query The URL query to get the value of.
 * @returns {string} The value of the URL query.
 */
export function getURLQuery(query: string): string {
    return mainView.router?.currentRoute?.query?.[query];
}