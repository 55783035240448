/**
 * This Framework7 file wraps around framework7-custom, which wraps around framework7 from npm.
 * This file initializes the framework and sets the base configuration for the framework.
 */

import Framework7 from './framework7-custom';
import '../../css/framework7-custom.less';
import { routes, menuRoutes } from '../routes';
import { emitEvent } from '../events';



export let app: Framework7;
export let mainView: any;
export let leftView: any;



export function initializeFramework7(): void {

    app = new Framework7({
        init: false, //We want to init after the views are setup
        el: '#app',
        name: 'Smart Rig Check',
        id: 'com.smartrigcheck.smartrigcheck',
        theme: 'md'
    });

    mainView = app.views.create('.view-main', {
        url: '/',
        routes: routes,
        main: true,
        browserHistory: true,
        browserHistoryRoot: window.location.origin,
        browserHistorySeparator: '',
        animate: false,
        on: {
            pageInit: async function (pageEvent: any) {
                if (pageEvent.direction !== 'backward') {
                    callPageInitHandlers();
                } else {
                    emitEvent('updateMenuActiveButton');
                    //Currently if this is enabled, pageinit function gets called twice on pages
                    //Instead, we use the pageAfterOut() event and check to make sure the direction is backward
                    //finish();
                }
            }, pageAfterOut: async function (pageEvent: any) {
                if (pageEvent.direction === 'backward') {
                    callPageInitHandlers();
                }
            },
            pageBeforeRemove: async function (pageEvent: any) {
                if (pageEvent.router.previousRoute?.route?.pageBeforeRemoveHandler) {
                    const pageBeforeRemoveHandler = await pageEvent.router.previousRoute.route.pageBeforeRemoveHandler;
                    pageBeforeRemoveHandler.handlePageBeforeRemove();
                }
            }
        }
    });

    leftView = app.views.create('.view-left', {
        url: '/menu_main',
        routes: menuRoutes,
        browserHistory: false,
        animate: false,
        on: {
            pageInit: async function (pageEvent: any) {
                if (pageEvent.direction !== 'backward') {
                    emitEvent('menuPageInit', leftView.router.currentRoute.name);
                    if (leftView.router.currentRoute.route.pageHandler) {
                        const pageHandler = await leftView.router.currentRoute.route.pageHandler;
                        pageHandler.default();
                    }
                } else {
                    emitEvent('updateMenuActiveButton');
                }
            }
        }
    });

    app.init();
}

async function callPageInitHandlers() {
    emitEvent('pageInit', mainView.router.currentRoute.name);
    if (mainView.router.currentRoute.route.pageHandler) {
        const pageHandler = await mainView.router.currentRoute.route.pageHandler;
        pageHandler.default();
    }
}