import * as database from './database';
import { getAgencyID } from './agency';
import { getUserInfo } from './user';
import { userDocument } from './databaseinterfaces';
import { logErrorToServer } from './debug';



interface analyticEventBody {
    event: analyticEventType;
    name?: string;
    timestamp: any;
    agencyid?: string;
}

export type analyticEventType = 'pageload' | 'signin' | 'signout' | 'joininvite' | 'use_feature' | 'click';

export function logAnalyticsEvent(event: analyticEventType, eventName: string = '', includeAgencyID = true, manualAgencyID?: string): Promise<void> {
    return new Promise(function (resolve, reject) {
        let analyticBody: analyticEventBody = {
            event: event,
            timestamp: database.SERVERTIMESTAMP()
        };
        if (eventName) {
            analyticBody.name = eventName;
        }
        if (includeAgencyID) {
            if (manualAgencyID) {
                analyticBody.agencyid = manualAgencyID;
            } else {
                let useID: string = '';
                //We do this in a try catch in case the agency info is not instantialized/loaded
                try {
                    useID = getAgencyID();
                } catch (e) {
                    //Do nothing
                }
                if (useID) {
                    analyticBody.agencyid = useID;
                }
            }
        }
        if (!manualAgencyID) {
            getUserInfo().then(function (userInfo: userDocument) {
                if (userInfo.disableanalytics) {
                    resolve();
                } else {
                    finish();
                }
            }).catch(function (error) {
                if (error !== 'No auth.') {
                    logErrorToServer(error, 'logAnalyticsEvent():1');
                }
                resolve();
            });
        } else {
            finish();
        }
        function finish() {
            database.add('/analytics', analyticBody).then(function () {
                resolve();
            }).catch(function (error) {
                logErrorToServer(error, 'logAnalyticsEvent():2');
                resolve(); // Silently fail
            });
        }
    });
}