import { showMenu, hideMenu } from './menu';
import { Dom7 as $$ } from 'framework7';
import html2canvas from 'html2canvas';
import * as ui from './ui';
import { convertBinaryToBase64, openURLInNewTab } from './utils';



export function printPage() {
    ui.closeDialog();
    $$('.browserprintwarning').text('');
    window.print();
    $$('.browserprintwarning').text('Please use the built-in print function to print this page.');
}

export async function printPageToPDF(elementID: string, openAs:  'newTab' | 'blob' | 'base64' = 'newTab'): Promise<any> {
    ui.closeDialog();
    $$('.browserprintwarning').text('');
    const canvas: any = await html2canvas(document.getElementById(elementID), {
        allowTaint: true,
        height: document.getElementById(elementID).scrollHeight,
        windowHeight: window.outerHeight + window.innerHeight,
        useCORS: true
    });
    ui.displayLoadPreloader('Printing...');
    $$('.browserprintwarning').text('Please use the built-in print function to print this page.');
    const jsPDF = (await import('jspdf')).jsPDF;
    let imgData = canvas.toDataURL('image/png');
    let imgWidth = 210;
    let pageHeight = 295;
    let imgHeight = canvas.height * imgWidth / canvas.width;
    let heightLeft = imgHeight;
    let doc = new jsPDF('p', 'mm', 'a4', true);
    let position = 2; // give some top padding to first page

    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
        position += heightLeft - imgHeight; // top padding for other pages
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
    }
    if (openAs === 'newTab') {
        openURLInNewTab(URL.createObjectURL(doc.output('blob')));
        ui.hideLoadPreloader();
        return;
    } else if (openAs === 'blob') {
        ui.hideLoadPreloader();
        return doc.output('blob');
    } else if (openAs === 'base64') {
        ui.hideLoadPreloader();
        return convertBinaryToBase64(doc.output('blob'));
    }
}