import * as FilePond from 'filepond';
import "filepond/dist/filepond.min.css";
//@ts-ignore
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
//@ts-ignore
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
//@ts-ignore
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { getFileExtension } from './utils';
import { validateFileSize, validateIfCertainFileType } from './validation';
import { displayAlert } from './ui';
import { getStorage, ref, getDownloadURL, deleteObject, uploadBytesResumable, uploadString } from "firebase/storage";



let initialized: boolean = false;
let currentFileUploadComponent: FilePond.FilePond;
let fileUploadComponents: any = {};
const maxFileSizes = {
    default: 20000 //KB
};



function initialize(): void {
    if (!initialized) {
        initialized = true;
        FilePond.registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginImageExifOrientation);
    }
}

export function setupCurrentFileUploadInput(input: string, list: boolean = false) {
    initialize();
    //credits property not added to type yet
    if (list) {
        //@ts-ignore
        fileUploadComponents[input] = FilePond.create(document.querySelector(input), { credits: false });
    } else {
        //@ts-ignore
        currentFileUploadComponent = FilePond.create(document.querySelector(input), { credits: false });
    }

}

export function getIfHasCurrentFile(input?: string): boolean {
    initialize();
    if ((input ? fileUploadComponents[input].getFile() : currentFileUploadComponent.getFile())) {
        return true;
    } else {
        return false;
    }
}

export function getCurrentFileExtension(input?: string): string {
    initialize();
    return getFileExtension((input ? fileUploadComponents[input].getFile().file.name : currentFileUploadComponent.getFile().file.name));
}

export function getCurrentFileMimeType(input?: string): string {
    initialize();
    return (input ? fileUploadComponents[input].getFile().file.type : currentFileUploadComponent.getFile().file.type);
}

export function destroyFileUpload(input: string) {
    delete fileUploadComponents[input];
}

export function getFileURLFromStorage(fileLoc: string): Promise<string> {
    return new Promise(function (resolve, reject) {
        let storageRef = ref(getStorage(), fileLoc);
        getDownloadURL(storageRef).then(function (url: string) {
            resolve(url);
        }).catch(function (error: any) {
            reject(error);
        });
    });
}

export function deleteFile(fileLoc: string, ignoreNotFoundError = true): Promise<void> {
    return new Promise(function (resolve, reject) {
        let storageRef = ref(getStorage(), fileLoc);
        deleteObject(storageRef).then(function () {
            resolve();
        }).catch(function (error: any) {
            if (error.code === 'storage/object-not-found' && ignoreNotFoundError) {
                resolve();
            } else {
                reject(error);
            }
        });
    });
}

export function uploadFile(fileLoc: string, filesrc: any, mimetype = 'application/zip'): Promise<any> {
    return new Promise(function (resolve, reject) {
        let storageRef = ref(getStorage(), fileLoc);

        const uploadTask = uploadBytesResumable(storageRef, filesrc, { contentType: mimetype });

        uploadTask.on('state_changed', function (snapshot: any) {
            //do nothing during
        }, function (error: any) {
            reject(error);
        },
            function () {
                resolve(fileLoc);
            }
        );
    });
}

export function uploadDataURL(fileLoc: string, filesrc: any): Promise<any> {
    return new Promise(function (resolve, reject) {
        let storageRef = ref(getStorage(), fileLoc);
        uploadString(storageRef, filesrc, 'data_url').then(function () {
            resolve(fileLoc);
        }).catch(function (error: any) {
            reject(error);
        });
    });
}

export function getDataURL(fileLoc: string): Promise<string> {
    return new Promise(function (resolve, reject) {
        getFileURLFromStorage(fileLoc).then(function (downloadURL: string) {
            let xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                const blob = xhr.response;
                let reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    const base64data = <string>reader.result;
                    resolve(base64data);
                }
            };
            xhr.open('GET', downloadURL);
            xhr.send();
        }).catch(function (error: any) {
            reject(error);
        });
    });
}

export function uploadCurrentFile(fileLoc: string, input: string = '', maxFileSize = maxFileSizes.default, mimeType?: string): Promise<any> {
    return new Promise(function (resolve, reject) {
        initialize();
        if (!mimeType) {
            mimeType = getCurrentFileMimeType(input);
        }
        let parsedFileLoc = fileLoc + '.' + getCurrentFileExtension(input);
        getCurrentFileFromInput(maxFileSize, input).then(function (readFile: any) {
            uploadFile(parsedFileLoc, readFile, mimeType).then(function (fileURL: string) {
                resolve({ fileLoc: parsedFileLoc, fileURL: fileURL });
            }).catch(function () {
                displayAlert('Error uploading file.');
                reject();
            });
        }).catch(function () {
            reject();
        });
    });
}

export function getCurrentFileFromInput(maxFileSize = maxFileSizes.default, input?: string) {
    return new Promise(function (resolve, reject) {
        initialize();
        let reader = new FileReader();
        if ((input ? fileUploadComponents[input].getFile() : currentFileUploadComponent.getFile())) {
            if (validateFileSize((input ? fileUploadComponents[input].getFile().file : currentFileUploadComponent.getFile().file), maxFileSize)) {
                reader.readAsArrayBuffer((input ? fileUploadComponents[input].getFile().file : currentFileUploadComponent.getFile().file));
            } else {
                displayAlert('The file you uploaded is too large. Please make sure it is less than ' + maxFileSize + 'KB.');
                reject();
            }
        } else {
            resolve('');
        }
        reader.onloadend = function () {
            resolve(reader.result);
        };
    });
}

export function getCurrentFile(input?: string): FilePond.FilePondFile {
    return (input ? fileUploadComponents[input].getFile() : currentFileUploadComponent.getFile());
}

/**
 * Validates the current file upload components file type (e.g. if it's a valid pdf file).
 * @param {string} fileExtension The file extension to validate for/make sure the file name has.
 * @returns {boolean} True if valid.
 */
export function validateCurrentFileType(fileExtension: string, input?: string): boolean {
    return validateIfCertainFileType((input ? fileUploadComponents[input].getFile().file : currentFileUploadComponent.getFile().file), fileExtension);
}

export function validateCurrentFileTypeIsImage(input?: string): boolean {
    let extensions: string[] = ['jpg', 'jpeg', 'png', 'svg', 'gif', 'tiff', 'tif'];
    for (let i in extensions) {
        if (validateCurrentFileType(extensions[i], input)) {
            return true;
        }
    }
    return false;
}

export function validateCurrentFileTypeIsDocument(input?: string): boolean {
    let extensions: string[] = ['pdf', 'doc', 'docx', 'txt', 'xlsx', 'xls', 'csv', 'rtf', 'odt', 'tex', 'ppt', 'pptx'];
    for (let i in extensions) {
        if (validateCurrentFileType(extensions[i], input)) {
            return true;
        }
    }
    return false;
}