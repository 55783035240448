import { globalConfigDocument } from "./databaseinterfaces";
import * as database from './database';
import { mainView } from './libs/framework7';
import * as ui from './ui';
import { Dom7 as $$ } from 'framework7';



let _globalConfig: globalConfigDocument;



export function loadGlobalConfig(): Promise<void> {
    return new Promise(function (resolve, reject) {
        if (_globalConfig) {
            resolve();
        } else {
            database.get('/globalconfig/config').then(function (configDoc: globalConfigDocument) {
                if (configDoc.exists) {
                    _globalConfig = configDoc;
                    resolve();
                } else {
                    resolve();
                }
            }).catch(function () {
                resolve(); // Silently fail
            });
        }
    });
}

export function displayGlobalAnnouncement() {
    if (_globalConfig) {
        run();
    } else {
        loadGlobalConfig().then(function () {
            if (_globalConfig) {
                run();
            }
        }).catch(function () {
            // Silently fail
        });
    }
    async function run() {
        let localVersion: number;
        if (localStorage.getItem('announcement_version')) {
            localVersion = Number(localStorage.getItem('announcement_version'));
        }
        if (_globalConfig.announcement_title || _globalConfig.announcement_body) {
            if (_globalConfig.announcement_version !== localVersion) {
                localStorage.setItem('announcement_version', _globalConfig.announcement_version.toString());
                const popupSource = await import('../popups/globalannouncements.html');
                ui.displayPopup(popupSource, true, undefined, function () {
                    if (_globalConfig.announcement_title) {
                        $$('.popup_globalannouncements_title').text(_globalConfig.announcement_title);
                        $$('.popup_globalannouncements_body').text(_globalConfig.announcement_body);
                        if (_globalConfig.announcement_link_url) {
                            $$('.popup_globalannouncements_link').html('<a href="' + _globalConfig.announcement_link_url + '" class="button button-fill external" target="_blank">' + _globalConfig.announcement_link_text + '</a>');
                        }
                    }
                });
            }
        }
    }
}