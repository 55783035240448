import getIfDevelopmentMode from './developmentmode';



export function getAppURL(): string {
    let url = '';
    url += window.location.protocol;
    url += '//'
    url += window.location.host;
    return url;
}