import { userIsSignedIn, getUserRole } from './user';
import { mainView } from './libs/framework7';
import { initializeUserAndAgencyInfo } from './initialize';
import { displayHeaderInfo } from './menu';
import { isConfirmGoBack, setIsConfirmGoBack } from './lockedstate';
import { displayConfirmDialog, displayAlert, displayLoadPreloader } from './ui';
import * as database from './database';
import { getURLParameter } from './urlparameters';
import { checkListItemDocument } from './databaseinterfaces';



export async function checkIfSignedIn({ to, from, resolve, reject }) {
    userIsSignedIn().then(function (isSignedIn: boolean) {
        if (isSignedIn) {
            initializeUserAndAgencyInfo().then(function () {
                resolve();
            }).catch(function (error: any) {
                reject();
                mainView.router.navigate('/signin');
            });
        } else {
            reject();
            mainView.router.navigate('/signin');
        }
    }).catch(function (error: any) {
        reject();
    });
}

export async function checkIfAdmin({ to, from, resolve, reject }) {
    initializeUserAndAgencyInfo().then(function () {
        if (getUserRole() === 'admin') {
            resolve();
        } else {
            displayAlert('You must be an admin to access this page or function.');
            reject();
        }
    });
}

export async function cannotBeSignedIn({ to, from, resolve, reject }) {
    userIsSignedIn().then(function (isSignedIn: boolean) {
        if (isSignedIn) {
            reject();
            mainView.router.navigate('/');
        } else {
            resolve();
        }
    }).catch(function (error: any) {
        reject();
        mainView.router.navigate('/');
    });
}

export async function defaultHandleBeforeLeave({ resolve, reject }) {
    if (isConfirmGoBack()) {
        displayConfirmDialog({
            message: 'You have unsaved changes. Are you sure you want to go back?', title: 'Unsaved Changes', confirmCallback: function () {
                setIsConfirmGoBack(false);
                destroyPageBackObjects();
                resolve();
            }, cancelCallback: function () {
                reject();
            }
        })
    } else {
        destroyPageBackObjects();
        unsubscribePageBackSnapshots();
        resolve();
    }
}

function destroyPageBackObjects() {
    if (pageBackObjectsToDestroy.length > 0) {
        for (let i in pageBackObjectsToDestroy) {
            pageBackObjectsToDestroy[i].destroy();
        }
        return;
    } else {
        return;
    }
}

function unsubscribePageBackSnapshots() {
    if (pageBackSnapshotsToUnsubscribe.length > 0) {
        for (let i in pageBackSnapshotsToUnsubscribe) {
            if (pageBackSnapshotsToUnsubscribe[i]) {
                pageBackSnapshotsToUnsubscribe[i]();
            }
        }
        return;
    } else {
        return;
    }
}

const pageBackObjectsToDestroy: any[] = [];
const pageBackSnapshotsToUnsubscribe: any[] = [];

export function addObjectToBeDestroyedOnPageBack(obj: any) {
    pageBackObjectsToDestroy.push(obj);
}

export function addSnapshotToBeUnsubscribedOnPageBack(snapshot: any) {
    pageBackSnapshotsToUnsubscribe.push(snapshot);
}

export function handleFormAccess(agencyID: string, formID: string) {
    displayLoadPreloader('Fetching form info...')
    userIsSignedIn(true).then(function (isSignedIn: boolean) {
        if (!agencyID || !formID) {
            displayAlert('Missing a parameter to access the form.');
            mainView.router.navigate('/');
            return;
        }
        database.get('/agencies/' + agencyID + '/forms/' + formID).then(function (form: checkListItemDocument) {
            if (!isSignedIn && form.permissions !== 'public') {
                displayAlert('You do not have permission to access this form. Please sign in.');
                mainView.router.navigate('/');
                return;
            }
            mainView.router.navigate('/form/run/' + agencyID + '/' + formID)
        }).catch(function () {
            displayAlert('There was an error fetching the form info.');
            mainView.router.navigate('/');
            return;
        });
    });
}